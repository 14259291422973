import { Helper } from '../../shared/helper';
import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Inject, Renderer2, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import { Auth } from '../../shared/services/auth.service';
import { AdvizorProService } from '../../shared/services/advizor-pro.service';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { PlanService } from '../../shared/services/plan.service';
import { NgForm } from '@angular/forms';
import { DOCUMENT, DatePipe, KeyValue } from '@angular/common';
import { interval, Subscription, forkJoin, timer, Subject, firstValueFrom, Observable, take } from 'rxjs';
import { makeRandomString } from '../../utils';
import { en_US, NzI18nService  } from 'ng-zorro-antd/i18n';
import { ReportService } from '../../shared/services/report.service';
import { UserService } from '../../shared/services/user.service';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { debounceTime, timeout } from 'rxjs/operators';
import { ProfileService } from '../../shared/services/profile.service';
import { ScoreService } from '../../shared/services/score.service';
import { ToolTipMessage } from './reference-tooltip';
import { categoryField } from './categories';

import { ModalService } from "./services/modal.service"
import { SEARCH_VARIABLES } from "./utils/variables-to-search"
import { ModalService as ModalServiceShared} from "../../shared/services/modal.service";
import { Dba } from "../../shared/interfaces/dba.interface"
import { FirmTeam, AdvisorTeam } from "../../shared/interfaces/person.interface"
import { ExportResponse, DownloadMessage, OmitType, OmitList, OmitCheck, SaveSearch, SearchData, SaveSearchResponse, SavedSearchResponse, SavedSearchOptionResponse, SaveSearchNewRespone, SaveSearchNewData, SavedSearchOption, PlanSelectOptions } from "./interfaces/search.interface";
import { DEFINED_BENEFIT, DEFINED_CONTRIBUTION } from '../../shared/components/advisor_firm_modal/core';
import { Icon } from '../../shared/components/icon-dataset/icons';
import { getIconsByRowKeys, getIconsDataset } from '../../shared/utils/icon-utils';
import { FirmContactResponse, Score } from '../../shared/interfaces/search-new.interface';

const TYPING_SPEED = 50;

interface AiFiltersResponse {
  data: {
    filters: SearchData;
    feedback?: string;
    isIdentifier?: boolean;
    isPersonName?: boolean;
    isFirmName?: boolean;
    isMultiple?: boolean;
    cleanedInput?: string;
  };
}

interface Panel {
  field_id: string;
  name?: string;
  category: string;
  level: string;
  shouldOpenPanel?: boolean;
  indexOfPanelToOpen?: number;
  filterCount?: number;
}

interface Panels {
  [key: string]: Panel[][];
}

@Component({
  selector: 'app-search-new',
  templateUrl: './search-new.component.html',
  styleUrls: ['./search-new.component.scss'],
  providers: [DatePipe],
})
export class SearchNewComponent implements OnInit, OnDestroy {
  readonly searchVariables = SEARCH_VARIABLES;
  datasetSearchParamType = {}
  display = false;

  header_basic_access =  false;
  @ViewChild('personForm') personForm: NgForm;
  @ViewChild('companyForm') companyForm: NgForm;
  @ViewChild('mapContainer') gmap: ElementRef;
  mapContactModal = false;
  limit_over_model = false;
  email_automation_modal = false;
  email_test_modal = false;
  bulk_follow = false;
  email_quality = false;
  date_saved_search = false;
  setting_modal = false;
  toolTipOrnament: any;

  slideUp: any = {
    type: "md"
  }

  search: any = {};
  search_result: any = ['person', 'firm'];
  exportStatus = false;
  user: any;
  save_search: SaveSearch = { 'search_name': '', 'option': 'live', 'shared_type': '0', 'save_exist_type': 1, exclude_firms: 0, exclude_persons: 0, search_update: 0};
  dis_zip_code_option = [];
  optionsChecked = [];
  crossSearchLoop = true;

  types: any = [];
  states: any = [];
  morningstar_category: any = [];
  insurance_lines: any = [];
  insurance_registered_states: { id: string; value: string }[] = [];
  firm_asset_class: any = [];
  person_asset_class: any = [];
  people_roles: any = [];
  compensations: any = [];
  business_type_po: any = [];
  advisor_services: any = [];
  firm_ownership: any = [];
  advisor_affiliations: any = [];
  client_types: any = [];
  channels: any = [];
  web_advizor_firm_keyword: any = [];
  web_advizor_person_keyword: any = [];
  filtered_web_advizor_firm_keyword: any = [];
  filtered_web_advizor_person_keyword: any = [];
  asset_types: any = [];
  financial_planing_clients: any = [];
  affiliations: any = [];
  licenses_registrations: any = [];
  select_title_type = 'contains';
  select_carrier_type = 'contains';
  select_company_type = 'contains';
  person_title_contains = [
    { 'name': 'Contains', 'value': 'contains' },
    { 'name': 'Not Contains', 'value': 'not_contains' },
    { 'name': 'Equals', 'value': 'equals' },
    { 'name': 'Excludes', 'value': 'excludes' }
  ];
  carrier_contains = [
    { 'name': 'Contains', 'value': 'contains' },
    { 'name': 'Not Contains', 'value': 'not_contains' },
    { 'name': 'Equals', 'value': 'equals' },
    { 'name': 'Excludes', 'value': 'excludes' }
  ];
  company_contains = [
    { 'name': 'Contains', 'value': 'contains' },
    { 'name': 'Not Contains', 'value': 'not_contains' },
    { 'name': 'Equals', 'value': 'equals' },
    { 'name': 'Excludes', 'value': 'excludes' }
  ];
  genders = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' }
  ];
  designation = [];

  columnModeSetting = (window.innerWidth < 960) ? 'standard' : 'force';
  scrollBarHorizontal = (window.innerWidth < 960);
  excel_search: any;

  limit: any = 50;
  paginate: any = [];
  sortField: any = 'last_name';
  sortOrder: any = 1;

  firmLimit: any = 50;
  firmPaginate: any = [];
  firmSortField: any = 'firm_name';
  firmSortOrder: any = 1;

  export_data: any;
  exportData: any = {};

  locationModalActive

  numberMask = createNumberMask({
    prefix: '',
    suffix: ''
  });

  select_county: any = [];
  select_company: any = [];
  select_person_county: any = [];
  select_title_contain: any = [];
  select_wealth_creator: any = [];
  select_metro_area: any = [];
  select_person_metro_area: any = [];
  select_team_metro_area: any = [];
  select_affiliation: any = [];
  select_custodian: any = [];
  select_firm_name: any = [];
  select_issuer_name: any = [];
  select_class: any = [];
  select_ticker: any = [];
  county: any;
  person_county: any;
  affiliation: any;
  custodian: any;
  issuer_name: any;
  ticker: any;
  class13: any;
  quick_search: any;
  title_contain: any;
  metro_area: any;
  person_metro_area: any;
  team_metro_area: any;
  exportType = 'person';
  custodian_flag = false;
  company_flag = false;
  affiliation_flag = false;
  title_flag = false;
  wealth_creator_flag = false;
  metro_area_flag = false;
  person_metro_area_flag = false;
  team_metro_area_flag = false;
  firm_name_flag = false;
  exportCss = true;
  over_export_row_limit = false;
  dateDiff: any;
  exclude_firm_flag = false;
  exclude_firm_name: any = [];
  exclude_firm: any;

  peopleWith = [
    { label: 'Business Email Address', value: 'email' },
    { label: 'Personal Email Address', value: 'personal_email' },
    { label: 'Phone Number', value: 'phone' },
    { label: 'Landline Phone Number', value: 'landline' },
    { label: 'Mobile Phone Number', value: 'mobile' },
    { label: 'LinkedIn Profile', value: 'linkedin' },
    { label: 'Website Bio', value: 'bio_url' }
  ];

  mapActive = false;
  mapActiveType = null;
  paginateContact: any = [];
  contacts: FirmContactResponse;
  mapCrd: any;
  loading = false;
  tab_recognised_id = null;
  search_btn = {
    text: 'Search',
    disabled : false
  };
  clear_btn= {
    text: 'Clear',
    disabled: false
  };

  exportMessage = "All fields with checked boxes will appear in the export";

  export_firm_cols: { id: string; value: string; checked: boolean; }[];
  export_person_cols: { id: string; value: string; checked: boolean; permision_key?: string; }[];
  exclude_firm_checkbox = [];
  exclude_person_checkbox = [];
  excel_link: string;

  select_previous_firm: any = [];
  previous_firm: any;
  previous_firm_flag = false;
  select_ins_carriers: any = [];
  select_ins_companies: any = [];

  insurance_carrier: any;
  insurance_company:any;
  ins_carriers_flag = false;
  ins_company_flag = false;
  recipient_data = {
    count: 0,
    recipients: [],
    type:'person_crd'
  };
  defCheck = false;
  firm_keyword_category: any = [];
  person_keyword_category: any = [];
  isExportModal = false;
  isAntWaitingBox = false;

  statusList = ['Cold Lead', 'Warm Lead', 'Hot Lead', 'Client', 'Closed Lost'];
  my_advizor_option = true;
  firm_my_advizor_option = true;
  custodian_option = true;
  plan_my_plans_option = true;
  plan_plans_with_option = true;
  firm_name: any;
  follow_status: any;

  company_checkbox = [];
  firm_exclude_checkbox = [];
  firm_checkbox = [];
  check_all = false;
  is_search = false;
  licensesFilterSwitch = false;

  tickerFilterSwitch = false;
  peopleWithFilterSwitch= false;
  insLineFilterSwitch = false;
  insRegisterFilterSwitch= false;

  is_make_search = false;
  bulk_data = [];
  bulk_ids = [];
  selected_person = [];
  has_disclosure_type = [];
  user_follow_type = 'private';
  advExport: Subscription;
  listMatching: Subscription;
  exportCreditCount = 0;
  exportExcelCount = 0;
  exportOptionalName = '';
  downloadMessage: DownloadMessage = { status_message:'Your report is processing...', status: "success", message: ""};
  bulkSearchValue = 'rep_crd';
  percent_complete = 0;
  mapped_records = 0;

  messagesPerson = {
    emptyMessage: '<div class=\"mt-3 ml-3 bold\" align=\"center\">Searching persons...<\/div>',
  }

  messagesFirm = {
    emptyMessage: '<div class=\"mt-3 ml-3 bold\" align=\"center\">Searching firms...<\/div>',
  }

  messagesTeam = {
    emptyMessage: '<div class=\"mt-3 ml-3 bold\" align=\"center\"><div class=\"ant-spin ant-spin-spinning ant-spin-sm\"><span class=\"ant-spin-dot ant-spin-dot-spin\\"><i class=\"ant-spin-dot-item\"><\/i><i class=\"ant-spin-dot-item\"><\/i><i class=\"ant-spin-dot-item\"><\/i><i class=\"ant-spin-dot-item\"><\/i><\/span><div class=\"ant-spin-text\">Loading...<\/div><\/div><\/div>',
  }

  firmLoading = true;
  personLoading = true;

  selectedSaved:any = '';

  saveOfGroupOption: SavedSearchOption[] = [];

  is_export_tab = true;
  saved_search_diff = [];
  saved_search_param:any = {};
  autoSelectionTitle = [];
  autoSelectionWealthCreator = [];
  autoSelectionMetroArea = [];
  autoSelectionPersonMetroArea = [];
  autoSelectionTeamMetroArea = [];
  autoSelectionFirm = [];
  autoSelectionExcludeFirm = [];
  autoSelectionPreviousFirm = [];
  autoSelectionCustodian = [];
  autoSelectionInsCarriers = [];
  autoSelectionInsCompany = [];
  text_insurance_company = '';
  search_active_url = true;
  onlyInsuranceAccess = false;
  crm_friendly = false;
  search_filters = [];
  user_setting:any = {};
  isUpdList = false;
  updFile:any = {};
  invalidUploadField = [];
  fileList: NzUploadFile[] = [];
  uploading = false;
  isList = false;
  uploadedList:any = {};
  tooltipList = [];
  searchAccess = [];
  searchFileName = '';
  create_saved_search = false;
  firm_name_search = 'Excludes';
  myAdvizorOption = ['Includes', 'Excludes'];
  isExportActive = false;
  tooltipColor: string;
  omitType: OmitType[] = [
    { 'name': 'Me', 'value': 'me' },
    { 'name': 'Team', 'value': 'team' }
  ];
  omitList: OmitList[] = [
    { 'name': '30 days', 'value': '30' },
    { 'name': '60 days', 'value': '60' },
    { 'name': '90 days', 'value': '90' },
    { 'name': 'All Time', 'value': 'all' }
  ];
  omit: OmitCheck = {
    check: false,
    day: '30',
    type: 'me',
  }

  insCarrierType = true;
  insAgencyActive = true;
  titleType = true;
  hubspotAccess = false;
  salesforceAccess = false;
  isAdvizorProSaved = false;
  onlyAdvizorAccess = false;
  onlyFamilyAccess = false;
  onlyTeamAccess = false;

  paginateSaved: any = [];
  dbaData: Dba;
  bulk_filter_option = true;
  disclosure_content = false;
  disclaimer_check:any = false;
  fileObj: any;
  count = 0;
  duration = 5000;
  familyType: any = [
    { 'name': 'Single Family Office', 'value': 'sfo' },
    { 'name': 'Multi Family Office', 'value': 'mfo' }
  ];
  // dual_icon:boolean = false;

  firm_contains_list:any = [
    { label: 'WEBSITE', value: 'website' },
    { label: 'ADV', value: 'adv' },
    { label: 'ADV BROCHURES', value: 'adv_brochures' },
    { label: 'LINKS', value: 'links' },
    { label: 'ADV CRS', value: 'adv_crs' },
    { label: 'BD DETAILED REPORT', value: 'bd_detailed_report' },
    { label: 'BD CRS', value: 'bd_crs' },
  ];

  selected_firm_contains = [];
  wealth_creator = '';

  panels: Panels = {};
  filter_count = 0;
  saveSearched = false;
  search_filter_modal = '';

  gender_male = false;
  gender_female = false;

  already_search_person_category = true;
  search_data: SaveSearchNewData[] = [];
  shareType: string[] = ['Private', 'Team', 'AdvizorPro'];
  contain_delay = new Subject();

  cate_active:any = {};

  expandSearchString = '';
  expandButton = false;
  expandbuttonShow = false;

  openContactPanel = false;
  openCompanyPanel = false;

  filterLengthCount = 0;

  paginateLimits: number[] = [20, 50, 100];
  modalPaginateLimits: number[] = [5, 10, 20];

  isProfilevisible = false;
  profileData: { type: string; sub_type: string; crd: string, score?: string, score_id?: number };

  hasContactFilters = false;
  hasCompanyFilters = false;
  show = false;

  people_with = [];
  already_search_firm_category = true;
  score: Score = { status: '', data: null };

  minimumScore = {
    Excellent: 'star',
    Good: 'good',
    Fair: 'fair',
    'Not a Fit': 'not-fit'
  }

  LeadScoreOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    const order = ['Excellent', 'Good', 'Fair', 'Not a Fit'];
    return order.indexOf(a.key) - order.indexOf(b.key);
  }

  lead_score = [];

  family_industry_interests = [];
  family_geographic_focus = [];
  family_wealth_origin = [];
  bt_classification_code: { value: string; label: string }[] = []
  hasNonAdvisorAccess = false;
  updateSaveFilterStatus = true;
  public readonly dbaContactId: string = 'dbaContactSearch';

  /**
   * Contacts
   */

  // Job, Title & Role
  private designationFilterSwitch = false;

  /**
   * Companies
   */

  // Bank & Trust
  private trustServices: boolean;
  private trustPowersOptions = []
  private trustTeams: boolean;

  // General Information
  private allocateExternalManagers: boolean;

  private disabledCache = new Map<number, boolean>();
  private planSelectOptions: PlanSelectOptions | null = null;
  toolTipIconOrnament: any;
  public isLoading = false;
  public aiFeedback = '';
  public typedFeedback = '';
  public isTyping = false;
  public errorMessage = '';
  searchQuery: string;
  scoreID: number;
  holding_filter = false;

  constructor(
    public router: Router,
    private _helper: Helper,
    public auth: Auth,
    private cdr: ChangeDetectorRef,
    public advizorProService: AdvizorProService,
    public planService: PlanService,
    public profileService: ProfileService,
    public userService: UserService,
    public reportService: ReportService,
    private scoreService: ScoreService,
    @Inject(DOCUMENT) private document: Document,
    private renderer2: Renderer2,
    private datePipe: DatePipe,
    private i18n: NzI18nService,
    private activatedRoute: ActivatedRoute,
    private readonly modalService: ModalService,
    private readonly modalServiceShared: ModalServiceShared ) {
    this.getZipcode();
    this.getTooltipCategory();
    
    this.advizorProService.$advTooltip.subscribe(value => {
      if (value) {
        this.toolTipOrnament = value as unknown as typeof ToolTipMessage
        this.toolTipIconOrnament = getIconsDataset(value as Record<string, string>);
      }
    });

    this.locationModalActive = false;

    this.paginate.pageNumber = 0;
    this.paginate.limit = 50;

    this.firmPaginate.pageNumber = 0;
    this.firmPaginate.limit = 50;

    this.paginateContact.pageNumber = 0;
    this.paginateContact.limit = 10;

    this.paginateSaved.pageNumber = 0;
    this.paginateSaved.limit = 10;

    if (this.auth.loggedIn()) {
      this.user = this.auth.getUser(); 

      if (this.user.has_access_insurance){
        this.user_setting = {known_non_advisor: 1, remove_known_non_advisor_individual:1, remove_known_non_producing_individual: 1};
      }else{
        this.user_setting = {known_non_advisor: 1, remove_known_non_advisor_individual:1, exclude_firm:[]};
      }

      if (this.user.salesforce) {
        this.salesforceAccess = this.user.salesforce
      }

      if (this.user.hubspot) {
        this.hubspotAccess = this.user.hubspot;
      }

      // Fill all dropdowns filter for plans (401k)
      if (this.user.has_access_defined_contribution || this.user.has_access_defined_benefit) {
              this.planService.searchFilter().subscribe((response: PlanSelectOptions) => {
                      if (response) {
                              this.planSelectOptions = response;
                              this.planSelectOptions.multipleEmployerPlan = [{key: "1", value: "1", label: "Yes"}, {key: "0", value: "0", label: "No"}];
                              this.planSelectOptions.myPlans = [
                                      {key: "Cold Lead", value: "Cold Lead", label: "Cold Lead"},
                                      {key: "Warm Lead", value: "Warm Lead", label: "Warm Lead"},
                                      {key: "Hot Lead", value: "Hot Lead", label: "Hot Lead"},
                                      {key: "Client", value: "Client", label: "Client"},
                                      {key: "Closed Lost", value: "Closed Lost", label: "Closed Lost"},
                              ];
                              this.planSelectOptions.plansWith = [
                                      {key: "email", label: 'Email Address', value: 'email' },
                                      {key: "phone", label: 'Phone Number', value: 'phone' },
                                      {key: "linkedin", label: 'LinkedIn', value: 'linkedin' }
                              ];
                      }
              });
      }

      this.userService.userSearchSetting().subscribe((res:any) => {
        if (res.data) {
          res.data.forEach((value)  => {
            if (value.key == 'exclude_firm') {
              this.user_setting[value.key] = JSON.parse(value.value);
              this.exclude_firm_name = JSON.parse(value.value);
              this.firm_exclude_checkbox = this.exclude_firm_name;
              this.search.exclude_firm = this.exclude_firm_name;
            } else {
              this.user_setting[value.key] = (value.value == '1')?1:0;
            }

            if (!this.user.has_access_insurance) {
              this.user_setting.remove_known_non_producing_individual = false;
            }
          });
        }
      });

      this.advizorProService.searchData$.subscribe((res:any) => {
        if (res == 'search') {
          this.initLoad();
        }
      });


      this.getScore();
      
    }

    window.onresize = () => {
      this.scrollBarHorizontal = (window.innerWidth < 960);
      this.columnModeSetting = (window.innerWidth < 960) ? 'standard' : 'force';
    };
    this.tooltipColor = '#000';

    this.advizorProService.advevent.subscribe(() => {
      this.setting_modal = true;
    });
    }

  _zip_code: any = [];
  set zip_code(value) {
    this._zip_code = value;
  }

  get zip_code() {
    if (this._zip_code.length > 0) {
        this.search.zip_code = this._zip_code;
    }
    return this._zip_code;
  }

  _person_zip_code: any = [];
  set person_zip_code(value) {
    this._person_zip_code = value;
  }

  get person_zip_code() {
    if (this._person_zip_code.length > 0) {
      this.search.person_zip_code = this._person_zip_code;
    }
    return this._person_zip_code;
  }

  _team_zip_code: any = [];
  set team_zip_code(value) {
    this._team_zip_code = value;
  }

  get team_zip_code() {
    if (this._team_zip_code.length > 0) {
        this.search.team_zip_code = this._team_zip_code;
    }
    return this._team_zip_code;
  }

  _text_title_contain: any = [];
  set text_title_contain(value) {
    this._text_title_contain = value;
  }

  get text_title_contain() {
    if (this._text_title_contain.length > 0) {
        this.search.text_title_contain = this._text_title_contain;
    }
    return this._text_title_contain;
  }

  _text_company_contain: any = [];
  set text_company_contain(value) {
    this._text_company_contain = value;
  }

  get text_company_contain() {
    if (this._text_company_contain.length > 0) {
        this.search.text_company_contain = this._text_company_contain;
    }
    return this._text_company_contain;
  }

  _text_carrier_contain: any = [];
  set text_carrier_contain(value) {
    this._text_carrier_contain = value;
  }

  get text_carrier_contain() {
    if (this._text_carrier_contain.length > 0) {
        this.search.text_carrier_contain = this._text_carrier_contain;
    }
    return this._text_carrier_contain;
  }

  getZipcode() {
    this.dis_zip_code_option = [
      { value: 5, label: '5 miles' },
      { value: 10, label: '10 miles' },
      { value: 25, label: '25 miles' },
      { value: 50, label: '50 miles' },
      { value: 100, label: '100 miles' },
      { value: 250, label: '250 miles' }
    ];
  }

  ngOnInit() {
    this.i18n.setLocale(en_US);
    if (this.auth.loggedIn()) {
      this.user = this.auth.getUser();

      if (!this.user.has_access_advisor  && !this.user.has_access_insurance) {
        // console.log('dashboard')
        // this.router.navigate(['/dashboard']);
      }

      this.getExportCreditsCount();
      //this.exportCreditCount = this.user.ap_export_credits;

      //if no AP access, but Insurance Access
      if (!this.user.has_access_advisor && this.user.has_access_insurance){
        this.onlyInsuranceAccess = true;
      }

      //if no Insurance access, but AP Access
      if ((this.user.has_access_advisor || this.user.has_access_team) && !this.user.has_access_insurance){
        this.onlyAdvizorAccess = true;
      }

      //if no Insurance/advisor access, but Family office Access
      if ((this.user.has_access_family_office || this.user.has_access_team) && !this.user.has_access_insurance && !this.user.has_access_advisor){
        this.onlyFamilyAccess = true;
      }

      //if Team Access
      if (this.user.has_access_team) {
        this.onlyTeamAccess = true;
      }
    } else {
      this.router.navigate(['/login']);
    }

    this.getSaveSearch();

    var clone_this = this;
    $(window).scroll(() => {
      clone_this.getClone(clone_this);
    });

    this.activatedRoute.queryParams.pipe(take(1)).subscribe(params => {
      if (params.searchId) {
        this._helper.setCookie('saved_search_param', params);
      } else {
        this.initLoad();
      }

      //remove querystring params
      if (Object.keys(params).length !== 0){
        this.router.navigate(
          [],
          {
            relativeTo: this.activatedRoute,
            queryParams: {},
            queryParamsHandling: ''
          });
      }
    });

    this.saved_search_param = this._helper.getCookie('saved_search_param');
    
    if (this.saved_search_param.searchId) {
      if (this.saved_search_param.date) {
        this.dateDiff = this.saved_search_param.date;
      }

      this._helper.removeCookie('saved_search_param');

      this.getData(parseInt(this.saved_search_param.searchId));
    }

    this.planService.dashPublish('ok');

    this.contain_delay.pipe(
      debounceTime(700),
    ).subscribe(() => {
      this.modelChanged()
    });

  }

  initLoad() {
    let getSearch = this._helper.getCookie('advizorSearchData');

    this.search = JSON.parse(JSON.stringify(getSearch));

    this.datasetFilterUpdate()
    
    if (Object.keys(this.search).length === 0) {
      let searchData = {};
      searchData = { file_name: 'searchAuto' };
      this.search = searchData;
    }

    if (Object.keys(this.saved_search_param).length > 0) {
      this.selectedSaved = parseInt(this.saved_search_param.searchId);
    } else {
      this.selectedSaved = this.search.save_search_id ? this.search.save_search_id : '';
    }

    /**
     * Contacts
     */

    // Job, Title & Role
    this.licensesFilterSwitch = this.search.licenses_filter_switch ? true : false;
    this.designationFilterSwitch = this.search.designation_filter_switch ? true : false;
    this.tickerFilterSwitch = this.search.ticker_filter_switch ? true : false;
    this.peopleWithFilterSwitch = this.search.people_with_filter_switch ? true : false;
    this.insLineFilterSwitch = this.search.ins_line_filter_switch ? true : false;
    this.insRegisterFilterSwitch = this.search.ins_register_filter_switch ? true : false;
    this.lead_score = this.search.lead_score ? this.search.lead_score : [];
    
    //intialize map info vars
    if(this.search_result.firm){
      this.search_result.firm.data = this.search_result.firm?.data? this.search_result.firm?.data : {}
    }

    if(this.search_result.person) {
      this.search_result.person.data = this.search_result.person?.data? this.search_result.person?.data : {}
    }
    this.mapActiveType = ''

    if (this.search.my_advizor_option){
      this.my_advizor_option = (this.search.my_advizor_option == 'Includes') ? true : false;
    }else{
      this.my_advizor_option = true;
    }

    if (this.search.firm_my_advizor_option){
      this.firm_my_advizor_option = (this.search.firm_my_advizor_option == 'Includes') ? true : false;
    }else{
      this.firm_my_advizor_option = true;
    }

    if (this.search.bulk_filter_option){
      this.bulk_filter_option = (this.search.bulk_filter_option == 'Includes') ? true : false;
    }else{
      this.bulk_filter_option = true;
    }

    if (this.search.custodian_option){
      this.custodian_option = (this.search.custodian_option == 'Includes') ? true : false;
    }else{
      this.search.custodian_option = true;
    }

    if (this.search.plan_my_plans_option){
      this.plan_my_plans_option = this.search.plan_my_plans_option === 'Includes' ? true : false;
    }else{
      this.plan_my_plans_option = true;
    }

    this.firm_name_search = (this.search.firm_name_search) ? this.search.firm_name_search : 'Excludes';

    if (this.search.hasOwnProperty('known_non_advisor')) {
      if (!Array.isArray(this.search.known_non_advisor) && this.search.known_non_advisor) {
        this.search.known_non_advisor = [1];
      }
    }

    if (this.search.hasOwnProperty('remove_known_non_advisor_individual')) {
      if (!Array.isArray(this.search.remove_known_non_advisor_individual) && this.search.remove_known_non_advisor_individua) {
        this.search.remove_known_non_advisor_individual = [1];
      }
    }

    if (!this.search.hasOwnProperty('known_non_advisor')) {
      this.search.known_non_advisor = [1];
    }

    if (!this.search.hasOwnProperty('remove_known_non_advisor_individual')) {
      this.search.remove_known_non_advisor_individual = [1];
    }

    if (!this.search.hasOwnProperty('remove_known_non_producing_individual')) {
      if (this.user.has_access_insurance){
        this.search.remove_known_non_producing_individual = 'true';
      }
    }

    if (!this.user.has_access_insurance){
      this.search.remove_known_non_producing_individual = false;
    }
/*
    if (!this.search.hasOwnProperty('remove_captive')) {
      if (this.user.has_access_insurance){
        this.search.remove_captive = 0;
      }
    }
*/
    if (this.search.hasOwnProperty('text_title_contain')) {
      if (Array.isArray(this.search.text_title_contain)) {
        this.text_title_contain = (this.search.text_title_contain[0]) ? this.search.text_title_contain : [];
      } else {
        this.text_title_contain = [this.search.text_title_contain];
      }
    }

    if (this.search.hasOwnProperty('text_company_contain')) {
      if (Array.isArray(this.search.text_company_contain)) {
        this.text_company_contain = (this.search.text_company_contain[0]) ? this.search.text_company_contain : [];
      } else {
        this.text_company_contain = [this.search.text_company_contain];
      }
    }

    if (this.search.hasOwnProperty('text_carrier_contain')) {
      if (Array.isArray(this.search.text_carrier_contain)) {
        this.text_carrier_contain = (this.search.text_carrier_contain[0]) ? this.search.text_carrier_contain : [];
      } else {
        this.text_carrier_contain = [this.search.text_carrier_contain];
      }
    }

    if (!this.search.firm_contains_includes) {
      this.search.firm_contains_includes = {
        website :true,
        adv: true,
        adv_brochures: true,
        links: true,
        adv_crs: true,
        bd_detailed_report: true,
        bd_crs: true
      };
    }

    this.firmContainsSelection();

    if (this.onlyInsuranceAccess) {
      this.search.known_non_advisor = [];
      this.search.remove_known_non_advisor_individual = [];
    }

    this.select_county = (this.search.county) ? this.search.county : [];
    this.select_company = (this.search.quick_search) ? this.search.quick_search : [];
    this.select_person_county = (this.search.person_county) ? this.search.person_county : [];
    this.select_title_contain = (this.search.title_contain) ? this.search.title_contain : [];
    this.select_metro_area = (this.search.metro_area) ? this.search.metro_area : [];
    this.select_person_metro_area = (this.search.person_metro_area) ? this.search.person_metro_area : [];
    this.select_team_metro_area = (this.search.team_metro_area) ? this.search.team_metro_area : [];
    this.select_wealth_creator = (this.search.wealth_creator) ? this.search.wealth_creator : [];
    this.select_title_type = (this.search.select_title_type) ? this.search.select_title_type : 'contains';
    this.select_carrier_type = (this.search.select_carrier_type) ? this.search.select_carrier_type : 'contains';
    this.select_company_type = (this.search.select_company_type) ? this.search.select_company_type : 'contains';
    this.select_affiliation = (this.search.affiliation) ? this.search.affiliation : [];
    this.select_custodian = (this.search.custodian) ? this.search.custodian : [];
    this.select_issuer_name = (this.search.issuer_name) ? this.search.issuer_name : [];
    this.select_class = (this.search.class13) ? this.search.class13 : [];
    this.select_ticker = (this.search.ticker) ? this.search.ticker : [];
    this.select_firm_name = (this.search.firm_name) ? this.search.firm_name : [];
    this.select_previous_firm = (this.search.previous_firm) ? this.search.previous_firm : [];
    this.select_ins_carriers = (this.search.insurance_carrier) ? this.search.insurance_carrier : [];
    this.select_ins_companies = (this.search.insurance_company) ? this.search.insurance_company : [];
    this.person_zip_code = (this.search.person_zip_code) ? this.search.person_zip_code : [];
    this.zip_code = (this.search.zip_code) ? this.search.zip_code : [];
    this.team_zip_code = (this.search.team_zip_code) ? this.search.team_zip_code : [];
    this.plan_plans_with_option = this.search.plan_plans_with_option ? true : false;
    
    if (this.search.gender) {
      this.gender_female = (this.search.gender.includes('female')) ? true : false;
      this.gender_male = (this.search.gender.includes('male')) ? true : false;
    }

    this.titleType = true;
    this.insCarrierType = true;
    this.insAgencyActive = true;

    if (!['contains', 'not_contains'].includes(this.select_title_type)) {
      this.titleType = false;
    }

    if (!['contains', 'not_contains'].includes(this.select_carrier_type)) {
      this.insCarrierType = false;
    }

    if (!['contains', 'not_contains'].includes(this.select_company_type)) {
      this.insAgencyActive = false;
    }

    this.bulkSearchValue = (this.search.bulk_search_value) ? this.search.bulk_search_value : 'rep_crd';

    this.autoSelectionTitle = this.select_title_contain;
    this.autoSelectionMetroArea = this.select_metro_area;
    this.autoSelectionWealthCreator = this.select_wealth_creator;
    this.autoSelectionPersonMetroArea = this.select_person_metro_area;
    this.autoSelectionTeamMetroArea = this.select_team_metro_area;
    this.autoSelectionFirm = this.select_firm_name;
    this.autoSelectionExcludeFirm = this.exclude_firm_name;
    this.autoSelectionPreviousFirm = this.select_previous_firm;
    this.autoSelectionCustodian = this.select_custodian;
    this.autoSelectionInsCarriers = this.select_ins_carriers;
    this.autoSelectionInsCompany = this.select_ins_companies;

    this.company_checkbox = this.select_company;
    this.firm_checkbox = this.select_firm_name;
    this.search.date_diff = this.dateDiff;
    
    if (this.updateSaveFilterStatus) {
      this.advizorProService.getExportCheckBox().subscribe((response: any) => {
        this.exclude_firm_checkbox = response.data.firm;
        this.exclude_person_checkbox = response.data.person;
      });

      this.getData();
    }

    this.updateSaveFilterStatus = true;

    if (this.search.people_with?.length > 0) {
      this.peopleWith.forEach(item => {
        if (this.search.people_with.includes(item.value)) {
          this.people_with.push(item.value);
        }
      });
    }

    /**
     * Company
     */

    // Bank & Trust
    this.trustServices = Boolean(this.search.bt_trust_services);

    // General Information
    this.allocateExternalManagers = this.search.allocate_external_managers === 1 ? true: false;
    

    this.makeSearch();
  }

  getTooltipCategory(params = {}) {
    this.advizorProService.getTooltipCategory(params).subscribe((res: { data: Panels }) => {
      this.panels = res.data

      if (res.data['Contacts'] !== undefined){
        this.hasContactFilters = true;
        this.processPanelData('Contacts', res.data);
        this.updatePanelData('Contacts');
      }

      if (res.data['Companies'] !== undefined){
        this.hasCompanyFilters = true;
        this.processPanelData('Companies', res.data);
        this.updatePanelData('Companies');
      }
    });
  }

  getClone(clone_this) {
    let new_table;
    let div;

    let $clone_head: any = $('p-datatable').find('table').find('thead');

    var offset = $clone_head.offset();
    var scroll = $(window).scrollTop();


    if ($clone_head == undefined) {
      return;
    }

    $('.ui-datatable.new-table').remove();

    if ($clone_head.length > 0) {
      let $clone = $clone_head.clone(true, true);
      new_table = $('<table>').append($clone);

      $clone.find('th').on('click', function () {
        var i = $(this).index();
        $($clone_head.find('th')[i]).click();

        setTimeout(() => {
          clone_this.getClone(clone_this);
        }, 200);
      });
      div = $('<div class="ui-datatable new-table"></div>').append(new_table)
      $('body').append(div);
    }

    if (div != undefined) {
      if (offset.top > scroll) {
        $(div).removeClass('sticy');
      } else {
        $(div).addClass('sticy');
      }
    }
  }

  savedSearchDiff(params) {
    this.advizorProService.getSavedSeachDiff(params).subscribe((res: any) => {
      this.saved_search_diff = res.data;
    });
  }

  getData(searchId=0) {
      forkJoin([
        this.advizorProService.getSearchFilter(),
        this.advizorProService.getWebSearchFilter()
      ]).subscribe(([searchData, searchFilterData]) => {
        if (searchData['data']) {
          let data = searchData['data'];
          this.types = data.types;
          this.states = data.states;
          this.morningstar_category = data.morningstar_category;
          this.business_type_po = data.business_types;
          this.advisor_services = data.advisor_services;
          this.firm_ownership = data.advisor_ownership;
          this.client_types = data.advizor_clients;
          this.licenses_registrations = data.licenses_registration;
          this.designation = data.person_designation;
          this.financial_planing_clients = data.financial_planing_clients;
          this.asset_types = data.asset_types;
          this.compensations = data.compensations;
          this.channels = data.channels;
          this.has_disclosure_type = data.advizor_person_disclosure;
          this.saveOfGroupOption = data.advizor_save_search;
          this.insurance_lines = data.insurance_lines;
          this.insurance_registered_states = data.insurance_registered_states;
          this.firm_asset_class = data.firm_asset_class;
          this.person_asset_class = data.person_asset_class;
          this.people_roles = data.people_roles;
          this.family_industry_interests = data.family_industry_interests;
          this.family_geographic_focus = data.family_geographic_focus;
          this.family_wealth_origin = data.family_wealth_origin;
          this.bt_classification_code= data.bt_classification_code;
          this.trustPowersOptions = data.trust_powers;
          if (searchId) {
            this.updateSaveFilter(searchId, true);
            this.search_active_url = false;
          }
        }

        if (searchFilterData['data']) {
          let data = searchFilterData['data'];
          this.web_advizor_firm_keyword = data.advizor_firm_keyword;
          this.web_advizor_person_keyword = data.advizor_person_keyword;
          this.firm_keyword_category = data.advizor_firm_category;
          if (this.search.firm_keyword_category && this.already_search_firm_category) {
            this.getSearchFirmFilter(this.search.firm_keyword_category)
            this.already_search_firm_category = false;
          }
          this.person_keyword_category = data.advizor_person_category;
          if (this.search.person_keyword_category && this.already_search_person_category) {
            this.getSearchPersonFilter(this.search.person_keyword_category)
            this.already_search_person_category = false;
          }
        }
      });

  }

  getSearchFirmFilter(value) {
    this.filtered_web_advizor_firm_keyword = [];
    let tempFilter = [];
    for (let i = 0; i < value.length; i++) {
      let letSearchFirmFilter =  this.web_advizor_firm_keyword.filter(
          item => item.tag_category == value[i]
          );
          this.filtered_web_advizor_firm_keyword = this.filtered_web_advizor_firm_keyword.concat(letSearchFirmFilter);
    }

    if (this.search.firm_keywords?.length > 0) {
      let old_firm_keywords = this.search.firm_keywords;
      for (let i = 0; i < this.search.firm_keywords.length; i++) {
        let searchKeywords =  this.filtered_web_advizor_firm_keyword.filter(
          item => item.keyword_display == this.search.firm_keywords[i]
          );
        tempFilter = tempFilter.concat(searchKeywords);
      }
      if(!value.length) {
        this.search.firm_keyword_category = false;
      }
      this.search.firm_keywords = tempFilter.map(function(a) {return a.keyword_display;});
      if(old_firm_keywords.length != this.search.firm_keywords.length) {
        this.modelChanged();
      }
    }
  }

  getCategories(value) {
    return this.web_advizor_person_keyword.filter(
      item => item.tag_category == value
    );
  }

  getFirmCategories(value){
    return this.web_advizor_firm_keyword.filter(
      item => item.tag_category == value 
    )
  }

  getSearchPersonFilter(value) {
    this.filtered_web_advizor_person_keyword = [];
    let tempFilter = [];
    for (let i = 0; i < value.length; i++) {
        let letSearchPersonFilter =  this.web_advizor_person_keyword.filter(
            item => item.tag_category == value[i]
            );
            this.filtered_web_advizor_person_keyword = this.filtered_web_advizor_person_keyword.concat(letSearchPersonFilter);
      }

    if (this.search.person_keywords?.length > 0) {
      let old_person_keywords = this.search.person_keywords
      for (let i = 0; i < this.search.person_keywords.length; i++) {
          let searchKeywords =  this.filtered_web_advizor_person_keyword.filter(
              item => item.keyword_display == this.search.person_keywords[i]
              );

              tempFilter = tempFilter.concat(searchKeywords);
        }
        if(!value.length) {
          this.search.person_keyword_category = false;
        }
        this.search.person_keywords = tempFilter.map(function(a) {return a.keyword_display;});

        if(old_person_keywords.length != this.search.person_keywords.length) {
          this.modelChanged();
        }
    }

  }


  getSearchFirm() {

    let search_args = Object.assign({}, this.search);
    if (!search_args.firm_contains) {
      delete search_args.firm_contains_includes;
    }

    const args = {
      limit: this.firmPaginate.limit,
      page: this.firmPaginate.pageNumber + 1,
      searchData: search_args,
      sortField: this.firmPaginate.sortField,
      sortOrder: this.firmPaginate.sortOrder
    }


    this.advizorProService.searchFirm(args).subscribe((response: any) => {
      this.search_result.firm = response.data;
    });
  }

  getSearchPerson() {
    let search_args = Object.assign({}, this.search);
    if (!search_args.firm_contains) {
      delete search_args.firm_contains_includes;
    }

    const args = {
      limit: this.paginate.limit,
      page: this.paginate.pageNumber + 1,
      searchData: search_args,
      sortField: this.paginate.sortField,
      sortOrder: this.paginate.sortOrder
    }
    this.advizorProService.searchPerson(args).subscribe((response: any) => {
        this.search_result.person = response.data;
        this.defCheck = false;
    });
  }

  getPlanOptions(value: string): void {
          if (this.planSelectOptions && value) return this.planSelectOptions[value];
  }

  sortDataFirm($event: { page: number }) {
    this.firmPaginate.pageNumber = $event.page - 1;
    this.getSearchFirm();
  }

  onSortFirm($event) {
    this.firmPaginate.sortField = $event.sorts[0].prop
    this.firmPaginate.sortOrder = $event.sorts[0].dir == 'asc' ? 1 : 0;
    this.getSearchFirm();
  }

  sortDataPerson($event: { page: number }) {
    this.paginate.pageNumber = $event.page - 1;
    this.getSearchPerson();
  }

  onSortPerson($event) {
    this.paginate.sortField = $event.sorts[0].prop
    this.paginate.sortOrder = $event.sorts[0].dir == 'asc' ? 1 : 0;
    this.getSearchPerson();
  }

  searchFirm() {
    return new Promise<boolean>((resolve) => {
      this.excel_search = Object.assign({}, this.search);

      if (!this.excel_search.firm_contains) {
        delete this.excel_search.firm_contains_includes;
      }

      this.advizorProService.searchFirm({
        limit: this.firmLimit,
        page: 1,
        searchData: this.excel_search,
        sortField: 'firm_name',
        sortOrder: 1
      }).
        subscribe((response: any) => {
          this.search_result.firm = response.data;
           resolve(true);
        }, () => {
            this.search_btn = {
              text: "Search",
              disabled: false
            };
            this.clear_btn = {
              text: "Clear",
              disabled: false
            };
          });
      });
  }

  searchPerson() {
    return new Promise<boolean>((resolve) => {
      this.excel_search = Object.assign({}, this.search);

      if (!this.excel_search.firm_contains) {
        delete this.excel_search.firm_contains_includes;
      }

      this.advizorProService.searchPerson({
        limit: this.limit,
        page: 1,
        searchData: this.excel_search,
        sortField: 'last_name',
        sortOrder: 1
      }).
        subscribe((response: any) => {
          this.search_result.person = response.data;
          this.advizorProService.savedSearchChange(true);
          resolve(true);
        }, () => {
            this.search_btn = {
              text: "Search",
              disabled: false
            };
            this.clear_btn = {
              text: "Clear",
              disabled: false
            };
          });
    });
  }

  endDateChange(result: Date): void {
    if (this.search.end_date_establish_date) {
      this.search.end_date_establish_date = this.datePipe.transform(
        result,
        "yyyy-MM-dd"
      );
      this.modelChanged();
    }
  }

  startDateChange(result: Date): void {
    if (this.search.start_date_establish_date) {
      this.search.start_date_establish_date = this.datePipe.transform(
        result,
        "yyyy-MM-dd"
      );
      this.modelChanged();
    }
  }

  endDateDiscChange(result: Date): void {
    if (this.search.end_latest_disclosure_date) {
      this.search.end_latest_disclosure_date = this.datePipe.transform(
        result,
        "yyyy-MM-dd"
      );
      this.modelChanged();
    }
  }

  startDateDiscChange(result: Date): void {
    if (this.search.start_latest_disclosure_date) {
      this.search.start_latest_disclosure_date = this.datePipe.transform(
        result,
        "yyyy-MM-dd"
      );
      this.modelChanged();
    }
  }

  startDateRegistrationChange(result: Date): void {
    if (this.search.start_registration_date) {
      this.search.start_registration_date = this.datePipe.transform(
        result,
        "yyyy-MM-dd"
      );
      this.modelChanged();
    }
  }

  endDateRegistrationChange(result: Date): void {
    if (this.search.end_registration_date) {
      this.search.end_registration_date = this.datePipe.transform(
        result,
        "yyyy-MM-dd"
      );
      this.modelChanged();
    }
  }

  startDateSecRegChange(result: Date): void {
    if (this.search.start_date_sec_registration) {
      this.search.start_date_sec_registration = this.datePipe.transform(
        result,
        "yyyy-MM-dd"
      );
      this.modelChanged();
    }
  }

  endDateSecRegChange(result: Date): void {
    if (this.search.end_date_sec_registration) {
      this.search.end_date_sec_registration = this.datePipe.transform(
        result,
        "yyyy-MM-dd"
      );
      this.modelChanged();
    }
  }

    startAptDateChange(result: Date): void {
    if (this.search.min_appointment_date) {
      this.search.min_appointment_date = this.datePipe.transform(
        result,
        "yyyy-MM-dd"
      );
      this.modelChanged();
    }
  }

  endAptDateChange(result: Date): void {
    if (this.search.max_appointment_date) {
      this.search.max_appointment_date = this.datePipe.transform(
        result,
        "yyyy-MM-dd"
      );
      this.modelChanged();
    }
  }

  async makeSearch() {
    // this.dual_icon = false;
    this.paginate.pageNumber = 0;
    this.firmPaginate.pageNumber = 0;
    if (!this.quick_search) {
      if (!isNaN(this.search.quick_search)) {
        this.search.quick_search = '';
      }
    }

    this.firmLoading = true;
    this.personLoading = true;

    this.messagesPerson = {
      emptyMessage: '<div class=\"mt-3 ml-3 bold\" align=\"center\">Searching persons...<\/div>',
    }
    this.messagesFirm = {
      emptyMessage: '<div class=\"mt-3 ml-3 bold\" align=\"center\">Searching firms...<\/div>',
    }

    if (this.is_search) {
      this.is_make_search = true;
    }

    this.search.search_option = 'live';
    this.search.select_title_type = this.select_title_type;
    this.search.select_carrier_type = this.select_carrier_type;
    this.search.select_company_type = this.select_company_type;
    this.search.my_advizor_option = this.my_advizor_option ? 'Includes' :'Excludes';
    this.search.firm_my_advizor_option = this.firm_my_advizor_option ? 'Includes' :'Excludes';
    this.search.bulk_filter_option = this.bulk_filter_option ? 'Includes' : 'Excludes';
    this.search.custodian_option = this.custodian_option ? 'Includes' : 'Excludes';

    this.search.firm_name_search = this.firm_name_search;
    this.search.licenses_filter_switch = this.licensesFilterSwitch;

    this.search.designation_filter_switch = this.designationFilterSwitch;

    this.search.ticker_filter_switch = this.tickerFilterSwitch;
    this.search.people_with_filter_switch = this.peopleWithFilterSwitch;
    this.search.ins_line_filter_switch = this.insLineFilterSwitch;
    this.search.ins_register_filter_switch = this.insRegisterFilterSwitch;

    this.search.bulk_search_value = this.bulkSearchValue;

    /**
     * Company
     */

    // Bank & Trust

    this.search.bt_trust_services = this.trustServices;
    this.search.bt_trust_teams = this.trustTeams;

    // General Information
    
    this.search.allocate_external_managers = this.allocateExternalManagers

    this._helper.setCookie('advizorSearchData', this.search);

    this.search_btn = {
      text : "Searching...",
      disabled: true
    };
    
    let hash = await this.crossSearch(this.search);
    //if hash, loop and look for a complete result
    if (hash){
      this.crossSearchLoop = true;

      while(this.crossSearchLoop) {
        await this.delay(1000);
        const status = await this.crossSearchStatus(hash);
        if (status) {
          this.search.hash = hash;
          break;
        }
      }
    } else {
      delete this.search.hash;
    }

    this.updatePanelData('Contacts');
    this.updatePanelData('Companies');
    this.searchRefresh('search');
    // this.countFilter()
  }

  delay(t) {
    return new Promise(resolve => setTimeout(resolve, t));
  }

  recipientCount() {
    this.advizorProService.recipientCount({
      limit: 98,
      page: 1,
      searchData: this.search,
      sortField: 'last_name',
      sortOrder: 1,
      recepient: 1
    }).
    subscribe(() => {
      });
  }

  isEmpty(item) {
    for (let key in item) {
      if (item[key] !== undefined && item[key] != null && item[key].length > 0) {
        return true;
      }
    }
    return false;
  }

  formatStringNumber(value) {
    value = String(value);
    return value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  convertStringToNumber(input: string) {

    if (!input) return 0;

    if (input.trim().length == 0) {
      return 0;
    }
    return Number(input);
  }

  exportAllCSV(type: string): void {
    let excel_count = this.exportExcelCount;
    this.isExportModal = false;

    if (!this.user.ap_user_limit_csv || (this.user.ap_user_limit_csv && this.user.ap_user_limit_csv <= excel_count)) {
      this.limit_over_model = true;
      return;
    }

    // this.waitingBox.show();
    this.isAntWaitingBox = true;

    this.downloadMessage.status_message = 'Your report is processing...';
    this.downloadMessage.status = "success";
    this.percent_complete = 0;

    this.exportStatus = true;
    let expArgs = {};

    this.tab_recognised_id = makeRandomString();

    const export_cols = {};
    export_cols['firm'] = this.export_firm_cols.filter(opt => opt.checked).map((opt) => opt.value);
    export_cols['person'] = this.export_person_cols.filter(
      opt => (opt.checked && (this.user[opt.permision_key] === 1 || opt?.permision_key === undefined))
    ).map((opt) => opt.value);

    if (this.exportType == 'firm') {
      this.excel_search = Object.assign({}, this.search);

      if (!this.excel_search.firm_contains) {
        delete this.excel_search.firm_contains_includes;
      }

      expArgs = {
        searchData: this.excel_search,
        is_export: 1,
        sortField: this.firmPaginate.sortField,
        sortOrder: this.firmPaginate.sortOrder,
        tab_recognised_id : this.tab_recognised_id,
        export_cols: export_cols,
        export_name: this.exportOptionalName,
        crm_friendly: this.crm_friendly,
        search_filters: this.search_filters,
        export_type: type,
        select_person: this.selected_person,
        omit_check: this.omit.check,
        omit_type: this.omit.type,
        omit_day: this.omit.day,
        person_count: this.search_result?.person?.total,
        firm_count: this.search_result?.firm?.total

      };

      this.advizorProService.searchFirm(expArgs).subscribe((response: ExportResponse) => {
              this.processExportResponse(response);
      });
    } else {
      this.excel_search = Object.assign({}, this.search);
      if (!this.excel_search.firm_contains) {
        delete this.excel_search.firm_contains_includes;
      }

      expArgs = {
        searchData: this.excel_search,
        is_export: 1,
        sortField: this.paginate.sortField,
        sortOrder: this.paginate.sortOrder,
        tab_recognised_id: this.tab_recognised_id,
        export_cols: export_cols,
        export_name: this.exportOptionalName,
        crm_friendly: this.crm_friendly,
        search_filters: this.search_filters,
        export_type: type,
        select_person: this.selected_person,
        omit_check: this.omit.check,
        omit_type: this.omit.type,
        omit_day: this.omit.day,
        person_count: this.search_result?.person?.total,
        firm_count: this.search_result?.firm?.total
      }

      this.advizorProService.searchPerson(expArgs).subscribe((response: ExportResponse) => {
              this.processExportResponse(response);
      });
    }
  }

  checkAdvReport(id) {
    this.reportService.getReportStatus(id).subscribe((data: { percent_complete: number; status: string; link: string; status_message: string }) => {
      this.percent_complete = data.percent_complete;
      if (data.status == 'complete') {
        this.isAntWaitingBox = false;
        this.crm_friendly = false;
        this.exportExcelCount = this.exportExcelCount + 1;
        this.advExport.unsubscribe();
        this.exportStatus = false;
        window.open(data.link);
      } else if (data.status == 'error') {
        // this.isAntWaitingBox = false;
        this.downloadMessage.status_message = data.status_message;
        this.downloadMessage.status = "error";
        this.advExport.unsubscribe();
        // this._helper.errorMessage('', data.status_message);
      }
    });
  }

  resetForm() {
    this.resetState();
    this.crossSearchLoop = false;
    this.firmLoading = true;
    this.personLoading = true;

    this.saved_search_param = null;

    this.people_with = [];
    
    this.selected_person = [];

    this.search = {};
    
    this.lead_score = [];
    this.zip_code = [];
    this.person_zip_code = [];
    this.team_zip_code = [];
    this.county = '';
    this.person_county = '';
    this.select_county = [];
    this.select_person_county = [];

    this.select_title_contain = [];
    this.text_title_contain = [];
    this.text_company_contain = [];
    this.text_carrier_contain = [];
    this.title_contain = [];
    this.titleType = true;
    this.autoSelectionTitle = [];
    
    this.metro_area = '';
    this.select_wealth_creator = [];

    this.wealth_creator = '';
    this.select_metro_area = [];

    this.person_metro_area = '';
    this.select_person_metro_area = [];

    this.team_metro_area = '';
    this.select_team_metro_area = [];

    this.previous_firm = '';
    this.select_previous_firm = [];

    this.affiliation = '';
    this.select_affiliation = [];

    this.custodian = '';
    this.select_custodian = [];

    this.issuer_name = '';
    this.select_issuer_name = [];

    this.class13 = '';
    this.select_class = [];

    this.ticker = '';
    this.select_ticker = [];

    this.firm_name = '';
    this.select_firm_name = [];

    this.quick_search = '';
    this.select_company = [];

    this.insurance_carrier = '';
    this.insurance_company = '';

    this.select_ins_carriers = [];
    this.select_ins_companies = [];

    this.selectedSaved = '';
    this.saved_search_diff = [];

    this.mapActive = false;

    this.save_search.option = 'live';

    this.select_title_type = 'contains';
    this.select_carrier_type = 'contains';
    this.select_company_type = 'contains';

    this.my_advizor_option = true;
    this.firm_my_advizor_option = true;
    this.bulk_filter_option = true;
    this.custodian_option = true;
    this.plan_my_plans_option = true;
    this.plan_plans_with_option = false;
    this.firm_name_search = 'Excludes';

    this.company_checkbox = [];
    this.firm_checkbox = [];

    this.defCheck = false;
    this.is_search = false;
    this.is_make_search = false;

    this.licensesFilterSwitch = false;
    this.designationFilterSwitch = false;
    this.tickerFilterSwitch = false;
    this.peopleWithFilterSwitch = false;
    this.insRegisterFilterSwitch = false;
    this.insLineFilterSwitch = false;

    this.bulkSearchValue = 'rep_crd';
    this.exportOptionalName = '';

    this.clear_btn = {
      text : "Clearing...",
      disabled : true
    };

    this.search.search_option = 'live';
    this.search.date_diff = '';
    this.dateDiff = null;
    this.search.select_title_type = this.select_title_type;
    this.search.select_carrier_type = this.select_carrier_type;
    this.search.select_company_type = this.select_company_type;

    this.search.my_advizor_option = 'Includes';
    this.search.firm_my_advizor_option = 'Includes';
    this.search.bulk_filter_option = 'Includes';

    this.search.custodian_option = 'Includes';
    this.search.firm_name_search = 'Excludes';

    this.search.firm_contains_includes = {
      website :true,
      adv: true,
      adv_brochures: true,
      links: true,
      adv_crs: true,
      bd_detailed_report: true,
      bd_crs: true
    };

    this.search_filter_modal = '';

    this.gender_male = false
    this.gender_female = false

    this.search.investment_committee = false

    /**
     * Company
     */

    // Bank & Trust
    this.trustServices = false
    this.trustTeams = false

    // General Information

    this.allocateExternalManagers = false

    // Teams
    
    this.search.team_member = false

    this.search = {...this.search, ...this.user_setting};

    if (this.user_setting.known_non_advisor) {
      this.search.known_non_advisor = [1];
    } else {
      this.search.known_non_advisor = [];
    }

    if (this.user_setting.remove_known_non_advisor_individual) {
      this.search.remove_known_non_advisor_individual = [1];
    } else {
      this.search.remove_known_non_advisor_individual = [];
    }

    this.firmContainsSelection();

    this.save_search = { 'search_name': '', 'option': 'live', 'shared_type': '0', 'save_exist_type': 1, exclude_firms: 0, exclude_persons: 0, search_update: 0 };
    this.planService.publish('');

    this._helper.setCookie('advizorSearchData', this.search);
    
    this.cate_active = {};
    this.expandSearchString = '';
    this.expandButton = false;
    this.hasNonAdvisorAccess = false;
    
    const allValuesTrue = Object.values(this.datasetSearchParamType).some(value => value === true);
    
    this.datasetSearchParamType = {};

    if (!allValuesTrue) {
      this.processPanelData('Contacts', this.panels);
      this.processPanelData('Companies', this.panels);
      this.updatePanelData('Contacts');
      this.updatePanelData('Companies');
    }

    this.searchRefresh();
  }

  async searchRefresh(type = 'clear') {
    this.filterCount();
    if (type == 'clear') {
      this.saved_search_param = Object.assign({}, {});
      this._helper.removeCookie('saved_search_param');

      if (this.onlyInsuranceAccess) {
        if (await this.searchPerson()) {
          this.personLoading = false;
          this.clear_btn = {
            text: "Clear",
            disabled: false
          };

          this.messagesPerson = {
            emptyMessage: '<div class=\"mt-3 ml-3 bold\" align=\"center\">Searching persons...<\/div>',
          }
        }
      } else {

        await Promise.all([this.searchPerson(), this.searchFirm()]);
        this.firmLoading = false;
        this.personLoading = false;
        this.clear_btn = {
          text: "Clear",
          disabled: false
        };

        this.messagesPerson = {
          emptyMessage: '<div class=\"mt-3 ml-3 bold\" align=\"center\">Searching persons...<\/div>',
        }
        this.messagesFirm = {
          emptyMessage: '<div class=\"mt-3 ml-3 bold\" align=\"center\">Searching firms...<\/div>',
        }
      }
    } else { 

      if (this.onlyInsuranceAccess) {
        if (await this.searchPerson()) {
          if ("person_zip_code" in this.search && this.search.person_zip_code.length && this.search_result.person.total != 0 && this.search_result.person.total <= 100) {
            this.mapActiveType = 'person';
          }

          this.search_btn = {
            text: "Search",
            disabled: false
          };

          this.personLoading = false;

          this.messagesPerson = {
            emptyMessage:  '<div class=\"mt-3 ml-3 bold\" align=\"center\">There are no persons that match the search criteria<\/div>',
          }

          if (this.search_result?.person?.total > 5000){
            this.over_export_row_limit = true;
          }else{
            this.over_export_row_limit = false;
          }

          if (this.saved_search_param.searchId) {
            this.saved_search_param = Object.assign({}, {});
            this._helper.removeCookie('saved_search_param');
          }
        }
      } else {
        await Promise.all([this.searchPerson(), this.searchFirm()]);
        if ("zip_code" in this.search && this.search.zip_code.length && this.search_result.firm.total != 0 && this.search_result.firm.total <= 100) {
          this.mapActiveType = 'firm';
        } else if ("person_zip_code" in this.search && this.search.person_zip_code.length && this.search_result.person.total != 0 && this.search_result.person.total <= 100) {
          this.mapActiveType = 'person';
        }

        this.search_btn = {
          text: "Search",
          disabled: false
        };

        this.firmLoading = false;
        this.personLoading = false;

        this.messagesPerson = {
          emptyMessage:  '<div class=\"mt-3 ml-3 bold\" align=\"center\">There are no persons that match the search criteria<\/div>',
        }
        this.messagesFirm = {
          emptyMessage: '<div class=\"mt-3 ml-3 bold\" align=\"center\">There are no firms that match the search criteria<\/div>',
        }

        if (this.search_result?.person?.total > 5000){
          this.over_export_row_limit = true;
        }else{
          this.over_export_row_limit = false;
        }

        if (this.saved_search_param.searchId) {
          this.saved_search_param = Object.assign({}, {});
          this._helper.removeCookie('saved_search_param');
        }
      }
    }
  }

  ngAfterContentChecked() {
    //your code to update the model
    this.cdr.detectChanges();
    this.scrollBarHorizontal = (window.innerWidth < 960);
    this.columnModeSetting = (window.innerWidth < 960) ? 'standard' : 'force';
    // window.dispatchEvent(new Event('resize'));
  }

  async callAIFilters(searchQuery: string): Promise<void> {
    this.isLoading = true;
    const prompt = `Process this request: ${searchQuery.trim()}.`;

    try {
      const res = await firstValueFrom(
        this.advizorProService.aiFilters({ prompt }).pipe(timeout(50000))
      );
      this.handleSuccess(res as AiFiltersResponse);
    } catch (error) {
      this.handleError();
    } finally {
      this.finishSearch();
    }
  }

  async onSearchButtonClick(searchInput: string): Promise<void> {
    await this.callAIFilters(searchInput);
  }

  private resetState(): void {
    this.aiFeedback = '';
    this.typedFeedback = '';
    this.isTyping = true;
    this.errorMessage = '';
  }

  private handleSuccess(res: AiFiltersResponse): void {
    try {
      this.aiFeedback = res?.data?.feedback ?? '';
      this.startTypingAnimation();
      this.updateSearch(res.data.filters);
    } catch (e) {
      this.handleError();
    } finally {
      this.finishSearch();
    }
  }

  private handleError(): void {
    this.errorMessage = 'Something went wrong, please try again later…';
    this.startTypingAnimation(true);
    this.finishSearch();
  }

  private finishSearch(): void {
    this.searchQuery = '';
    this.isLoading = false;
  }

  async callCompanyAutocomplete(text: string): Promise<object> {

    let companyList = []
    if(text.length > 1){
      companyList = (await firstValueFrom(
        this.advizorProService.getCompany({
          keyword: text.toLowerCase(),
          limit: 30
        }) as Observable<{[key:string]: string | number}[]>
      )) || [];
    }

    const suggestionList = (await firstValueFrom(
        this.advizorProService.getSuggestedPrompts({ 
            query: text.toLowerCase() 
        }) as Observable<{data: {[key:string]:string | number}[]}>
    ))?.data || [];
    
    this.search.crd_autocomplete = '';

    if (this.search.quick_search && this.select_company.length > 0) {
      this.search.quick_search = this.select_company.concat([this.quick_search]);
    } else {
      this.search.quick_search = [this.quick_search];
    }

    return  companyList.length <= 3? companyList.concat(suggestionList) : suggestionList.concat(companyList);
  }

  private startTypingAnimation(isError = false): void {
    const message = isError ? this.errorMessage : this.aiFeedback;
    let index = 0;
    let isProcessing = false;
    const queue: (() => void)[] = [];

    const processNext = () => {
      if (queue.length === 0) {
        isProcessing = false;
        // Completion logic goes here once all tasks are done
        return;
      }
      isProcessing = true;
      index = 0;
      this.typedFeedback = ''; // Reset typed feedback for the next task
      const nextTask = queue.shift();
      if (nextTask) nextTask(); // Execute the next task
    };
  
    const type = () => {
      if (index < message.length) {
        this.typedFeedback += message.charAt(index);
        index++;
        setTimeout(type, TYPING_SPEED); // Continue typing
      } else {
        processNext(); // Move to the next task in the queue
      }
    };
  
    const addTaskToQueue = (task: () => void) => {
      queue.push(task);
  
      if (!isProcessing) {
        processNext(); // Start processing if not already active
      }
    };
  
    // Enqueue the typing animation
    addTaskToQueue(() => type());
  }
  
  
  
  company_name_autocomplete = async (text: string): Promise<object> => {
    this.resetState();
    return await this.callCompanyAutocomplete(text);
  }

  selectCompany(data) {
    if(data?.item?.type === 'suggestion'){  
      this.search = JSON.parse(data?.item?.searchData)
      this.modelChanged()
      return 
    } 

    this.company_flag = true;
    const index: number = this.select_company.indexOf(data.value);
    if (index == -1) {
      this.select_company.push(data.value);
      this.search.quick_search = this.select_company;
    }    

    if (this.search.quick_full_search && this.search.quick_full_search !== 'null' && this.search.quick_full_search !== 'undefined') {
      if (!this.search.quick_full_search.hasOwnProperty(data.value)) {
        this.search.quick_full_search[data.value] = data.item.crd;
      }
    } else {
      this.search.quick_full_search = {};
      this.search.quick_full_search[data.value] = data.item.crd;
    }

    this.modelChanged();
  }

  removeCompany(item) {

    const index: number = this.select_company.indexOf(item);

    if (index !== -1) {
      this.select_company.splice(index, 1);
    }

    if (this.search.quick_search && this.search.quick_search.length > 0) {
      const index: number = this.search.quick_search.indexOf(item);
      if (index !== -1) {
        this.search.quick_search.splice(index, 1);
      }
    }

    if (this.search.quick_full_search && this.search.quick_full_search !== 'null' && this.search.quick_full_search !== 'undefined') {
      if (this.search.quick_full_search.hasOwnProperty(item)) {
        delete this.search.quick_full_search[item];
      }
    }

    this.modelChanged();

    const index1 = this.company_checkbox.indexOf(item);
    if (index1 > -1) {
      this.company_checkbox.splice(index1, 1);
      this.select_company = JSON.parse(JSON.stringify(this.company_checkbox));
    }
  }

  changeCompany() {
    setTimeout(() => {
      if (this.company_flag) {
        this.search.quick_search = this.select_company;
      }
      this.company_flag = false;
    }, 500);
  }

  preEvent($event, data) {
    $event.stopPropagation();
    this.quick_search = '';
    var index = this.company_checkbox.indexOf(data.name);
    if ($event.target.checked) {
      if (index == -1) {
        this.company_checkbox.push(data.name);
        if (this.search.quick_full_search && this.search.quick_full_search !== 'null' && this.search.quick_full_search !== 'undefined') {
          if (!this.search.quick_full_search.hasOwnProperty(data.name)) {
            this.search.quick_full_search[data.name] = data.crd;
          }
        } else {
          this.search.quick_full_search = {};
          this.search.quick_full_search[data.name] = data.crd;
        }
      }
    } else if (!$event.target.checked) {
      if (index > -1) {
        this.company_checkbox.splice(index, 1);
      }
    }

    this.select_company = JSON.parse(JSON.stringify(this.company_checkbox));
    this.search.quick_search = JSON.parse(JSON.stringify(this.company_checkbox));
    this.modelChanged();
  }
  
  preExcludeEvent($event, data) {
    $event.stopPropagation();
    this.exclude_firm = '';
    var index = this.firm_exclude_checkbox.indexOf(data.name);
    if ($event.target.checked) {
      if (index == -1) {
        this.firm_exclude_checkbox.push(data.name);
      }
    } else if (!$event.target.checked) {
      if (index > -1) {
        this.firm_exclude_checkbox.splice(index, 1);
      }
    }

    this.exclude_firm_name = JSON.parse(JSON.stringify(this.firm_exclude_checkbox));
    this.search.exclude_firm = JSON.parse(JSON.stringify(this.firm_exclude_checkbox));
    this.user_setting.exclude_firm = JSON.parse(JSON.stringify(this.firm_exclude_checkbox));
    // this.modelChanged();
  }

  preFirmEvent($event, data) {
    $event.stopPropagation();
    this.firm_name = '';
    var index = this.firm_checkbox.indexOf(data.name);
    if ($event.target.checked) {
      if (index == -1) {
        this.firm_checkbox.push(data.name);
      }
    } else if (!$event.target.checked) {
      if (index > -1) {
        this.firm_checkbox.splice(index, 1);
      }
    }

    this.select_firm_name = JSON.parse(JSON.stringify(this.firm_checkbox));
    this.search.firm_name = JSON.parse(JSON.stringify(this.firm_checkbox));
    this.modelChanged();
  }

  city_name_autocomplete = (text) => {
    return this.advizorProService.getCity({
      keyword: text.toLowerCase(),
      limit:30
    })
  }

  selectFirmCity(data) {
    this.search.city = data.value;
    this.modelChanged();
  }

  selectPersonCity(data) {
    this.search.person_city = data.value;
    this.modelChanged();
  }

  selectTeamCity(data) {
    this.search.team_city = data.value;
    this.modelChanged();
  }

  county_autocomplete = (text) => {
    const county = this.planService.getCounty({
      keyword: text.toLowerCase(),
      limit:30
    })
    return county;
  }

  selectCounty(data) {
    this.select_county.push(data.value);
    // this.search.county = this.select_county;
    this.search.county = JSON.parse(JSON.stringify(this.select_county));
    this.modelChanged();
  }

  removeCounty(item) {
    const index: number = this.select_county.indexOf(item);
    if (index !== -1) {
      this.select_county.splice(index, 1);
      this.modelChanged();
    }
  }

  selectPersonCounty(data) {
    this.select_person_county.push(data.value);
    // this.search.person_county = this.select_person_county;
    this.search.person_county = JSON.parse(JSON.stringify(this.select_person_county));
    this.modelChanged();
  }

  removePersonCounty(item) {
    const index: number = this.select_person_county.indexOf(item);
    if (index !== -1) {
      this.select_person_county.splice(index, 1);
      this.modelChanged();
    }
  }

  changeTitleContain() {
    setTimeout(() => {
        if (this.title_flag) {
        this.search.title_contain = this.select_title_contain;
      }
      this.title_flag = false;
    }, 500);
  }

  title_contain_autocomplete = (text) => {
    return this.planService.getTitleContain({
      keyword: text.toLowerCase(),
      limit:30
    })
  }

  keyUpTitleContain(data) {
    this.title_contain = '';
    if (data) {
      const index: number = this.select_title_contain.indexOf(data);
      if (index == -1) {
        this.select_title_contain.push(data);
        this.search.title_contain = this.select_title_contain;
      }
    }
  }

  selectTitleContain(data) {
    this.select_title_contain = data;
    this.search.title_contain = JSON.parse(JSON.stringify(this.select_title_contain));
    this.modelChanged();
  }

  removeTitleContain(item) {
    const index: number = this.select_title_contain.indexOf(item);
    if (index !== -1) {
      this.select_title_contain.splice(index, 1);
      this.search.title_contain = JSON.parse(JSON.stringify(this.select_title_contain));
      this.modelChanged();
    }
  }

  changeTitleType($e) {
    if (['contains', 'not_contains'].includes($e)) {
      this.title_contain = [];
      this.titleType = true;
      this.select_title_contain = [];
      this.autoSelectionTitle = [];
      delete this.search.title_contain;
    } else {
      this.titleType = false;
      this.text_title_contain = [];
      this.search.text_title_contain = [];
    }
    this.modelChanged();
  }

  changeWealthCreatorContain() {
    setTimeout(() => {
        if (this.wealth_creator_flag) {
        this.search.wealth_creator = this.select_wealth_creator;
      }
      this.wealth_creator_flag = false;
    }, 500);
  }

  wealth_creator_autocomplete = (text) => {
    const wealth_creators = this.advizorProService.getWealthCreator({
      keyword: text.toLowerCase(),
      limit:30
    })

    if (this.search.wealth_creator && this.select_wealth_creator.length > 0) {
      this.search.wealth_creator = this.select_wealth_creator;
      this.search.wealth_creator = this.search.wealth_creator.concat([this.wealth_creator]);
    } else {
      this.search.wealth_creator = [this.wealth_creator];
    }

    return wealth_creators;
  }

  selectWealthCreator(data) {
    this.select_wealth_creator = data;
    this.search.wealth_creator = this.select_wealth_creator;
    if (data.length > 0) {
      this.modelChanged();
    }
  }

  keyUpWealthCreatorContain(data) {
    this.wealth_creator = '';
    if (data) {
      const index: number = this.select_wealth_creator.indexOf(data);
      if (index == -1) {
        this.select_wealth_creator.push(data);
        this.search.wealth_creator = this.select_wealth_creator;
      }
    }
  }

  removeWealthCreator(item) {
    const index: number = this.select_wealth_creator.indexOf(item);
    if (index !== -1) {
      this.select_wealth_creator.splice(index, 1);
      this.modelChanged();
    }
  }

  changeMetroAreaContain() {
    setTimeout(() => {
        if (this.metro_area_flag) {
        this.search.metro_area = this.select_metro_area;
      }
      this.metro_area_flag = false;
    }, 500);
  }

  metro_area_autocomplete = (text) => {
    let metros = this.advizorProService.getMetroArea({
      keyword: text.toLowerCase(),
      limit:30
    })

    if (this.search.metro_area && this.select_metro_area.length > 0) {
      this.search.metro_area = this.select_metro_area;
      this.search.metro_area = this.search.metro_area.concat([this.metro_area]);
    } else {
      this.search.metro_area = [this.metro_area];
    }

    return metros;
  }

  selectMetroArea(data) {
    this.select_metro_area = data;
    this.search.metro_area = JSON.parse(JSON.stringify(this.select_metro_area));
    if (data.length > 0) {
      this.modelChanged();
    }
  }

  keyUpMetroAreaContain(data) {
    this.metro_area = '';
    if (data) {
      const index: number = this.select_metro_area.indexOf(data);
      if (index == -1) {
        this.select_metro_area.push(data);
        this.search.metro_area = this.select_metro_area;
      }
    }
  }

  removeMetroArea(item) {
    const index: number = this.select_metro_area.indexOf(item);
    if (index !== -1) {
      this.select_metro_area.splice(index, 1);
      this.search.metro_area = JSON.parse(JSON.stringify(this.select_metro_area));
    }
    this.modelChanged();
  }

  changePersonMetroAreaContain() {
    setTimeout(() => {
        if (this.person_metro_area_flag) {
        this.search.person_metro_area = this.select_person_metro_area;
      }
      this.person_metro_area_flag = false;
    }, 500);
  }

  person_metro_area_autocomplete = (text) => {
    let metros = this.advizorProService.getMetroArea({
      keyword: text.toLowerCase(),
      limit:30
    })

    if (this.search.person_metro_area && this.select_person_metro_area.length > 0) {
      this.search.person_metro_area = this.select_person_metro_area;
      this.search.person_metro_area = this.search.person_metro_area.concat([this.person_metro_area]);
    } else {
      this.search.person_metro_area = [this.person_metro_area];
    }

    return metros;
  }

  selectPersonMetroArea(data) {
    this.select_person_metro_area = data;
    this.search.person_metro_area = JSON.parse(JSON.stringify(this.select_person_metro_area));
    if (data.length > 0) {
      this.modelChanged();
    }
  }

  keyUpPersonMetroAreaContain(data) {
    this.person_metro_area = '';
    if (data) {
      const index: number = this.select_person_metro_area.indexOf(data);
      if (index == -1) {
        this.select_person_metro_area.push(data);
        this.search.person_metro_area = this.select_person_metro_area;
      }
    }
  }

  removePersonMetroArea(item) {
    const index: number = this.select_person_metro_area.indexOf(item);
    if (index !== -1) {
      this.select_person_metro_area.splice(index, 1);
      this.search.person_metro_area = JSON.parse(JSON.stringify(this.select_person_metro_area));
      this.modelChanged();
    }
  }

  changeTeamMetroAreaContain() {
    setTimeout(() => {
        if (this.team_metro_area_flag) {
        this.search.team_metro_area = this.select_team_metro_area;
      }
      this.team_metro_area_flag = false;
    }, 500);
  }

  team_metro_area_autocomplete = (text) => {
    let metros = this.advizorProService.getMetroArea({
      keyword: text.toLowerCase(),
      limit:30
    })

    if (this.search.team_metro_area && this.select_team_metro_area.length > 0) {
      this.search.team_metro_area = this.select_team_metro_area;
      this.search.team_metro_area = this.search.team_metro_area.concat([this.team_metro_area]);
    } else {
      this.search.team_metro_area = [this.team_metro_area];
    }

    return metros;
  }

  selectTeamMetroArea(data) {
    this.select_team_metro_area = data;
    this.search.team_metro_area = JSON.parse(JSON.stringify(this.select_team_metro_area));
    if (data.length > 0) {
      this.modelChanged();
    }
  }

  keyUpTeamMetroAreaContain(data) {
    this.team_metro_area = '';
    if (data) {
      const index: number = this.select_team_metro_area.indexOf(data);
      if (index == -1) {
        this.select_team_metro_area.push(data);
        this.search.team_metro_area = this.select_team_metro_area;
      }
    }
  }

  removeTeamMetroArea(item) {
    const index: number = this.select_team_metro_area.indexOf(item);
    if (index !== -1) {
      this.select_team_metro_area.splice(index, 1);
      this.search.team_metro_area = JSON.parse(JSON.stringify(this.select_team_metro_area));
      this.modelChanged();
    }
  }

  changeCarrierType($e) {
    if (['contains', 'not_contains'].includes($e)) {
      this.insCarrierType = true;
      this.insurance_carrier = [];
      this.select_ins_carriers = [];
      this.autoSelectionInsCarriers = [];
      delete this.search.insurance_carrier;
    } else {
      this.insCarrierType = false;
      this.search.text_carrier_contain = [];
      this.text_carrier_contain = [];
    }
    this.modelChanged();
  }

  changeCompanyType($e) {
    if (['contains', 'not_contains'].includes($e)) {
      this.insAgencyActive = true;
      this.insurance_company = [];
      this.select_ins_companies = [];
      this.autoSelectionInsCompany = [];
      delete this.search.insurance_company;
    } else {
      this.insAgencyActive = false;
      this.search.text_insurance_company = [];
      this.text_company_contain = [];
    }
    this.modelChanged();
  }

  previous_firm_autocomplete = (text) => {
    let previous_firm = this.planService.personFirmAutocomplete({
      keyword: text.toLowerCase(),
      limit: 30
    })

    if (this.search.previous_firm && this.select_firm_name.length > 0) {
      this.search.previous_firm = this.select_firm_name;
      this.search.previous_firm = this.search.previous_firm.concat([this.previous_firm]);
    } else {
      this.search.previous_firm = [this.previous_firm];
    }

    return previous_firm;
  }

  changePreviousFirm() {
    setTimeout(() => {
      if (this.previous_firm_flag) {
        this.search.previous_firm = this.select_previous_firm;
      }
      this.previous_firm_flag = false;
    }, 500);
  }

  keyUpPreviousFirm(data) {
    this.previous_firm = '';
    if (data) {
      const index: number = this.select_previous_firm.indexOf(data);
      if (index == -1) {
        this.select_previous_firm.push(data);
        this.search.previous_firm = this.select_previous_firm;
      }
    }
  }

  selectPreviousFirm(data) {
    this.previous_firm_flag = true;
    this.select_previous_firm = data;
    this.search.previous_firm = this.select_previous_firm;
    if (data.length > 0) {
      this.modelChanged();
    }
  }

  removePreviousFirm(item) {
    const index: number = this.select_previous_firm.indexOf(item);
    if (index !== -1) {
      this.select_previous_firm.splice(index, 1);
      this.modelChanged();
    }
  }

  ins_carriers_autocomplete = (text) => {
    let ins_carriers = this.advizorProService.insCarriersAutocomplete({
      keyword: text.toLowerCase(),
      limit: 30
    })
    return ins_carriers;
  }


  changeInsCarriers() {
    setTimeout(() => {
      if (this.ins_carriers_flag) {
        this.search.insurance_carrier = this.select_ins_carriers;
      }
      this.ins_carriers_flag = false;
    }, 500);
  }

  selectInsCarriers(data) {
    this.ins_carriers_flag = true;
    this.select_ins_carriers = data;
    this.search.insurance_carrier = this.select_ins_carriers;
    this.modelChanged();
  }

  removeInsCarriers(item) {
    const index: number = this.select_ins_carriers.indexOf(item);
    if (index !== -1) {
      this.select_ins_carriers.splice(index, 1);
      this.modelChanged();
    }
  }


  ins_company_autocomplete = (text) => {
    let ins_companies = this.advizorProService.insCompanyAutocomplete({
      keyword: text.toLowerCase(),
      limit: 30
    })

    return ins_companies;
  }

  changeInsCompany() {
    setTimeout(() => {
      if (this.ins_company_flag) {
        this.search.insurance_company = this.select_ins_companies;
      }
      this.ins_company_flag = false;
    }, 500);
  }

  selectInsCompany(data) {
    this.ins_company_flag = true;
    this.select_ins_companies = data;
    this.search.insurance_company = JSON.parse(JSON.stringify(this.select_ins_companies));
    this.modelChanged();
  }

  removeInsCompany(item) {
    const index: number = this.select_ins_companies.indexOf(item);
    if (index !== -1) {
      this.select_ins_companies.splice(index, 1);
      this.search.insurance_company = JSON.parse(JSON.stringify(this.select_ins_companies));
      this.modelChanged();
    }
  }

  affiliation_autocomplete = (text) => {
    let affiliation = this.planService.getAffiliation({
      keyword: text.toLowerCase(),
      limit: 30
    })

    if (this.search.affiliation && this.select_affiliation.length > 0) {
      this.search.affiliation = this.select_affiliation;
      this.search.affiliation = this.search.affiliation.concat([this.affiliation]);
    } else {
      this.search.affiliation = [this.affiliation];
    }
    return affiliation;
  }

  changeAffiliation() {
    setTimeout(() => {
      if (this.affiliation_flag) {
        this.search.affiliation = this.select_affiliation;
      }
      this.affiliation_flag = false;
    }, 500);
  }

  selectAffiliation(data) {
    this.affiliation_flag = true;
    this.select_affiliation.push(data.value);
    this.search.affiliation = this.select_affiliation;
    this.modelChanged();
  }

  removeAffiliation(item) {
    const index: number = this.select_affiliation.indexOf(item);
    if (index !== -1) {
      this.select_affiliation.splice(index, 1);
      this.modelChanged();
    }
  }

  custodian_autocomplete = (text: string) => {
    let custodians = this.planService.getCustodian({
      keyword: text.toLowerCase().trim(),
      limit: 125
    });

    if (this.search.custodian && this.select_custodian.length > 0) {
      this.search.custodian = this.select_custodian;
      if (this.custodian.trim()) {
        this.search.custodian =  this.search.custodian.concat([this.custodian]);
      }
    } else {
      this.search.custodian = [this.custodian];
    }

    return custodians;
  }

  selectCustodian(data: string[]) {
    this.select_custodian = data.filter((item: string) => item && item.trim() !== "");
    this.clearAssetsHeldByCustodian();
    this.search.custodian = this.select_custodian;
    if (data.length > 0) {
      this.modelChanged();
    }
  }

  clearAssetsHeldByCustodian() {
    if(this.select_custodian.length !== 1) {
      this.search.min_assets_held_by_custodian = '';
      this.search.max_assets_held_by_custodian = '';

      this.search.mix_assets_held_by_custodian_pct = ''
      this.search.max_assets_held_by_custodian_pct = ''
    }
  }

  removeCustodian(item: string) {
    const index: number = this.select_custodian.indexOf(item);
    if (index !== -1) {
      this.select_custodian.splice(index, 1);
      this.search.custodian = this.select_custodian;
      this.clearAssetsHeldByCustodian();
      this.modelChanged();
    }
  }

  changeCustodian() {
    setTimeout(() => {
      this.clearAssetsHeldByCustodian();
      if (this.custodian_flag) {
        this.search.custodian = this.select_custodian;
      }
      this.custodian_flag = false;
      this.modelChanged();
    }, 500);
  }

  keyUpCustodianContain(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;
    const data = inputElement.value.trim(); 

    this.custodian = '';
    if (data) {
      const index: number = this.select_custodian.indexOf(data);
      if (index == -1) {
        this.select_custodian.push(data);
        this.clearAssetsHeldByCustodian();
        this.search.custodian = this.select_custodian;
      }
    }
  }

  issuer_name_autocomplete = (text) => {
    return this.advizorProService.getIssuerName({
      keyword: text.toLowerCase(),
      limit:30
    })
  }

  selectIssuerName(data) {
    const index: number = this.select_issuer_name.indexOf(data.value);
    if (index == -1) {
      this.select_issuer_name.push(data.value);
      this.search.issuer_name = JSON.parse(JSON.stringify(this.select_issuer_name));
    }
  }

  removeIssuerName(item) {
    const index: number = this.select_issuer_name.indexOf(item);
    if (index !== -1) {
      this.select_issuer_name.splice(index, 1);
    }
  }

  keyUpIssuer(data) {
    this.issuer_name = '';
    if (data) {
      const index: number = this.select_issuer_name.indexOf(data);
      if (index == -1) {
        this.select_issuer_name.push(data);
        this.search.issuer_name = this.select_issuer_name;
      }
    }
  }

  class_autocomplete = (text) => {
    return this.advizorProService.getClass({
      keyword: text.toLowerCase(),
      limit: 30
    })
  }

  selectClass(data) {
    const index: number = this.select_class.indexOf(data.value);
    if (index == -1) {
      this.select_class.push(data.value);
      this.search.class13 = JSON.parse(JSON.stringify(this.select_class));
    }
  }

  removeClass(item) {
    const index: number = this.select_class.indexOf(item);
    if (index !== -1) {
      this.select_class.splice(index, 1);
    }
  }

  keyUpClass(data) {
    this.class13 = '';
    if (data) {
      const index: number = this.select_class.indexOf(data);
      if (index == -1) {
        this.select_class.push(data);
        this.search.class13 = this.select_class;
      }
    }
  }

  ticker_autocomplete = (text) => {
    return this.advizorProService.getTicker({
      keyword: text.toLowerCase(),
      limit: 30
    })
  }

  selectTicker(data) {
    const index: number = this.select_ticker.indexOf(data.value);
    if (index == -1) {
      this.select_ticker.push(data.value);
      this.search.ticker = JSON.parse(JSON.stringify(this.select_ticker));
      this.modelChanged();
    }
  }

  removeTicker(item) {
    const index: number = this.select_ticker.indexOf(item);
    if (index !== -1) {
      this.select_ticker.splice(index, 1);
      this.modelChanged();
    }
  }

  keyUpTicker(data) {
    this.ticker = '';
    if (data) {
      const index: number = this.select_ticker.indexOf(data);
      if (index == -1) {
        this.select_ticker.push(data);
        this.search.ticker = this.select_ticker;
      }
    }
  }

  firm_name_autocomplete = (text) => {
    let firms = this.planService.getFirmName({
      keyword: text.toLowerCase(),
      limit: 30
    })

    if (this.search.firm_name && this.select_firm_name.length > 0) {
      this.search.firm_name = this.select_firm_name;
      this.search.firm_name = this.search.firm_name.concat([this.firm_name]);
    } else {
      this.search.firm_name = [this.firm_name];
    }

    return firms;
  }

  changeFirmName() {
    setTimeout(() => {
      if (this.firm_name_flag) {
        this.search.firm_name = this.select_firm_name;
      }
      this.firm_name_flag = false;
    }, 500);
  }

  selectFirmName(data) {
    this.firm_name_flag = true;
    const index: number = this.select_firm_name.indexOf(data.value);
    if (index == -1) {
      this.select_firm_name.push(data.value);
      this.firm_checkbox = [];
      this.firm_checkbox = this.select_firm_name;

      this.search.firm_name = JSON.parse(JSON.stringify(this.select_firm_name));
      this.modelChanged();
    }
  }

  removeFirmName(item) {
    const index: number = this.select_firm_name.indexOf(item);
    if (index !== -1) {
      this.select_firm_name.splice(index, 1);
      this.modelChanged();
    }

    const index1 = this.firm_checkbox.indexOf(item);
    if (index1 > -1) {
      this.firm_checkbox.splice(index1, 1);
      this.select_firm_name = JSON.parse(JSON.stringify(this.firm_checkbox));
    }
  }

  showDialog(): void {
    this.save_search.search_name = (this.search.shared_type) ? this.search.file_name : '';
    this.save_search.shared_type = (this.search.shared_type) ? this.search.shared_type : '0';
    this.save_search.exclude_firms = (this.search.exclude_firms) ? this.search.exclude_firms : 0;
    this.save_search.exclude_persons = (this.search.exclude_persons) ? this.search.exclude_persons: 0;
    this.save_search.search_update = (this.search.search_update) ? this.search.search_update: 0;
    this.save_search.save_exist_type = 1;
    this.display = true;
  }

  displaySavedContentCancel() {
    this.display = false;
  }

  BasicAccess() {
    this.header_basic_access = true;
  }

  headerBasiAccessCancel() {
    this.header_basic_access = false;
  }

  saveSearchData(searchdata: SaveSearch): void {
    if (!searchdata.search_name) {
      this._helper.errorMessage('', 'Please Fill Search Name');
      return;
    }

    this.search.file_name = searchdata.search_name;
    this.search.search_type = 1;
    this.search.search_option = searchdata.option;
    this.search.shared_type = searchdata.shared_type;
    this.search.exclude_firms = searchdata.exclude_firms;
    this.search.exclude_persons = searchdata.exclude_persons;
    this.search.search_update = searchdata.search_update;

    this.search.my_advizor_option = this.my_advizor_option ? 'Includes' :'Excludes';
    this.search.firm_my_advizor_option = this.firm_my_advizor_option ? 'Includes' :'Excludes';
    this.search.bulk_filter_option = this.bulk_filter_option ? 'Includes' : 'Excludes';
    this.search.custodian_option = this.custodian_option ? 'Includes' : 'Excludes';

    this.search.licenses_filter_switch = this.licensesFilterSwitch;
    this.search.designation_filter_switch = this.designationFilterSwitch;
    this.search.ticker_filter_switch = this.tickerFilterSwitch;
    this.search.people_with_filter_switch = this.peopleWithFilterSwitch;
    this.search.ins_line_filter_switch = this.insLineFilterSwitch;
    this.search.ins_register_filter_switch = this.insRegisterFilterSwitch;

    /**
     * Companies
     */

    // Bank & Trust 
    this.search.bt_trust_services = this.trustServices
    this.search.bt_trust_teams = this.trustTeams

    // General Information
    this.search.allocate_external_managers = this.allocateExternalManagers

    const args: SearchData = JSON.parse(JSON.stringify(this.search));
    args.save_search_id = Number(searchdata.save_exist_type) == 1 ? this.search.save_search_id : '';
    args.select_carrier_type = this.select_carrier_type;
    args.select_company_type = this.select_company_type;
    args.select_title_type = this.select_title_type;
    args.is_saved_search_advizorpro = this.isAdvizorProSaved;

    this.planService.saveAdvisorSearch(args).subscribe((response: SaveSearchResponse) => {
        if (response?.status === 'error') {
                this._helper.errorMessage('', response.message);
                return;
        } 

        if (response?.status === 'ok' && response?.data) {
                this.isAdvizorProSaved = false;
                this.search.save_search_id = response.data.id;
                this.getSavedSearch();
                this._helper.setCookie('advizorSearchData', this.search);
                this._helper.successMessage('', 'Search saved sucessfully');
                this.display = false;
        }
      },
    );
  }

  getSavedSearch(): void {
    this.advizorProService.savedSearch().subscribe((response: SavedSearchOptionResponse) => {
        if (response) this.saveOfGroupOption = response;
    });
  }

  onInputClick() {

  }

  keyFormat(key='') {
    key = key.replace(/_/g, ' ');
    return key.replace(
      /\w\S*/g,
      function (txt: string) {
        return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
      }
    );
  }

  preCheckSearch(val) {
    let check = false;
    if (this.select_company.length) {
      this.select_company.forEach((item: string) => {
        if (val && val.indexOf(item) != -1) {
          check = true;
        }
      });
    }

    return check;
  }

  replace(str) {
    this.is_search = true;
    let key = this.keyFormat(str.key);

    let label = [];
    
    if (str.key == this.searchVariables.upload_person_crd || str.key == this.searchVariables.bulk_firm_city || str.key == this.searchVariables.bulk_person_city || str.key == this.searchVariables.bulk_firm_zip || str.key == this.searchVariables.bulk_person_zip || str.key == this.searchVariables.bulk_ticker || str.key == this.searchVariables.upload_crd && str.value || str.key == this.searchVariables.bulk_team_city && str.value || str.key == this.searchVariables.bulk_team_zip && str.value) {
      str.value = 'Bulk Search';
    } else if (str.value instanceof Array && str.value.length > 0) {
      str.value = str.value.slice(0, 10);
    }

    let labelName = '';

    if (str.key == 'quick_search') {
      if (str.value && str.value.length == 0) {
        return str.value = null;
      }
      if (this.select_company.length) {
        this.select_company.forEach((item: string) => {
          if (str.value && str.value.indexOf(item) != -1) {
            label.push(item);
          }
        });
      }
      if (label.length == 0) {
        return str.value = null;
      }

     return 'Search Criteria: ' + label.join(' or ');
    } else if (str.key == 'known_non_advisor') {
      if (str.value == '' || str.value == null || str.value.length == 0) {
        return str.value = null;
      }

      if (!Array.isArray(str.value)) {
        str.value = [1];
      }
      
      let label = [];

      if (str.value?.includes(1)) {
        label.push("Yes");
      }

      if (str.value?.includes(0)) {
        label.push("No");
      }
      return 'Remove Non-Advisor Firms: ' + label.join(' or ');
    } else if (str.key == 'date_diff') {
      let labelValue = this.datePipe.transform(str.value, 'MM-dd-yyyy');
      return 'Additions since: ' + labelValue;
    } else if (str.key == 'remove_known_non_advisor_individual') {
      let label = [];
      
      if (str.value == '' || str.value == null || str.value.length == 0) {
        return str.value = null;
      }

      if (!Array.isArray(str.value)) {
        str.value = [1];
      }

      if (str.value?.includes(1)) {
        label.push("Yes");
      }

      if (str.value?.includes(0)) {
        label.push("No");
      }
      return 'Remove Non-Advisor Individuals: ' + label.join(' or ');
    }  else if (str.key == 'remove_known_non_producing_individual') {
      labelName = (str.value) ? 'Yes' : 'No'
      return 'Remove Non-Producers: ' + labelName;
    }  else if (str.key == 'family_direct_investments') {
      labelName = (str.value) ? 'Yes' : 'No'
      return 'Family Direct Investments: ' + labelName;
    }  else if (str.key == 'remove_captive') {
      labelName = (str.value) ? 'Yes' : 'No'
      return 'Remove Captive Persons: ' + labelName;
    }
    else if (str.key == 'firm_contains') {
      labelName = 'Firm search contains ';

      if(this.search.firm_contains_includes.website == true || 
        this.search.firm_contains_includes.links == true || 
        this.search.firm_contains_includes.adv == true || 
        this.search.firm_contains_includes.adv_brochures == true || 
        this.search.firm_contains_includes.adv_crs == true || 
        this.search.firm_contains_includes.bd_crs == true || 
        this.search.firm_contains_includes.bd_detailed_report == true){
        let firm_contain_include = [];
        for (const [key, value] of Object.entries(this.search.firm_contains_includes)) {
          if(value == true){
            if (key == 'adv_brochures'){
              firm_contain_include.push('ADV Brochures');
            }else if (key == 'adv_crs'){
              firm_contain_include.push('ADV CRS');
            } else if (key == 'bd_crs'){
              firm_contain_include.push('BD CRS');
            } else if (key == 'bd_detailed_report'){
              firm_contain_include.push('BD Detailed Report');
            }
            else{
              firm_contain_include.push(key);
            }

          }
        }
        labelName += '(';
        labelName += firm_contain_include.join(" or ");;
        labelName += ')';
      }
      return labelName +': ' + str.value;
    } else if (str.key == 'firm_contains_includes' ) {
      str.key = 'search_option';
    }
    else if(str.key === 'investment_committee'){
      labelName = (str.value) ? 'Yes' : 'No'
      return 'Investment Committee: ' + labelName;
    }
    else if (str.key == 'has_no_disclosures') {
      labelName = (str.value) ? 'Yes' : 'No'
      return 'Has No Disclosures: ' + labelName;
    } else if (str.key == 'has_disclosures') {
      labelName = (str.value) ? 'Yes' : 'No'
      return 'Has Disclosures: ' + labelName;
    } else if (str.key == 'hustorical_aum') {
      labelName = (str.value) ? 'Yes' : 'No'
      return 'Historical AUM: ' + labelName;
    } else if (str.key == 'has_form_13F_filing') {
      labelName = (str.value) ? 'Yes' : 'No'
      return 'Has Form 13F Filing: ' + labelName;
    } else if (str.key == 'captive') {
      labelName = (str.value) ? 'Yes' : 'No'
      return  'Captive : ' + labelName;
    } else if (str.key == 'securities_registered') {
      labelName = (str.value) ? 'Yes' : 'No'
      return  'Securities Registered : ' + labelName;
    } else if (str.key == 'state') {
      if (str.value== null || str.value?.length == 0) {
        return str.value = null;
      }
      if (this.states.length) {
        this.states.forEach((item: { id: string; value: string }) => {
          if (str.value.indexOf(item.id) != -1) {
            label.push(item.value);
          }
        });
      }
      return  'Firm State : ' + label.join(' or ');
    } else if (str.key == 'morningstar_category') {
      if (str.value== null || str.value?.length == 0) {
        return str.value = null;
      }
      if (this.morningstar_category.length) {
        this.morningstar_category.forEach((item: { id: string; value: string }) => {
          if (str.value.indexOf(item.id) != -1) {
            label.push(item.value);
          }
        });
      }
      return  'M* Category : ' + label.join(' or ');
    } else if (str.key == 'registered_state') {
      if (str.value.length == 0) {
        return str.value = null;
      }
      if (this.states.length) {
        this.states.forEach((item: { id: string; value: string }) => {
          if (str.value.indexOf(item.id) != -1) {
            label.push(item.value);
          }
        });
      }
      return  'Registered State : ' + label.join(' or ');
    } else if (str.key == 'person_state') {
      if (str.value== null || str.value?.length == 0) {
        return str.value = null;
      }
      if (this.states.length) {
        this.states.forEach((item: { id: string; value: string }) => {
          if (str.value.indexOf(item.id) != -1) {
            label.push(item.value);
          }
        });
      }
      return  'Person State : ' + label.join(' or ');
    } else if (str.key == 'business_type_po') {
      if (str.value == 0 || str.value == null || str.value?.length == 0) {
        return str.value = null;
      }
      if (this.business_type_po) {
        this.business_type_po.forEach((item: { label: string }) => {
          if (str.value.indexOf(item.label) != -1) {
            label.push(item.label);
          }
        });
      }
    } else if (str.key == 'advisor_service') {
      if (str.value == 0 || str.value== null || str.value?.length == 0) {
        return str.value = null;
      }
      if (this.advisor_services) {
        this.advisor_services.forEach((item: { label: string }) => {
          if (str.value.indexOf(item.label) != -1) {
            label.push(item.label);
          }
        });
      }
    } else if (str.key == 'firm_ownership') {
      if (str.value == null || str.value?.length == 0) {
        return str.value = null;
      }
      if (this.firm_ownership) {
        this.firm_ownership.forEach((item: { label: string }) => {
          if (str.value.indexOf(item.label) != -1) {
            label.push(item.label);
          }
        });
      }
    } else if (str.key == 'family_firm_asset_class') {
      if (str.value == 0 || str.value== null || str.value?.length == 0) {
        return str.value = null;
      }
      if (this.firm_asset_class) {
        this.firm_asset_class.forEach((item: { label: string }) => {
          if (str.value.indexOf(item.label) != -1) {
            label.push(item.label);
          }
        });
      }
    } else if (str.key == 'family_person_asset_class') {
      if (str.value == 0 || str.value== null || str.value?.length == 0) {
        return str.value = null;
      }
      if (this.person_asset_class) {
        this.person_asset_class.forEach((item: { label: string }) => {
          if (str.value.indexOf(item.label) != -1) {
            label.push(item.label);
          }
        });
      }
    } else if (str.key == 'people_role') {
      if (str.value == 0 || str.value== null || str.value?.length == 0) {
        return str.value = null;
      }
      this.people_roles.forEach((item: { value: string; label: string }) => {
        if (str.value.indexOf(item.value) != -1) {
          label.push(item.label);
        }
      });
      return 'Role: ' + label.join(' or ');
    } else if (str.key == 'person_family_office_type') {
      if (str.value == 0) {
        return str.value = null;
      }

      this.familyType.forEach((item: { name: string; value: string }) => {
         if (str.value.indexOf(item.value) != -1) {
          label.push(item.name);
        }
      });
    } else if (str.key == 'firm_family_office_type') {
      if (str.value == 0 || str.value== null || str.value?.length == 0) {
        return str.value = null;
      }
      this.familyType.forEach((item: { name: string; value: string }) => {
         if (str.value.indexOf(item.value) != -1) {
          label.push(item.name);
        }
      });
    } else if (str.key == 'previous_firm') {
      if (this.select_previous_firm.length == 0) {
        return str.value = null;
      }
      this.select_previous_firm.forEach((item: string) => {
        label.push(item);
      });
      return 'Previous Firm : ' + label.join(' or ');
    } else if (str.key == 'my_advisor') {
        return  'Contact Lead Status : ' + str.value.join(' or ');
    }
    else if (str.key == 'distance_of_zip') {
      if (str.value.length == 0) {
        return str.value = null;
      }
      this.dis_zip_code_option.forEach((item: { value: number; label: string }) => {
        if (str.value == item.value) {
          label.push(item.label);
        }
      });
    } else if (str.key == 'licenses_registration') {
      if (str.value== null || str.value.length == 0) {
        return str.value = null;
      }
      if (this.licenses_registrations) {
        this.licenses_registrations.forEach((item: { id: string; name: string }) => {
          if (str.value.indexOf(item.id) != -1) {
            label.push(item.name);
          }
        });

        let type = this.licensesFilterSwitch ? ' or ': ' and ';
        return key + ': ' + label.join(type);
      }
    } else if (str.key == 'insurance_line') {
      if (str.value == null || str.value?.length == 0) {
        return str.value = null;
      }

      let type = this.insLineFilterSwitch ? ' and ': ' or ';
      return key + ': ' + str.value.join(type);
    } else if (str.key == 'insurance_registered_state') {
      if (str.value?.length == 0 || str.value == null || str.value?.length == 0) {
        return str.value = null;
      }

      let type = this.insRegisterFilterSwitch ? ' and ': ' or ';
      return 'State Licenses: ' + str.value.join(type);
    } else if (str.key == 'client_type') {
      if (str.value== null || str.value?.length == 0) {
        return str.value = null;
      }
      if (this.client_types) {
        this.client_types.forEach((item: { label: string }) => {
          // if (item.label == 'HNW') {
          //   item.label = 'High-Net-Worth';
          //   str.value = 'High-Net-Worth';
          // }
          if (str.value.indexOf(item.label) != -1) {
            label.push(item.label);
          }
        });
      }
    } else if (str.key == 'affiliation') {
      if (this.select_affiliation.length == 0) {
        return str.value = null;
      }
      this.select_affiliation.forEach((item: string) => {
        label.push(item);
      });
      return key + ': ' + label.join(' or ');
    } else if (str.key == 'insurance_carrier' || str.key == 'text_carrier_contain') {
      if (str.key == 'text_carrier_contain') {
        if (this.text_carrier_contain.length == 0) {
          return str.value = null;
        }
        if (this.text_carrier_contain && Array.isArray(this.text_carrier_contain)){
          this.text_carrier_contain.forEach((item: string) => {
            label.push(item);
          });
          str.value = label.join(' or ');
        } else {
          return str.value = null;
        }
      } else if (['contains', 'not_contains'].includes(this.select_ins_carriers)) {
        if (this.select_ins_carriers && Array.isArray(this.select_ins_carriers)){
          this.select_ins_carriers.forEach((item: string) => {
            label.push(item);
          });
          str.value = label.join(' or ');
        }

      } else {
        if (Array.isArray(str.value)) {
          str.value = str.value.join(' or ');
        }
      }
      return 'Insurance Carrier ' + this.keyFormat(this.select_carrier_type) + ': ' + str.value;
    } else if (str.key == 'insurance_company' || str.key == 'text_company_contain') {
      if (str.key == 'text_company_contain') {
        if (this.text_company_contain.length == 0) {
          return str.value = null;
        }
        if (this.text_company_contain && Array.isArray(this.text_company_contain)){
          this.text_company_contain.forEach((item: string) => {
            label.push(item);
          });
          str.value = label.join(' or ');
        } else {
          return str.value = null;
        }
      } else if (!['contains', 'not_contains'].includes(this.select_ins_companies)) {
        if (this.select_ins_companies && Array.isArray(this.select_ins_companies)){
          this.select_ins_companies.forEach((item: string) => {
            label.push(item);
          });
          str.value = label.join(' or ');
        }

      } else {
        if (Array.isArray(str.value)) {
          str.value = str.value.join(' or ');
        }
      }
      return 'Insurance Agency ' + this.keyFormat(this.select_company_type) + ': ' + str.value;
    } else if (str.key == 'title_contain' || str.key == 'text_title_contain') {
      if (str.key == 'text_title_contain') {
        if (this.text_title_contain.length == 0) {
          return str.value = null;
        }

        if (this.text_title_contain && Array.isArray(this.text_title_contain)){
          if (this.text_title_contain.length > 10) {
            label = this.text_title_contain.slice(0, 10);
            str.value = label.join(' or ');
            str.value = str.value+' and '+ (this.text_title_contain.length - 10) +' more';
          } else {
            this.text_title_contain.forEach((item: string) => {
              label.push(item);
            });
            str.value = label.join(' or ');
          }
        } else {
          return str.value = null;
        }
      } else if (!['contains', 'not_contains'].includes(this.select_title_type)) {
        if (this.select_title_contain && Array.isArray(this.select_title_contain)){
          this.select_title_contain.forEach((item: string) => {
            label.push(item);
          });
          str.value = label.join(' or ');
        }

      } else {
        if (Array.isArray(str.value)) {
          str.value = str.value.join(' or ');
        }
      }
      return 'Title ' + this.keyFormat(this.select_title_type) + ': ' + str.value;
    } else if (str.key == 'wealth_creator') {
      if (this.select_wealth_creator.length == 0) {
        return str.value = null;
      }

      this.select_wealth_creator.forEach((item: string) => {
        label.push(item);
      });
      return key + ': ' + label.join(' or ');
    } else if (str.key == 'metro_area') {
      if (this.select_metro_area.length == 0) {
        return str.value = null;
      }

      this.select_metro_area.forEach((item: string) => {
        label.push(item);
      });
      return key + ': ' + label.join(' or ');
    } else if (str.key == 'person_metro_area') {
      if (this.select_person_metro_area.length == 0) {
        return str.value = null;
      }

      this.select_person_metro_area.forEach((item: string) => {
        label.push(item);
      });
      return key + ': ' + label.join(' or ');
    } else if (str.key == 'insurance_carrier' || str.key == 'text_carrier_contain') {
      if (Array.isArray(str.value)) {
        str.value = str.value.join(' or ');
      }
      return 'Insurance Carriers ' + this.keyFormat(this.select_carrier_type) + ': ' + str.value;
    } else if (str.key == 'insurance_company' || str.key == 'text_insurance_company') {
      if (Array.isArray(str.value)) {
        str.value = str.value.join(' or ');
      }
      return 'Insurance Agency ' + this.keyFormat(this.select_company_type) + ': ' + str.value;
    } else if (str.key == 'custodian') {
      if (this.select_custodian.length == 0) {
        return str.value = null;
      }

      this.select_custodian.forEach((item: string) => {
        label.push(item);
      });
      return key + ': ' + label.join(' or ');
    } else if (str.key == 'issuer_name') {
      if (this.select_issuer_name.length == 0) {
        return str.value = null;
      }
      this.select_issuer_name.forEach((item: string) => {
        label.push(item);
      });
      return key + ': ' + label.join(' or ');
    } else if (str.key == 'class13') {
      if (this.select_class.length == 0) {
        return str.value = null;
      }
      this.select_class.forEach((item: string) => {
        label.push(item);
      });
      return key + ': ' + label.join(' or ');
    } else if (str.key == 'ticker') {
      if (this.select_ticker.length == 0) {
        return str.value = null;
      }
      this.select_ticker.forEach((item: string) => {
        label.push(item);
      });
      let type = this.tickerFilterSwitch ? ' and ': ' or ';
      return key + ': ' + label.join(type);
    } else if (str.key == 'firm_name') {
      if (this.select_firm_name.length == 0) {
        return str.value = null;
      } else if (this.select_firm_name.length > 10) {
        return key + ' (' + this.search.firm_name_search +'): ' + 'Multiple';
      }
      this.select_firm_name.forEach((item: string) => {
        label.push(item);
      });
      return key + ' (' + this.firm_name_search+ '): ' + label.join(' or ');
    } else if (str.key == 'zip_code') {
      if (this.zip_code.length == 0) {
        return str.value = null;
      }
      this.zip_code.forEach((item: string) => {
        label.push(item);
      });
      return  'Firm Zip Code: ' + label.join(' or ');
    } else if (str.key == 'person_zip_code') {
      if (this.person_zip_code.length == 0) {
        return str.value = null;
      }
      this.person_zip_code.forEach((item: string) => {
        label.push(item);
      });
      return  'Person Zip Code: ' + label.join(' or ');
    } else if (str.key == 'county') {
      if (this.select_county.length == 0) {
        return str.value = null;
      }
      this.select_county.forEach((item: string) => {
        label.push(item);
      });
      return 'Firm County : ' + label.join(' or ');
    } else if (str.key == 'person_county') {
      if (this.select_person_county.length == 0) {
        return str.value = null;
      }
      this.select_person_county.forEach((item: string) => {
        label.push(item);
      });
      return key + ': ' + label.join(' or ');
    } else if (str.key == 'people_with') {
      if (str.value == null || str.value?.length == 0) {
        return str.value = null;
      }
      str.value.forEach((item: string) => {
        if (item == 'bio_url') {
          label.push('Website Bio');
        }else if (item == 'personal_email') {
          label.push('Personal Email');
        }else if (item == 'email') {
          label.push('Business Email');
        } else {
          label.push(item.charAt(0).toUpperCase() + item.slice(1));
        }
      });
      let type = this.peopleWithFilterSwitch ? ' or ': ' and ';
      return key + ': ' + label.join(type);
    } else if(str.key == 'city') {
      if (str.value == 0) {
        return str.value = null;
      }
      return  'Firm City : ' + str.value;

    } else if(str.key == 'bulk_search_value') {
        return str.value = null;

    } else if (str.key == 'min_establish_date') {
      if (str.value == null) {
        return str.value = null;
      }
      return  'Min Establish Date : ' + str.value;
    } else if (str.key == 'min_advisor_count') {
      return  'Min RIA Reps : ' + str.value;
    }
    else if (str.key == 'max_advisor_count') {
      return  'Max RIA Reps : ' + str.value;
    }
    else if (str.key == 'min_bd_reps') {
      return  'Min BD Reps : ' + str.value;
    }
    else if (str.key == 'max_bd_reps') {
      return  'Max BD Reps : ' + str.value;
    }
    else if (str.key == 'min_ins_licensed_reps') {
      return  'Max Insurance Licensed Reps : ' + str.value;
    }
    else if (str.key == 'max_ins_licensed_reps') {
      return  'Max Insurance Licensed Reps : ' + str.value;
    } else if (str.key == 'person_alma_mater_keywords') {
    return  'Alma Mater : ' + str.value.join(' or ');
    } else if (str.key == 'person_hobbie_keywords') {
      return  'Hobbies : ' + str.value.join(' or ');
    } else if (str.key == 'person_family_keywords') {
    return  'Family : ' + str.value.join(' or ');
    } else if (str.key == 'person_sports_team_keywords') {
      return 'Sports Teams : ' + str.value.join(' or ');
    } else if (str.key == 'person_greek_life_keywords') {
      return  'Greek Life : ' + str.value.join(' or ');
    } else if (str.key == 'person_military_status_keywords') {
      return  'Military Status : ' + str.value.join(' or ');
    } else if (str.key == 'person_services_keywords') {
      return  'Services : ' + str.value.join(' or ');
    } else if (str.key == 'person_accolade_award_keywords') {
      return  'Accolades & Awards : ' + str.value.join(' or ');
    } else if (str.key == 'person_investment_keywords') {
      return  'Investments : ' + str.value.join(' or ');
    } else if (str.key == 'person_faith_based_investing_keywords') {
      return  'Faith Based Investing : ' + str.value.join(' or ');
    } else if (str.key == 'person_asset_classes') {
      return  'Asset Classes : ' + str.value.join(' or ');
    } else if (str.key == 'hash'){
      return str.value = null;
    } else if (str.key == 'designation'){
      if (str.value == null || str.value?.length == 0) {
        return str.value = null;
      }
      let type = this.designationFilterSwitch ? ' or ': ' and ';
      return key + ': ' + str.value.join(type);
    }
    /**
     * Companies
     */

    // Bank & Trust
    else if (str.key == "bt_trust_services") {
      labelName = (str.value) ? 'Yes' : 'No'
      return 'Trust services: ' + labelName;
    } else if (str.key == "bt_trust_teams") {
      labelName = (str.value) ? 'Yes' : 'No'
      return 'Trust team: ' + labelName;
    }
    else if (str.key == "bt_trust_powers"){
      if (str.value == 0 || str.value== null || str.value?.length == 0) {
        return str.value = null;
      }
      this.trustPowersOptions.forEach((item: { value: string; label: string }) => {
        if (str.value.indexOf(item.value) != -1) {
          label.push(item.label);
        }
      });
      return 'Trust powers: ' + label.join(' or ');
    }
    else if (str.key == "bt_classification_code"){
      if (str.value == 0 || str.value== null || str.value?.length == 0) {
        return str.value = null;
      }

      this.bt_classification_code.forEach((item) => {
        if (str.value.indexOf(item.value) != -1) {
          label.push(item.label);
        }
      });
      return 'Classification: ' + label.join(' or ');
    }
    // General Information
    else if (str.key == "allocate_external_managers") {
      labelName = (str.value) ? 'Yes' : 'No'
      return 'Allocates to External Managers: ' + labelName;
    }
    // Teams
    else if (str.key == 'team_zip_code') {
      if (this.team_zip_code.length == 0) {
        return str.value = null;
      }
      this.team_zip_code.forEach((item) => {
        label.push(item);
      });
      return  'Team Zip Code: ' + label.join(' or ');
    } else if (str.key == 'team_metro_area') {
      if (this.select_team_metro_area.length == 0) {
        return str.value = null;
      }

      this.select_team_metro_area.forEach((item) => {
        label.push(item);
      });
      return key + ': ' + label.join(' or ');
    } else if (str.key === this.searchVariables.team_member) {
      labelName = (str.value) ? 'Yes' : 'No'
      return `Member of a Team: ${labelName}`;
    } else if (str.key === 'firm_platform_keywords') {
      return `Platform : ${str.value.join(' or ')}`;
    } else if (str.key === 'firm_client_personas_keywords') {
      return `Client Personas : ${str.value.join(' or ')}`;
    } else if (str.key === 'firm_investment_themes_keywords') {
      return `Investment Themes : ${str.value.join(' or ')}`;
    } else if (str.key === 'firm_investment_vehicles_keywords') {
      return `Investment Vehicles : ${str.value.join(' or ')}`;
    } 
    // plans
    else if (str.key === this.searchVariables.plan_type) {
            this.planSelectOptions.planType.forEach((item) => {
                    if (str.value.indexOf(item.value) != -1) {
                            label.push(item.label);
                    }
            });
            return `Plan Type: ${label.join(' or ')}`;
    } else if (str.key === this.searchVariables.plan_insights_red_flags) {
            this.planSelectOptions.redFlags.forEach((item) => {
                    if (str.value.indexOf(item.code) != -1) {
                            label.push(item.name);
                    }
            });
            return `Insights & Red Flags: ${label.join(' or ')}`;
    } else if (str.key === this.searchVariables.plan_multiple_employer_plan) {
            const labelName = str.value.includes("1") ? 'Yes' : 'No'
            return `Multiple Employer Plan: ${labelName}`;
    } else if (str.key === this.searchVariables.plan_my_plans) {
            return `My Plans: ${str.value.join(", ")}`;
    } else if (str.key === this.searchVariables.plan_plans_with) {
      if (!str.value) return str.value = null;
      const type = this.plan_plans_with_option ? ' and ': ' or ';
      return `Plans With: ${str.value.join(type)}`;
    } 
    else {
      if (str.value == 0) {
        return str.value = null;
      }
      if (Array.isArray(str.value)) {
        if (key == 'exclude_firm') {
          return key + ': ' + str.value.join(' and ');
        }
        return key + ': ' + str.value.join(' or ');
      }
      return key + ': ' + str.value;
    }

    if (key && label) {
      if (Array.isArray(str.value)) {
        return key + ': ' + label.join(' or ');
      }
      return key + ': ' +  label;
    }
  }

  filterTag() {
    this.search_filters = [];

    let excludes  = ['crd_autocomplete', 'file_name', 'save_search_id', 'search_option', 'search_type', 'quick_search_crd_name', 'quick_full_search', 'select_title_type', 'select_carrier_type', 'select_company_type', 'shared_type', 'my_advizor_option', 'firm_my_advizor_option', 'custodian_option', 'firm_name_search', 'licenses_filter_switch', 'designation_filter_switch', 'exclude_firms', 'exclude_persons', 'firm_contains_includes', 'people_with_filter_switch', 'ins_line_filter_switch', 'ins_register_filter_switch', 'search_update', 'bulk_filter_option', 'plan_my_plans_option', 'plan_plans_with_option'];

    for (const key in this.search) {
      if (!excludes.includes(key) && this.search[key]) {
        if (Array.isArray(this.search[key]) && this.search[key].length == 0 ) {
          continue;
        }
        let tag = this.replace({key: key, value: this.search[key]});
        if (tag) {
          if (key == 'type') {
            tag = tag.replace('Type','Dataset');
          }
          this.search_filters.push(tag);
        }
      }
    }
  }

  changeTab(event) {
    this.exportType = event;
    setTimeout(() => {
      if(window.innerWidth > 1025){
        window.dispatchEvent(new Event('resize'));
      }
    }, 300);
  }

  uploadCrd() {
    this.updFile.id = this.user.id;
    this.modalService.openModal(this.searchVariables.upload_crd)
  }

  updCrdOk() {
    this.modalService.closeModal(this.searchVariables.upload_crd)
  }

  uploadList() {
    this.isUpdList = true;
    this.disclaimer_check = false;
    this.modalService.closeModal(this.searchVariables.upload_crd)
  }

  updListCancel() {
    this.isUpdList = false;
    this.mapped_records = 0;
  }

  updListOk() {
    this.isUpdList = false;
  }

  listCancel() {
    this.isList = false;
    this.listMatching.unsubscribe();
  }

  listOk() {
    this.isList = false;
    this.listMatching.unsubscribe();
  }

  mapContactOpen(event) {
    this.mapCrd = event;
    this.mapContactModal = true;
    this.loading = true;
    this.contacts = {} as FirmContactResponse;
    this.getFirmContact();
  }

  mapContactCancel() {
    this.mapContactModal = false;
  }

  mapContactOk() {
    this.mapContactModal = false;
  }

  getFirmContact() {
    let searchContact = {
      limit: this.paginateContact.limit,
      page: this.paginateContact.pageNumber + 1,
      crd: this.mapCrd,
      search: ''
    };
    return this.advizorProService.firmContacts(searchContact).subscribe(
      (data: { data: FirmContactResponse }) => {
        if (data) {
          this.contacts = data.data;
        }
      },
    );
  }

  sortData($event: { offset: number }) {
    this.paginateContact.pageNumber = $event.offset;
    this.getFirmContact();
  }

  ngAfterViewInit() {
    $(document).on('click', '.tags-control .pg-close', () => {
      this.modelChanged();
    });
  }

  private loadScript(url) {
    return new Promise((resolve, reject) => {
      const script = this.renderer2.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.text = "";
      script.async = true;
      script.defer = true;
      script.onload = resolve;
      script.onerror = reject;
      this.renderer2.appendChild(this.document.body, script);
    })
  }

  handleClose(str: any): void {
    if (str.key == 'zip_code') {
      this.zip_code = [];
    } else if (str.key == 'person_zip_code') {
      this.person_zip_code = [];
    } else if (str.key == 'team_zip_code') {
      this.team_zip_code = [];
    } else if (str.key == 'text_title_contain') {
      this.text_title_contain = [];
    } else if (str.key == 'text_company_contain') {
      this.text_company_contain = [];
    } else if (str.key == 'text_carrier_contain') {
      this.text_carrier_contain = [];
    } else if (str.key == 'county') {
      this.county = '';
      this.select_county = [];
    } else if (str.key == 'person_county') {
      this.person_county = '';
      this.select_person_county = [];
    } else if (str.key == 'custodian') {
      this.custodian = '';
      this.select_custodian = [];
      this.clearAssetsHeldByCustodian();
    } else if (str.key == 'ticker') {
      this.ticker = '';
      this.select_ticker = [];
    } else if (str.key == 'affiliation') {
      this.affiliation = '';
      this.select_affiliation = [];
    } else if (str.key == 'title_contain') {
      this.title_contain = '';
      this.select_title_contain = [];
    // } else if (str.key == 'person_metro_area') {
    //   this.person_metro_area = '';
    //   this.select_person_metro_area = [];
    } else if (str.key == 'quick_search') {
      this.quick_search = '';
      this.select_company = [];

      if (this.search) {
        if (this.search.quick_full_search) {
          delete this.search['quick_full_search'];
        }

        if (this.search.crd_autocomplete) {
          delete this.search['crd_autocomplete'];
        }
      }

    } else if (str.key == 'known_non_advisor') {
      this.search['known_non_advisor'] = [];
    } else if (str.key == 'remove_known_non_advisor_individual') {
      this.search['remove_known_non_advisor_individual'] = [];
    } else if (str.key == 'remove_known_non_producing_individual') {
      this.search['remove_known_non_producing_individual'] = false;
    } else if (str.key == 'remove_captive') {
      this.search['remove_captive'] = false;
    } else if(str.key == 'previous_firm'){
      this.previous_firm = '';
      this.select_previous_firm = [];
    } else if (str.key == 'firm_name') {
      this.firm_name = '';
      this.select_firm_name = [];
      this.firm_checkbox = [];
    } else if (str.key == 'insurance_company') {
      str.value.split(' or ').forEach((item: string) => {
        this.removeInsCompany(item);
      });
    } else if (str.key == 'person_metro_area') {
      str.value.forEach((item: string) => {
        this.removePersonMetroArea(item);
      });
    } else if (str.key == 'metro_area') {
      str.value.forEach((item: string) => {
        this.removeMetroArea(item);
      });
    } else if (str.key == 'team_metro_area') {
      str.value.forEach((item: string) => {
        this.removeTeamMetroArea(item);
      });
    } else if (str.key === 'lead_score') {
      this.lead_score = [];
    }

    if (str.key == 'firm_keyword_category') {
      this.search['firm_keyword_category'] = [];
      this.search['firm_keywords'] = [];
    }

    if (str.key == 'person_keyword_category') {
      this.search['person_keyword_category'] = [];
      this.search['person_keywords'] = [];
    }
    
    if (str.key == this.searchVariables.upload_crd) {
      delete this.search[this.searchVariables.upload_crd];
    }

    if (str.key == 'date_diff'){
      delete this.search['date_diff'];
      this.dateDiff = null;
    }

    if (str.key == 'exclude_firm'){
      this.search['exclude_firm'] = [];
    }

    if (str.key == 'gender') {
      this.gender_female = false;
      this.gender_male = false;
      this.search['gender'] = [];
    }

    if (str.key == 'people_with') {
      this.people_with = []
    }

    if (this.search?.[str.key]) {
      delete this.search[str.key];
    }

    if (str.key === 'bt_trust_services')
      this.trustServices = false

    if (str.key === 'bt_trust_teams')
      this.trustTeams = false

    if (str.key === 'allocate_external_managers')
      this.allocateExternalManagers = false

    if (str.key === this.searchVariables.team_member)
      this.search.team_member = false

    this.makeSearch();
  }

  limitOverModelCancel() {
    this.limit_over_model = false;
  }

   exportModel() {
    let excel_count = this.exportExcelCount;

    if (!this.user.ap_user_limit_csv || (this.user.ap_user_limit_csv && this.user.ap_user_limit_csv <= excel_count)) {
      this.limit_over_model = true;
      return false;
    }

    this.export_person_cols = [
      { id: 'column1', value: 'CRD', checked: true },
      { id: 'column2', value: 'NPN', checked: true },
      { id: 'column3', value: 'First Name', checked: true },
      { id: 'column4', value: 'Middle Name', checked: true },
      { id: 'column5', value: 'Last Name', checked: true },
      { id: 'column6', value: 'Other Names', checked: true },
      { id: 'column7', value: 'Broker-Dealer', checked: true },
      { id: 'column8', value: 'Broker-Dealer CRD', checked: true },
      { id: 'column9', value: 'Years with Current BD', checked: true },
      { id: 'column10', value: 'Current BD Start Date', checked: true },
      { id: 'column11', value: 'RIA', checked: true },
      { id: 'column12', value: 'RIA CRD', checked: true },
      { id: 'column13', value: 'Team', checked: true },
      { id: 'column14', value: 'Team Address', checked: true },
      { id: 'column15', value: 'Team Phone', checked: true },
      { id: 'column98', value: 'Team Website', checked: true },
      { id: 'column16', value: 'Years with Current RIA', checked: true },
      { id: 'column17', value: 'Current RIA State Date', checked: true },
      { id: 'column18', value: 'Address', checked: true },
      { id: 'column19', value: 'City', checked: true },
      { id: 'column20', value: 'State', checked: true },
      { id: 'column21', value: 'Zip', checked: true },
      { id: 'column22', value: 'Metro Area', checked: true },
      { id: 'column23', value: 'Licenses & Exams', checked: true },
      { id: 'column24', value: 'Title', checked: true },
      { id: 'column25', value: 'Designations', checked: true },
      { id: 'column26', value: 'Phone', checked: true },
      { id: 'column27', value: 'Phone - Type', checked: true },
      { id: 'column28', value: 'LinkedIn', checked: true },
      { id: 'column29', value: 'Email 1', checked: true },
      { id: 'column30', value: 'Email 2', checked: true },
      { id: 'column31', value: 'Email 3', checked: true },
      { id: 'column32', value: 'Personal Email', checked: true },
      { id: 'column33', value: 'Bio', checked: true },
      { id: 'column34', value: 'Years of Experience', checked: true },
      { id: 'column35', value: 'Est. Age', checked: true },
      { id: 'column36', value: 'Previous Broker Dealer', checked: true },
      { id: 'column37', value: 'Previous RIA', checked: true },
      { id: 'column38', value: 'Gender', checked: true },
      { id: 'column39', value: 'Team ID', checked: true },
      { id: 'column40', value: 'Firm Ownership', checked: true },
      { id: 'column41', value: 'Person Tag - Role', checked: true },
      { id: 'column42', value: 'Person Tag - Family', checked: true },
      { id: 'column43', value: 'Person Tag - Hobbies', checked: true },
      { id: 'column44', value: 'Person Tag - Expertise', checked: true },
      { id: 'column45', value: 'Person Tag - Services', checked: true },
      { id: 'column46', value: 'Person Tag - Investments', checked: true },
      { id: 'column47', value: 'Person Tag - Sports Teams', checked: true },
      { id: 'column48', value: 'Person Tag - School', checked: true },
      { id: 'column49', value: 'Person Tag - Greek Life', checked: true },
      { id: 'column50', value: 'Person Tag - Military Status', checked: true },
      { id: 'column51', value: 'Person Tag - Faith Based Investing', checked: true },
      { id: 'column52', value: 'Firm Tag - Platform', checked: true },
      { id: 'column53', value: 'Firm Tag - Technology', checked: true },
      { id: 'column54', value: 'Firm Tag - Custodian', checked: true },
      { id: 'column55', value: 'Firm Tag - Services', checked: true },
      { id: 'column56', value: 'Firm Tag - Client Personas', checked: true },
      { id: 'column57', value: 'Firm Tag - Asset Classes', checked: true },
      { id: 'column58', value: 'Firm Tag - Investment Themes', checked: true },
      { id: 'column59', value: 'Firm Tag - Investment Vehicles', checked: true },
      { id: 'column60', value: 'Firm Tag - Fund Managers', checked: true },
      { id: 'column61', value: 'Firm Tag - Accredited Investors', checked: true },
      { id: 'column62', value: 'Firm Tag - CRM', checked: true },
      { id: 'column63', value: 'Notes', checked: true },
      { id: 'column64', value: 'Profile', checked: true },
      { id: 'column65', value: 'SEC Link', checked: true },
      { id: 'column66', value: 'FINRA Link', checked: true },
      { id: 'column67', value: 'Firm Company Name', checked: true },
      { id: 'column68', value: 'Firm Type', checked: true },
      { id: 'column69', value: 'Firm Website', checked: true },
      { id: 'column70', value: 'Firm Address', checked: true },
      { id: 'column71', value: 'Firm City', checked: true },
      { id: 'column72', value: 'Firm State', checked: true },
      { id: 'column73', value: 'Firm Zip', checked: true },
      { id: 'column74', value: 'Firm Phone', checked: true },
      { id: 'column75', value: 'Firm AUM', checked: true },
      { id: 'column76', value: 'Firm Total Accounts', checked: true },
      { id: 'column77', value: 'Firm Custodians', checked: true },
      { id: 'column78', value: 'Firm Total Employees', checked: true },
      { id: 'column79', value: 'Firm RIA Reps', checked: true },
      { id: 'column80', value: 'Firm BD Reps', checked: true },
      { id: 'column81', value: 'Firm Form 13F', checked: true },
      { id: 'column82', value: 'Lines', checked: true },
      { id: 'column83', value: 'Carrier', checked: true },
      { id: 'column84', value: 'Company', checked: true },
      { id: 'column85', value: 'Initial Appointment Date', checked: true },
      { id: 'column86', value: 'Captive', checked: true },
      { id: 'column87', value: 'Num of Carriers', checked: true },
      { id: 'column88', value: 'Num of Lines', checked: true },
      { id: 'column89', value: 'Non-Producing', checked: true },
      { id: 'column90', value: 'Insurance Years of Experience', checked: true },
      { id: 'column91', value: 'Family Office Asset Class', checked: true },
      { id: 'column92', value: 'Family Office Type', checked: true },
      { id: 'column93', value: 'Family Office BIO', checked: true },
      { id: 'column94', value: 'Family Office Years Founded', checked: true },
      { id: 'column95', value: 'Family Office Industry Focus', checked: true },
      { id: 'column96', value: 'Registration Type', checked: true },
      { id: 'column97', value: 'Non-Advisor', checked: true },
      { id: 'column99', value: 'Lead Score', checked: true, permision_key: 'lead_scoring'},
    ];

    this.export_firm_cols = [
      { id: 'column1', value: 'CRD', checked: true },
      { id: 'column2', value: 'Company Name', checked: true },
      { id: 'column3', value: 'Other Names', checked: true },
      { id: 'column4', value: 'Type', checked: true },
      { id: 'column5', value: 'Date Established', checked: true },
      { id: 'column6', value: 'Website', checked: true },
      { id: 'column7', value: 'Address', checked: true },
      { id: 'column8', value: 'City', checked: true },
      { id: 'column9', value: 'State', checked: true },
      { id: 'column10', value: 'Zip', checked: true },
      { id: 'column11', value: 'Metro Area', checked: true },
      { id: 'column12', value: 'Phone', checked: true },
      { id: 'column13', value: 'AUM', checked: true },
      { id: 'column14', value: 'Total Accounts', checked: true },
      { id: 'column15', value: 'Average Account Size', checked: true },
      { id: 'column16', value: 'AUM - Discretionary', checked: true },
      { id: 'column17', value: 'AUM - Non Discretionary', checked: true },
      { id: 'column18', value: 'Channel', checked: true },
      { id: 'column19', value: 'Advisory Clients', checked: true },
      { id: 'column20', value: 'Planning Clients', checked: true },
      { id: 'column21', value: 'Clients - Individuals', checked: true },
      { id: 'column22', value: 'Clients - High-Net-Worth', checked: true },
      { id: 'column23', value: 'Clients - Retirement Plans', checked: true },
      { id: 'column24', value: 'Clients - Charities', checked: true },
      { id: 'column25', value: 'Clients - Municipalities', checked: true },
      { id: 'column26', value: 'Clients - Corporations', checked: true },
      { id: 'column27', value: 'Clients - Other', checked: true },
      { id: 'column28', value: 'Assets - Individuals', checked: true },
      { id: 'column29', value: 'Assets - HNW', checked: true },
      { id: 'column30', value: 'Assets - Retirement Plans', checked: true },
      { id: 'column31', value: 'Assets - Charities', checked: true },
      { id: 'column32', value: 'Assets - Municipalities', checked: true },
      { id: 'column33', value: 'Assets - Corporations', checked: true },
      { id: 'column34', value: 'Assets - Other', checked: true },
      { id: 'column35', value: 'Assets - Investment Companies', checked: true },
      { id: 'column36', value: 'Assets - Sovereign Wealth & Foreign Institutions', checked: true },
      { id: 'column37', value: 'Assets - Business Development Companies', checked: true },
      { id: 'column38', value: 'Assets - Insurance Companies', checked: true },
      { id: 'column39', value: 'Assets - Other Pooled Vehicles', checked: true },
      { id: 'column40', value: 'Assets - Other Investment Advisers', checked: true },
      { id: 'column41', value: 'Custodians', checked: true },
      { id: 'column42', value: 'SMA - Public Funds', checked: true },
      { id: 'column43', value: 'SMA - Equity - Exchange', checked: true },
      { id: 'column44', value: 'SMA - Cash', checked: true },
      { id: 'column45', value: 'SMA - Municipal Bonds', checked: true },
      { id: 'column46', value: 'SMA - Corporate - Investment Grade', checked: true },
      { id: 'column47', value: 'SMA - Private Funds', checked: true },
      { id: 'column48', value: 'SMA - Equity - OTC', checked: true },
      { id: 'column49', value: 'SMA - U.S. Government/Agency', checked: true },
      { id: 'column50', value: 'SMA - Sovereign Bonds', checked: true },
      { id: 'column51', value: 'SMA - Corporate - Non-Investment Grade', checked: true },
      { id: 'column52', value: 'SMA - Derivatives', checked: true },
      { id: 'column53', value: 'SMA - Other', checked: true },
      { id: 'column54', value: 'Total Employees', checked: true },
      { id: 'column55', value: 'RIA Reps', checked: true },
      { id: 'column56', value: 'BD Reps', checked: true },
      { id: 'column57', value: 'Form 13F', checked: true },
      { id: 'column58', value: 'Firm Tag - Platform', checked: true },
      { id: 'column59', value: 'Firm Tag - Technology', checked: true },
      { id: 'column60', value: 'Firm Tag - Custodian', checked: true },
      { id: 'column61', value: 'Firm Tag - Services', checked: true },
      { id: 'column62', value: 'Firm Tag - Client Personas', checked: true },
      { id: 'column63', value: 'Firm Tag - Asset Classes', checked: true },
      { id: 'column64', value: 'Firm Tag - Investment Themes', checked: true },
      { id: 'column65', value: 'Firm Tag - Investment Vehicles', checked: true },
      { id: 'column66', value: 'Firm Tag - Fund Managers', checked: true },
      { id: 'column67', value: 'Firm Tag - Accredited Investors', checked: true },
      { id: 'column68', value: 'Firm Tag - CRM', checked: true },
      { id: 'column69', value: 'Notes', checked: true },
      { id: 'column70', value: 'Profile', checked: true },
      { id: 'column71', value: 'Historical AUM', checked: false },
      { id: 'column72', value: 'Family Office Asset Class', checked: true },
      { id: 'column73', value: 'Family Office Type', checked: true },
      { id: 'column74', value: 'Family Office AUM', checked: true },
      { id: 'column75', value: 'Family Office Wealth Creator', checked: true },
      { id: 'column76', value: 'Family Office Firm Description', checked: true },
      { id: 'column77', value: 'Family Office Year Founded', checked: true },
      { id: 'column78', value: 'Family Office Wealth Origin', checked: true },
      { id: 'column79', value: 'Family Office General Email', checked: true },
      { id: 'column80', value: 'Family Office Direct Investments', checked: true },
      { id: 'column81', value: 'Family Office External Managers', checked: true },
      { id: 'column82', value: 'Family Office - Investment Criteria - EBITDA', checked: true },
      { id: 'column83', value: 'Family Office - Investment Criteria - Revenue Min', checked: true },
      { id: 'column84', value: 'Family Office - Investment Criteria - Revenue Max', checked: true },
      { id: 'column85', value: 'Family Office - Investment Criteria - Revenue Text', checked: true },
      { id: 'column86', value: 'Family Office - Investment Criteria - Link', checked: true },
      { id: 'column87', value: "Bank Trust - Total Fiduciary and Related Assets", checked: true},
      { id: 'column88', value: "Bank Trust - Trust Assets", checked: true},
      { id: 'column89', value: "Bank Trust - Total Assets", checked: true},
      { id: 'column90', value: "Affilitations", checked: true},
      { id: 'column91', value: "Total AdvizorPro Associated Contacts", checked: true},
      { id: 'column92', value: "Total RIA Employees", checked: true},
      { id: 'column93', value: "Total BD Employees", checked: true},
      { id: 'column94', value: "Total Trust Employees", checked: true},
    ];

    this.export_person_cols = this.export_person_cols.filter((item) => {
      if (!this.user.has_access_insurance && ['NPN', 'Lines', 'Carrier', 'Company', 'Initial Appointment Date', 'Captive', 'Num of Carriers', 'Num of Lines', 'Non-Producing', 'Insurance Years of Experience'].includes(item.value)) {
      } else if (this.onlyInsuranceAccess && ['Firm Company Name','Firm Type','Firm Website','Firm Address','Firm City','Firm State','Firm Zip','Firm Phone','Firm AUM','Firm Total Accounts','Firm Custodians','Firm Total Employees','Firm RIA Reps','Firm BD Reps','Firm Form 13F','Firm Tags','Broker-Dealer','Broker-Dealer CRD','Years with Current BD','RIA','RIA CRD','Years with Current RIA','Previous Broker Dealer','Previous RIA','Date of Latest Firm Change'].includes(item.value)) {
      } else if (!this.onlyTeamAccess && ['Team', 'Team Address', 'Team Phone', 'Team Website'].includes(item.value)) {
      } else {
        if (!this.user.has_access_family_office && item.value.startsWith("Family Office")) {
        } else {
          return item;
        }
      }
    });

    this.export_firm_cols = this.export_firm_cols.filter((item) => {
      if (!this.user.has_access_family_office && item.value.startsWith("Family Office")) {
      } else {
        return item;
      }
    });

    if (this.exclude_firm_checkbox) {
      this.export_firm_cols.forEach(item => {
        let index = this.exclude_firm_checkbox.indexOf(item.value);
        if (index) {
          item.checked = true;
        }

        if (index != -1) {
          item.checked = false;
        }
      });
    }

    if (this.exclude_person_checkbox) {
      this.export_person_cols.forEach(item => {
        let index = this.exclude_person_checkbox.indexOf(item.value);
        if (index != -1) {
          item.checked = false;
        }
      });
    }

    this.excel_link = '';
    this.isExportModal = true;
    this.omit.check = false;

    this.filterTag();

    if (this.selectedSaved) {
      let find_save_search = this.saveOfGroupOption.find(item => item.value === this.selectedSaved);
      if (find_save_search?.label) {
        this.exportOptionalName = find_save_search.label;
      }
    }
  }

  checkboxFirmExportCheckBox($event) {
    if (this.exclude_firm_checkbox) {
      let index = this.exclude_firm_checkbox.indexOf($event.target.value);
      if ($event.target.checked) {
        if (index !== -1) {
          this.exclude_firm_checkbox.splice(index, 1);
        }
      } else {
        if (index == -1) {
          this.exclude_firm_checkbox.push($event.target.value);
        }
      }
    }

    this.advizorProService.saveExportCheckBox({ 'name': $event.target.value, 'value': $event.target.checked, type: 'firm' }).subscribe(() => {
    });
  }

  checkboxPersonExportCheckBox($event) {
    if (this.exclude_person_checkbox) {
      let index = this.exclude_person_checkbox.indexOf($event.target.value);
      if ($event.target.checked) {
        if (index !== -1) {
          this.exclude_person_checkbox.splice(index, 1);
        }
      } else {
        if (index == -1) {
          this.exclude_person_checkbox.push($event.target.value);
        }
      }
    }

    this.advizorProService.saveExportCheckBox({ 'name': $event.target.value, 'value': $event.target.checked, type: 'person' }).subscribe(() => {
    });
  }

  sendEmail() {
    if(this.recipient_data.count > 0){
      window.scroll(0,0);
      this.email_automation_modal = true;
    }else{
      this._helper.errorMessage('', 'Please select person first.');
    }
  }

  searchTicker(event){
    let dataList = this.search_result?.person?.data;
    this.defCheck = event;

    if (this.defCheck) {
      this.check_all = true;
      dataList.forEach((val: { crd: string; id: number; email: string; checked: boolean }) => {
        if (val.email) {
          if((this.recipient_data.recipients.length) < 100) {
            val.checked = event;
            const index: number = this.recipient_data.recipients.indexOf(val.crd);
            if (index === -1) {
              this.recipient_data.recipients.push(val.crd);
              this.recipient_data.count = this.recipient_data.recipients.length;
            }
          }
        }

        if ((this.bulk_data.length) < 5000) {
          val.checked = event;
          const index: number = this.bulk_data.indexOf(val.id);
          if (index === -1) {
            this.bulk_data.push(val.id);
          }

          const index_id: number = this.bulk_ids.findIndex(item => item.id == val.id);

          if (index_id === -1) {
            this.bulk_ids.push(val.id);
          }
        }

        const index_person: number = this.selected_person.indexOf(val.id);
        if (index_person === -1) {
          this.selected_person.push(val.id);
        }
      });
    } else {
      this.check_all = false;
      dataList.forEach((val: { crd: string; id: number; checked: boolean }) => {
        val.checked = event;
        const index: number = this.recipient_data.recipients.indexOf(val.crd);
        if (index !== -1) {
          this.recipient_data.recipients.splice(index, 1);
          this.recipient_data.count = this.recipient_data.recipients.length;
        }

        const index1: number = this.bulk_data.indexOf(val.id);
        if (index1 !== -1) {
          this.bulk_data.splice(index1, 1);
        }

        const index_id: number = this.bulk_ids.findIndex(item => item.id == val.id);
        if (index_id !== -1) {
          this.bulk_ids.splice(index_id, 1);
        }

        const index_person: number = this.selected_person.indexOf(val.id);
        if (index_person !== -1) {
          this.selected_person.splice(index_person, 1);
        }
      });
    }
  }

  tickerChange(crd, email, event, id){
    this.check_all = false;
    let dataList = this.search_result?.person?.data;
    if (email) {
      if (event) {
        if ((this.recipient_data.recipients.length) < 100) {
          const index: number = this.recipient_data.recipients.indexOf(crd);
          if (index === -1) {
            this.recipient_data.recipients.push(crd);
            this.recipient_data.count = this.recipient_data.recipients.length;
          }
        }
      } else {
        const index: number = this.recipient_data.recipients.indexOf(crd);
        if (index !== -1) {
          this.recipient_data.recipients.splice(index, 1);
          this.recipient_data.count = this.recipient_data.recipients.length;
        }
      }
    }
    dataList.forEach((val:any) => {
      if(val.id == id){
        val.checked = event;
      }
    });
    if (event) {
      if ((this.bulk_data.length) < 5000) {
        const index: number = this.bulk_data.indexOf(id);
        if (index === -1) {
          this.bulk_data.push(id);
        }


        const index_id: number = this.bulk_ids.findIndex(item => item.id == id);

        if (index_id === -1) {
          this.bulk_ids.push(id);
        }
      }

      const index_person: number = this.selected_person.indexOf(id);
      if (index_person === -1) {
        this.selected_person.push(id);
      }
    } else {
      const index_person: number = this.selected_person.indexOf(id);
      if (index_person !== -1) {
        this.selected_person.splice(index_person, 1);
      }

      const index: number = this.bulk_data.indexOf(id);
      if (index !== -1) {
        this.bulk_data.splice(index, 1);
      }

      this.defCheck = false;
    }
  }

  exportModalOk(): void {
    this.isExportModal = false;
  }

  exportModalCancel(): void {
    this.isExportModal = false;
  }

  antWaitingBoxCancel(): void {
    this.isAntWaitingBox = false;
  }

  antWaitingBoxOk(): void {
    this.isAntWaitingBox = false;
  }
  showSuccessModal(){
     this.email_test_modal = true;
  }

  emailTestContentCancel() {
    this.email_test_modal = false;
  }

  emailContentCancel() {
    this.email_automation_modal = false;
  }

  bulkFollow() {
    if (this.bulk_data.length > 0) {
      this.bulk_follow = true;
    } else {
      this._helper.errorMessage('', 'Please select person first.');
    }
  }

  emailQuality() {
    this.email_quality = true;
  }

  emailQualityCancel() {
    this.email_quality = false;
  }

  bulkFollowCancel() {
    this.bulk_follow = false;
  }

  saveBulkStatus() {

    let expArgs = {};

    if (this.check_all) {
      expArgs = {
        is_follow: 1,
        searchData: Object.assign({}, this.search),
        follow_status:this.follow_status,
        follow_type:this.user_follow_type,
        sortField: this.paginate.sortField,
        sortOrder: this.paginate.sortOrder
      }
    } else {
      expArgs = {
        searchData: {},
        ids: this.bulk_data,
        is_follow: 1,
        follow_status: this.follow_status,
        follow_type:this.user_follow_type,
        sortField: this.paginate.sortField,
        sortOrder: this.paginate.sortOrder
      }
    }

    this.advizorProService.searchPerson(expArgs).subscribe(() => {
      this.bulk_follow = false;
      this._helper.successMessage('', 'Successfully followed.');
    });
  }

  saveEmailQualit() {

  }

  getExportCreditsCount() {
    return this.advizorProService.getExportCreditsCount().subscribe(
      (data: any) => {
        this.exportCreditCount = data.data.ap_credits;
        this.exportExcelCount = data.data.ap_excel_exports;
      },
    );
  }

  updateSaveFilter(save_id: number, fromLoad = false) {
    if (save_id) {
      this.updateSaveFilterStatus = false;
      this.advizorProService.savedSearch(save_id).subscribe(
        (data: SavedSearchResponse) => {
          if (data) {
            const search: SearchData = JSON.parse(data.search_data);
            search.exclude_firms = data.exclude_firms;
            search.exclude_persons = data.exclude_persons;
            search.search_update = data.search_update;
            search.save_search_id = save_id;
            search.search_name = data.search_name;

            this.isAdvizorProSaved = false;
            if (data.shared_type == 2) {
              this.isAdvizorProSaved = true;
            }

            search['shared_type'] = '0';

            this.saved_search_param = Object.assign({}, {});

            if (!fromLoad){
              this.dateDiff = null;
              delete this.search['date_diff'];
            }

            this._helper.setCookie('advizorSearchData', search);
            this.initLoad();
          }
        }
      );
    } else {
      this.resetForm();
    }
  }

  tabSelectChange($event){
    if ($event.tab.nzTitle == "Settings") {
      this.is_export_tab = true;
    } else {
      this.is_export_tab = false;
    }
  }

  handleEvent(e) {
    if (e.which === 13) {
      this.searchRefresh('search');
      /*
      this.advizorProService.getCrossSearch(this.search).subscribe((response:any) => {
        if (response.data) {
          this.search.internal_firm_crd = response.data.internal_firm_crd;
          this.search.internal_person_crd = response.data.internal_person_crd;
          this.searchRefresh('search');
        }
      });
      */
    }
  }

  handleKeyEvent(e) {
    if (e.which === 13) {
      this.modelChanged();
    }
  }

  modelChanged() {
    this.hasNonAdvisorAccess = false;

    const hasPeopleRole = Array.isArray(this.search.people_role) && this.search.people_role.length > 0;
    const hasBankAndTrust = this.search.type && this.search.type.length > 0 && this.search.type.includes('Bank & Trust');
    const hasFamilyOffice = this.search.type && this.search.type.length > 0 && this.search.type.includes('Family Office');

    if (hasPeopleRole || hasBankAndTrust || hasFamilyOffice) {
      this.hasNonAdvisorAccess = true;
      this.search.known_non_advisor = [];
      this.search.remove_known_non_advisor_individual = [];

      if (hasFamilyOffice || hasBankAndTrust) {
        this.search.remove_known_non_producing_individual = false;
      }
    }
  
    this.makeSearch();
  }

  firmModelChanged() {
    this.search.firm_name_search = this.firm_name_search;
    if (this.search.firm_name) {
      this.modelChanged();
    }
  }

  keyUpFirmName(data) {
    const fname = data.target.value;
    this.firm_name = '';
    if (fname) {
      const index: number = this.select_firm_name.indexOf(fname);
      if (index == -1) {
        this.select_firm_name.push(fname);
        this.search.firm_name = this.select_firm_name;
        this.modelChanged();
      }
    }
  }

  crossSearch(search){
    if (this.bulkSearchValue == 'ticker') {
      this.select_ticker = this.search.upload_crd ? this.search.upload_crd.trim().split(/[,\s]+/) : [];
      this.search.ticker = this.select_ticker;
      this.bulkSearchValue = 'rep_crd';
      delete this.search.upload_crd;
    }

    if (this.search.bulk_ticker) {
      this.select_ticker = this.search.bulk_ticker.trim().split(/[,\s]+/);
      this.search.ticker = this.select_ticker;
      delete this.search.bulk_ticker;
    }

    return new Promise<any>((resolve) => {

      this.advizorProService.getCrossSearch(search).
        subscribe((response: any) => {
          resolve(response.data);
        }, () => {
          });
    });
  }

  crossSearchStatus(hash){
    return new Promise<boolean>((resolve) => {

      this.advizorProService.getCrossSearchStatus(hash).
        subscribe((response: any) => {
          resolve(response.data);
        }, () => {
          });
    });
  }

  filterSetting() {
    this.setting_modal = true;
  }

  settingContentCancel() {
    this.setting_modal = false;
  }

  filterSavedSearch() {
    this.date_saved_search = true;

    this.savedSearchDiff({searchId: this.selectedSaved });
  }

  savedContentCancel() {
    this.date_saved_search = false;
  }

  runSavedSearcQuery(searchId, date=null) {
    this.savedSearchDiff({searchId: searchId, date:date });
    this.date_saved_search = false;
    this.dateDiff = date;
    this.initLoad();
  }

  updateUserSearch() {
    this.user_setting.exclude_firm = this.exclude_firm_name;

    let search_merge = {...this.search, ...this.user_setting};
    this.search = JSON.parse(JSON.stringify(search_merge));

    if (this.user_setting.known_non_advisor) {
      this.search.known_non_advisor = [1];
    } else {
      this.search.known_non_advisor = [];
    }

    if (this.user_setting.remove_known_non_advisor_individual) {
      this.search.remove_known_non_advisor_individual = [1];
    } else {
      this.search.remove_known_non_advisor_individual = [];
    }

    this.userService.saveUserSearchSetting(this.user_setting).
    subscribe(() => {
        this.email_quality = false;
        this.setting_modal = false;
        this.makeSearch();
      }, () => {
        this.email_quality = false;
        this.setting_modal = false;
      });
  }

  uploadFileChange = (file: NzUploadFile): boolean => {
    this.fileList = [];

    if (file.name && !this.searchFileName) {
      this.searchFileName = file.name.split('.')[0];
    }

    this.fileList = this.fileList.concat(file);

    return false;
  };

  uploadFile() {
    if (this.fileList.length == 0) {
      this.invalidUploadField.push("upload_file");
      return false;
    }

    this.invalidUploadField = [];

    const formData = new FormData();

    formData.append('id', this.user.id);
    formData.append('search_name', this.searchFileName);
    formData.append('create_saved_search', this.create_saved_search.toString());

    this.fileList.forEach((file: NzUploadFile) => {
      this.fileObj = file;
    });

    this.uploading = true;

    this.count = 0;

    this.advizorProService.getPresignedurl(this.fileObj.name).subscribe((response: any) => {
      if (response.url) {

        const imageForm = new FormData();
        imageForm.append('file', this.fileObj);
        this.uploading = false;

        this.advizorProService.uploadfileAWSS3(response.url, this.fileObj.type, this.fileObj).subscribe((event) => {
          let res = JSON.parse(JSON.stringify(event));
          if (res.status == 200) {
            formData.append('name', response.name);
            formData.append('key', response.key);
            formData.append('guid', response.guid);

            this.advizorProService.uploadXlsFile(formData).subscribe({
              next: (response: any) => {
                this.uploadedList = response.data;

                this.isUpdList = false;
                this.isList = true;
                this.uploading = false;
                this.fileList = [];
                this.searchFileName = '';

                this.listMatching = interval(2000)
                  .subscribe(() => {
                      this.checkUploadList(this.uploadedList.id);
                    });
                  return false;
              },
              error: () => {
                this.uploading = false;
                this._helper.errorMessage('', 'upload failed.');
              }
            });
          }
        });
      }
    });
  }

  checkUploadList(id: number): void {
    this.advizorProService.getUploadMatchList(id).subscribe((data: { status: string; saved_search_id?: number }) => {
      this.uploadedList = data;

      if (data.status === 'complete' || data.status === 'completed') {
        this.listMatching.unsubscribe();
        // Enable button to run search
        this.uploadedList.saved_search_id = data.saved_search_id;
      } else if (data.status === 'error') {
        this.listMatching.unsubscribe();
      }
    });
  }

  exclude_firm_name_autocomplete = (text) => {
    let firms = this.planService.getFirmName({
      keyword: text.toLowerCase(),
      limit: 30
    })

    if (this.user_setting.exclude_firm && this.exclude_firm_name.length > 0) {
      this.user_setting.exclude_firm = this.exclude_firm_name;
      this.user_setting.exclude_firm = this.user_setting.exclude_firm.concat([this.exclude_firm]);
    } else {
      this.user_setting.exclude_firm = [this.exclude_firm];
    }

    return firms;
  }

  removeExcludeFirmName(item) {
    const index: number = this.exclude_firm_name.indexOf(item);
    if (index > -1) {
      this.exclude_firm_name.splice(index, 1);
    }
  }

  changeExcludeFirmName() {
    setTimeout(() => {
      if (this.exclude_firm_flag) {
        this.search.exclude_firm = this.user_setting.exclude_firm;
      }
      this.exclude_firm_flag = false;
    }, 500);
  }

  selectExcludeFirm(data) {
    this.exclude_firm_flag = true;
    const index: number = this.exclude_firm_name.indexOf(data.value);
    if (index == -1) {
      this.exclude_firm_name.push(data.value);
      this.user_setting.exclude_firm = this.exclude_firm_name;
      this.firm_exclude_checkbox = [];
      this.firm_exclude_checkbox = this.exclude_firm_name;
    }
  }

  ngOnDestroy(): void {
  }

  openDbaContact(data: FirmTeam, advisorTeam: AdvisorTeam[]) {
          if (!data?.id || !advisorTeam?.length) return;

          const getTeam: AdvisorTeam = advisorTeam.find((value: AdvisorTeam) => value.dba_id === data.id);

          if (!getTeam) return;

          const dbaData: Dba = {
                  ...getTeam,
                  id: getTeam.dba_id,
          }

          this.dbaData = dbaData;
          this.modalServiceShared.openModal(this.dbaContactId);
  }

  disclosureContentModal() {
    this.disclosure_content = true;
  }

  disclosureContentCancel() {
    this.disclosure_content = false;
  }

  getCount(cnt) {
    if (cnt) {
      this.count += cnt
    }
    return this.count;
  }

  checkAccess() {
    // return this.tooltipList.hasOwnProperty(key);
  }

  firmContainsSelection() {
    this.selected_firm_contains = [];
    for (const [key, value] of Object.entries(this.search.firm_contains_includes)) {
      if (value == true) {
        this.selected_firm_contains.push(key);
      }
    }
  }

  firmContainChange() {
    for (const [key] of Object.entries(this.search.firm_contains_includes)) {
      if (this.selected_firm_contains.includes(key)) {
        this.search.firm_contains_includes[key] = true;
      } else {
        this.search.firm_contains_includes[key] = false;
      }
    }
    this.modelChanged();
  }

  saveSearchedCancel() {
    this.saveSearched = false;
  }

  savedClick() {
    this.saveSearched = true;
  }

  panelClick(index: number, level: string): void {
    const category = (level === 'contact') ? 'Contacts' : 'Companies';
    setTimeout(() => {
      this.panels[category][index][0]['shouldOpenPanel'] = !this.panels[category][index][0]['shouldOpenPanel'];
    }, 200);
  }

  processPanelData(category: string, data: Panels): void {
    if (data[category] !== undefined) {
      data[category].forEach((item, key) => {
        const panel = data[category][key][0];
        
        panel.shouldOpenPanel = false;
        panel.indexOfPanelToOpen = key;
        panel.filterCount = this.countFields(panel.category,panel.level);

        item.forEach((item1, key1) => {
          data[category][key][key1].name = item1.field_id.replace("person_", "").replace(/_/g, " ");
        });
      });
    }
  }

  genderChanged() {
    var gender = [];

    if (this.gender_male) {
      gender.push('male');
    }

    if (this.gender_female) {
      gender.push('female');
    }

    this.search.gender = gender;
    this.modelChanged();
  }

  containChanged(value): void {
    this.contain_delay.next(value);
  }

  pageSearchData($event: { offset: number }) {
    this.paginateSaved.pageNumber = $event.offset;
    this.getSaveSearch();
  }

  getSaveSearch(): void {
    let params = {
      per_page: this.paginateSaved.limit,
      current_page: this.paginateSaved.pageNumber + 1,
    }
		this.advizorProService.saveSearchNew(params, 0).subscribe((response: SaveSearchNewRespone) => {
           if(response?.data) this.search_data = response.data;
    });
  }

  deleteSearchRecord(searchid) {
		if (confirm('Are you sure want to delete')) {
			this.profileService.deleteSaveSearch(searchid).subscribe(
				(data: { status: string }) => {
					if (data.status == 'ok') {
						this._helper.successMessage('', 'Search deleted sucessfully');
            this.getSaveSearch();
					}
				},
			);
		}
  }

  searchSaveFilter(id: number): void {
		let params = {
			per_page: 10,
			current_page: 1
		};

		this.advizorProService.saveSearchNew(params, id).subscribe(
		(data: any) => {
			if (data) {
        const search: SearchData = JSON.parse(data.search_data);
				search.save_search_id = id;
				search.exclude_firms = data.exclude_firms;
        search.exclude_persons = data.exclude_persons;
        this.saveSearched = false;

        this.search  = search;

        this._helper.setCookie('advizorSearchData', this.search);

        setTimeout(() => {
          this.modelChanged();
        }, 300);
			}
		});
  }


  filterCount() {
    let search = this.search;
    let count = 0;

    if (search) {
      const excludedKeys = [
        'crd_autocomplete', 'file_name', 'save_search_id', 'search_option',
        'search_type', 'quick_search_crd_name', 'quick_full_search','person_keyword_category',
        'select_title_type', 'select_carrier_type', 'select_company_type',
        'shared_type', 'my_advizor_option', 'firm_my_advizor_option', 'custodian_option',
        'licenses_filter_switch', 'designation_filter_switch', 'ticker_filter_switch',
        'people_with_filter_switch', 'exclude_firms', 'exclude_persons', 'ins_line_filter_switch',
        'ins_register_filter_switch', 'firm_name_search', 'exclude_firm', 'bulk_filter_option',
        'email_quality', 'email_quality_A', 'email_quality_B','firm_contains_includes','hash','bulk_search_value','firm_keyword_category', 'plan_my_plans_option',
        'plan_plans_with_option'
      ];

      // Filter out excluded keys
      const filteredKeys = Object.keys(search).filter(key => !excludedKeys.includes(key));
      // Filter out undesired values for the remaining keys
      const filteredData = filteredKeys.reduce((result, key) => {
        const value = search[key];
        const valuesToRemove = [0, 'rep_crd', 'object', undefined, null, []];
        if (!valuesToRemove.includes(value)) {
          result[key] = value;
        }

        return result;
      }, {} as { [key: string]: any });

      for (const key in filteredData) {
        if (Object.prototype.hasOwnProperty.call(filteredData, key)) {
          const value = filteredData[key];

          if (Array.isArray(value) && value.length > 0) {
            count += 1;
            continue;
          }
          
          if (value != 'null' && value != 'undefined' && value !=  ''  && value !=  '[]') {
            count += 1;
            continue;
          }
        }
      }
    }
    this.filter_count = count;
  }

  keyFilter(val, type) {
    if (val != 'null' && val != null) {
      var data = val.split(':');
      if (type == "key") {
        if (data[0] == 'Type') {
          return 'Dataset';
        }
        return data[0];
      }
      return data[1];
    }
  }

  handleHeaderClose(tag) {
    if (tag.key == 'type') {
      if (this.search.type && this.search.type.length > 0) {
        const index: number = this.search.type.indexOf(tag.value);
        if (index !== -1) {
          this.search.type.splice(index, 1);
          this.datasetSearchParamType[tag.value] = false;
        }
        if (this.search.type.length == 0) {
          this.search.type = [];
          this.datasetSearchParamType = {};
        }
      }
    } else {
      if (this.search.quick_search && this.search.quick_search.length > 0) {
        const index: number = this.search.quick_search.indexOf(tag.value);
        if (index !== -1) {
          this.search.quick_search.splice(index, 1);
        }
      }

      if (this.search.quick_full_search && this.search.quick_full_search !== 'null' && this.search.quick_full_search !== 'undefined') {
        if (this.search.quick_full_search.hasOwnProperty(tag.value)) {
          delete this.search.quick_full_search[tag.value];
        }
      }
    }

    this.modelChanged();
  }

  datasetFilterChange($event, value: string) {
    
    //$event.stopPropagation();

    if (this.search.type === undefined) this.search.type = []

    const searchParams: string[] = this.search.type
    const index = searchParams.indexOf(value);
    if (index > -1) {
      searchParams.splice(index, 1);
      this.datasetSearchParamType[value] = false
    } else {
      searchParams.push(value)  
      this.datasetSearchParamType[value] = true
    }
    
    this.search.type = JSON.parse(JSON.stringify(searchParams));

    this.modelChanged();
  }

  datasetFilterOptionDisabled = (option) => (!option.enable)

  datasetFilterChecked(option: {label: string}){
    const datasetName: string = option.label
    return this.datasetSearchParamType[datasetName] as boolean
  }
  
  datasetFilterUpdate(){
    this.datasetSearchParamType = {}
    const types = this.search.type || []
    for(let type of types){
      this.datasetSearchParamType[type] = true
    }
  }

  datasetFilterTooltipMessage(option: {label: string}): string{
    const datasetName = option.label
    const tooltip: typeof ToolTipMessage = this.toolTipOrnament

    if(datasetName === "Family Office")
      return tooltip?.family_office_dataset_disabled
    if(datasetName === "Bank & Trust")
      return tooltip?.bank_and_trust_dataset_disabled
    if(datasetName == "Insurance")
      return tooltip?.insurance_office_dataset_disabled
  }

  inputFilterOptionDisabled(option: any): boolean {
    const cacheKey = option.id;

    if (this.disabledCache.has(cacheKey)) {
      return this.disabledCache.get(cacheKey);
    }

    let isDisabled: boolean;
    
    isDisabled = option.disabled == '1' ? false : true;

    this.disabledCache.set(cacheKey, isDisabled);
    return isDisabled;
  }

  trackByFn(index: number, item: { id?: number; field_id?: string }): number | string {
    return item?.id ?? item?.field_id ?? index;
  }

  trackByFnOptions(index: number, item: { id?: number; field_id?: string }): string {
    return `opts${item?.id}${index}`
  }

  getList(option) {
    if (option['option_function']) {
      const functionName = option['option_function'];

      if (functionName.includes("(")) {
        const functionArguments = this.extractFunctionArguments(functionName);
        
        // Ensure the function exists and is callable
        if (typeof this[functionArguments.name] === 'function') {
          return this[functionArguments.name](...functionArguments.args);
        } else {
          console.error(`Function ${functionArguments.name} does not exist or is not a function.`);
          return []; // Return an empty array if the function is not found
        }
      } else {
        return this[option['option_function']]
      }
    } else {
      return this[option['model_name']]
    }
  }

  extractFunctionArguments(functionString: string) {
    const functionName = functionString.split('(')[0];
    const argsString = functionString.split('(')[1].split(')')[0];
    const args = argsString ? argsString.split(',').map(arg => arg.trim().replace(/['"]+/g, '')) : [];
    
    return { name: functionName, args: args };
  }

  setModelName(value: any, fieldOption: any, model = 'model_change'): void {
    const functionName = fieldOption[model];

    if (typeof this[functionName] === 'function') {
      this[functionName](value); // Call the function with any required arguments
    } else {
      console.error(`Function ${functionName} does not exist or is not a function.`);
    }
  }

  setSwitchModelName(value, fieldOption) {
    this[fieldOption['switch_model_name']] = value;
    this.setModelName(value, fieldOption, 'switch_model_change')
  }

  getSwitchModel = (option) => this[option['switch_model_name']]
  
  expandAll() {
    setTimeout(()=> {
      this.expandbuttonShow = !this.expandbuttonShow;
    },300)
  }

  openCollapsePanel(level:string) {
    if (level == 'contact') {
      this.openContactPanel = !this.openContactPanel
    } else {
      this.openCompanyPanel = !this.openCompanyPanel
    }
  }

  updatePanelData(category: string) {
    let panels = this.panels
    if (panels[category]?.length > 0) {
      panels[category].forEach((item, key) => {
        const panel = panels[category][key][0];
        panel.filterCount = this.countFields(panel.category,panel.level);
      });
    }
  }

  countFields(category: string, level: string) {
  
      const checkCondition = (condition: string) => Array.isArray(this.search[condition]) ? this.search[condition].length ? 1 : 0 : !!this.search[condition] ? 1 : 0;
  
      let count = 0;

      for (const condition of categoryField[level]) {
        if (condition.case === category) {
          count += condition.values.reduce((sum, value) => sum + checkCondition(value), 0);
          break;
        }
      }

      return count;
  }

  openPersonProfile(item: {
    db: boolean,
    dc: boolean,
    id: number
  }){
    this._openProfile(item, "person")
  }

  openFirmProfile(item: {
    db: boolean,
    dc: boolean,
    id: number
  }): void {
    this._openProfile(item, "firm")
  }

  /**
   * 
   * @param options subtype is either firm or person
   */
  private _openProfile(item: {
    db: boolean,
    dc: boolean,
    id: number
  }, subType: string) {

    let type = null
    let id: number | string = null
    if (item?.db) {
      type = DEFINED_BENEFIT
      id = item.id
    }
    else if (item?.dc) {
      type = DEFINED_CONTRIBUTION
      id = item.id
    }

    this.profileData = {
      type: type,
      sub_type: subType,
      crd: id.toString(),
    }

    this.isProfilevisible = true;

    this.renderer2.setStyle(document.body, 'overflow', 'hidden');
  }

  openProfile(item,modal): void {
    if (!this.auth.loggedIn()) {
      this.router.navigate(['/login']);
      return;
    }

		let type = 'advisor';
		let sub_type = 'person';
		if (item.firm) {
			sub_type = 'firm';
		} else if (item.insurance_person || item.type == 'in-person') {
			sub_type = 'person';
			type = 'insurance';
		} else if (item.type == 'family-person') {
      sub_type = 'person';
			type = 'family';
    } else if (item.type == 'family-firm') {
      sub_type = 'firm';
			type = 'family';
    }else if (item.type == 'bank-firm') {
      sub_type = 'firm';
			type = 'bank';
    }else if(item.type === 'bank-person') {
      type = 'bank';
      sub_type = 'person';
    }else if(item.type === 'db-person') {
      type = 'db';
      sub_type = 'person'
    }else if(item.type === 'dc-person') {
      type = 'dc';
      sub_type = 'person'
    }
    
		this.profileData = {
			'type': type,
			'sub_type': sub_type,
			'crd': item.crd ? item.crd : item.profile_id,
      'score': item?.score ? item.score: null,
      'score_id': this.scoreID? this.scoreID: null  
		};
  
    if (modal) {
      this.modalServiceShared.closeModal(this.dbaContactId)
    }

		this.isProfilevisible = true;

    this.renderer2.setStyle(document.body, 'overflow', 'hidden');
	}

	close() {
		this.isProfilevisible = false;
    this.renderer2.setStyle(document.body, 'overflow','auto');
	}

	drawerClose() {
    this.renderer2.setStyle(document.body, 'overflow','auto');
  }

  changePeopleWith($event,value) {
    $event.stopPropagation();
    var index = this.people_with.indexOf(value);
    if ($event.target.checked) {
      if (index == -1) {
        this.people_with.push(value);
      }
    } else if (!$event.target.checked) {
      if (index > -1) {
        this.people_with.splice(index, 1);
      }
    }
    this.search.people_with = JSON.parse(JSON.stringify(this.people_with));
    this.modelChanged();
  }

  clearPeopleWith() {
    this.people_with = [];
    this.search.people_with = [];
    this.modelChanged();
  }

  checkClientLabel(label) {
    if (label == 'HNW') {
      return 'High-Net-Worth';
    }
    return label;
  }

  getScore(args={}) {
		this.scoreService.getScore(args).subscribe((res: { status: string; data: Score }) => {
			if (res.status === "200" && res.data) {
				this.score = res.data;
				this.scoreID =  res.data['selected_score_id'];
        this.search.lead_score_id = this.scoreID;
			}
		});
	}

  changeScore() {
    this.scoreService.setScore(this.scoreID).subscribe((res: { status: string; data: Score }) => {
			if (res.status !== "200") {
        console.error("Failed to set score", res)
			}
    });
		this.getScore({'id': this.scoreID});
    this.modelChanged();
	}

  changeMinimumScore($event,value) {
    $event.stopPropagation();
    var index = this.lead_score.indexOf(value);
    if ($event.target.checked) {
      if (index == -1) {
        this.lead_score.push(value);
      }
    } else if (!$event.target.checked) {
      if (index > -1) {
        this.lead_score.splice(index, 1);
      }
    }
    this.search.lead_score = JSON.parse(JSON.stringify(this.lead_score));
    this.modelChanged();
  }

  logoNameFilter(row) {
    if (row) {
      return row.first_name.replaceAll(/[&/\\#,+()$~%.'":*?<>{}0-9\s]/g, '').charAt(0)+row.last_name.replaceAll(/[&/\\#,+()$~%.'":*?<>{}0-9\s]/g, '').charAt(0);
    }
	}

  logoNamePersonFilter(row) {
    let name = row.name.split(' ');

    if (name.length > 1 && row.type != 'firm') {
      return name[0].replaceAll(/[&/\\#,+()$~%.'":*?<>{}0-9\s]/g, '').charAt(0)+name[name.length-1].replaceAll(/[&/\\#,+()$~%.'":*?<>{}0-9\s]/g, '').charAt(0);
    } else {
      return row.name.replaceAll(/[&/\\#,+()$~%.'":*?<>{}0-9\s]/g, '').charAt(0);
    }
  }

  handleOpenBulkModal(id :string): void {
    if(id && id in this.searchVariables) this.modalService.openModal(id)
  }

  inputFilterAccess(field_id) {
    if (['family_wealth_origin', 'family_geographic_focus', 'family_industry_interests', 'family_direct_investments'].includes(field_id) && !this.user.has_access_family_office) {
      return false;
    }
    return true;
  }

  hasStaticField(cat: { search_field: { type: string }[] }): boolean {
    return cat?.search_field?.some(v => v.type === "static");
  }

  getOption(option: any, type, fieldOption: any): any {
    return option.hasOwnProperty(fieldOption[type]) ? option[fieldOption[type]] : option;
  }
  
  private processExportResponse(response: ExportResponse): void {
          if (!response) {
                  this.downloadMessage.status_message = "Something went wrong";
                  this.downloadMessage.status = "error";
                  this.downloadMessage.message = "Excel Export could not be generated. Please try again.";
                  this.exportStatus = false;
                  return;
          }

          if (response?.status === '404') {
                  this.downloadMessage.status_message = "Not found";
                  this.downloadMessage.status = "error";
                  this.downloadMessage.message = response?.message;
                  this.exportStatus = false;
                  return;
          }

          if (response?.status === '400') {
                  this.downloadMessage.status_message = "Invalid";
                  this.downloadMessage.status = "error";
                  this.downloadMessage.message = response?.message;
                  this.exportStatus = false;
                  return;
          }

          // limit is exceed
          if (response?.status === '401') { 
                  this.exportStatus = false;
                  this.isAntWaitingBox = false;
                  this.limit_over_model = true;
                  return;
          }

          // large exports
          if (response?.status === '206') {
                  this.downloadMessage.status_message = "Your report is processing...";
                  this.downloadMessage.status = "large";
                  this.downloadMessage.message = response?.message;
                  timer(60000).subscribe(() => {
                          this.advExport = interval(1500).subscribe(() => {
                                  this.checkAdvReport(response.reportId);
                          });
                  });
                  return;
          }

          // normal export
          if (response?.status === '200' && response?.reportId) {
                  this.downloadMessage.status_message = "Your report is processing...";
                  this.downloadMessage.status = "success";
                  this.advExport = interval(1500).subscribe(() => {
                          this.checkAdvReport(response.reportId);
                  });
          }
  }
  showLocationModal(){
    this.locationModalActive = true     
  }
  locationModalActiveChange(){
    this.locationModalActive = false 
  } 

  getIcons(row: Record<string, unknown>): Icon[] {
    return getIconsByRowKeys(row);
  }

  updateSearch(search: unknown){
    this._helper.setCookie('advizorSearchData', search);
    this.initLoad();
  }

  isTickerDisable(type=null): boolean {
    if (type)
      return !this.select_ticker.length && !this.search?.cusip?.length && !this.search?.morningstar_category?.length;
    else
      return !this.select_ticker.length && !this.search?.cusip?.length;
  }
}

