<div class="mx-[5%] mt-[1%]">
    <div>
        <nz-table #basicTable [nzData]="disclosures" [nzFrontPagination]="true" [nzPageSize]="PER_PAGE"
            [nzTotal]="disclosures?.length" [nzPageIndex]="currentPage" (nzPageIndexChange)="onPageChange($event)"
            [nzShowSizeChanger]="false" [nzSize]="'middle'">
            <thead>
                <tr>
                    <th nzWidth="60px"></th>
                    <th class="fw-700 text-left fs-12">Event Date</th>
                    <th class="fw-700 text-left fs-12">Type</th>
                    <th class="fw-700 text-left fs-12">Settlement Amount</th>
                    <th class="fw-700 text-left fs-12">Resolution</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let data of basicTable.data">
                    <tr (click)="onExpandChange(data.id, !expandSet.has(data.id))">
                        <td [nzExpand]="expandSet.has(data.id)"></td>
                        <td class="fs-12">{{ data.event_date | date:'MM-dd-yyyy' }}</td>
                        <td class="text-left fs-12">{{ data.disclosure_type }}</td>
                        <td class="text-left fs-12">
                            {{ data.settlement_amount}}{{buildPercentage(data.settlement_amount,
                            data.damage_amount_requested)}}</td>
                        <td class="text-left fs-12">{{ data.disclosure_resolution }}</td>
                    </tr>
                    <tr *ngIf="expandSet.has(data.id)">
                        <td colspan="5">
                            <div style="margin-left: 3%;">
                                <div *ngIf="data.allegations" class="disclosure-section">
                                    <span class="disclosure-label fs-12">Allegations</span>
                                    <p class="value fs-12">{{data.allegations}}</p>
                                </div>
                                <div *ngIf="data.damage_amount_requested" class="disclosure-section">
                                    <span class="disclosure-label fs-12">Damage Amount Requested</span>
                                    <p class="value fs-12">{{data.damage_amount_requested}}</p>
                                </div>
                                <div *ngIf="data.arbitration_claim_filed_detail" class="disclosure-section">
                                    <span class="disclosure-label fs-12">Arbitration Claim</span>
                                    <p class="value fs-12">{{data.arbitration_claim_filed_detail}}</p>
                                </div>
                                <div *ngIf="data.arbitration_docket_number" class="disclosure-section">
                                    <span class="disclosure-label fs-12">Arbitration Docket Number</span>
                                    <p class="value fs-12">{{data.arbitration_docket_number}}</p>
                                </div>
                                <div *ngIf="data.broker_comment" class="disclosure-section">
                                    <span class="disclosure-label fs-12">Broker Comment</span>
                                    <p class="value fs-12">{{data.broker_comment}}</p>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </nz-table>
    </div>
</div>