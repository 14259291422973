import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";
import { Observable, EMPTY } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { catchError, mergeMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class OpenRedirectResolver implements Resolve<any> {
  constructor(private http: HttpClient, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const id = route.paramMap.get("id");
    if (!id || id.trim().length !== 14) {
      this.router.navigate(["/"]);
      return EMPTY;
    }

    return this.http.get(`https://app.api.advizorpro.com/id/${id}`).pipe(
      mergeMap((response) => {
        // Assuming the API returns { redirectUrl: string }
        const redirectUrl = (response as any)?.path;
        this.router.navigate([redirectUrl]);
        return EMPTY;
      }),
      catchError((error) => {
        console.error("Error during redirect:", error);
        this.router.navigate(["/"]);
        return EMPTY;
      })
    );
  }
}
