<pg-container *ngIf="!locationModalActive" id="advisor-search">
    <div class="row mb-3" *ngIf="(!user?.basic_access || !user?.basic_access_advizor)">
        <div class="col-lg-3">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card card-default adv-box-shadow">
                        <div class="card-body">
                            <div class="row">
                                <form class="pl-1 pr-1 pb-3 w-100">
                                    <div class="col-lg-12 mt-3">
                                        <span class="fs-12 pull-left advanced-filter">Advanced Filters</span>
                                        <a href="javascript:void(0);" class="fs-12 pull-right"  (click)="resetForm()"
                                            [ngClass]="activeButton">Reset</a>
                                    </div>
                                    <div class="col-lg-12 mt-2">
                                        <input type="hidden" name="document_enter_event"
                                            (document:keypress)="handleEvent($event)">

                                        <div class="typehead">
                                            <div class="multiple-control cus-multiple-control c-adv-search">
                                                <app-autocomplete [(ngModel)]="quick_search" [getValue]="true"
                                                    [searchButtonPrimaryColor]="'#A28ECE'"
                                                    [searchButtonSecondaryColor]="'#8166CD'"
                                                    [searchIconClass]="'fa-solid fa-wand-magic-sparkles'"
                                                    [isSearchButtonVisible]="quick_search && quick_search.includes(' ')"
                                                    [iconTitle]="'AI Search'"
                                                    [isSearchButtonLoading]="isLoading"
                                                    (searchButtonClicked)="onSearchButtonClick($event)"
                                                    [removeValue]="true" [callback]="company_name_autocomplete"
                                                    [placeholder]="user.has_access_insurance ? (user.has_access_advisor ? 'Search Person, Firm, CRD, NPN, or available filters' : 'Search Person or NPN') : 'Search Person, Firm, CRD or available filters'"
                                                    name="quick_search" [typeaheadItemTemplate]="typeaheadItemTemplate"
                                                    typeaheadOptionField="name"
                                                    (typeaheadOnSelect)="selectCompany($event)"
                                                    (change)="changeCompany($event)" nz-tooltip
                                                    [nzTooltipColor]="tooltipColor"
                                                    nzTooltipTitle="{{tooltipList['search_box']}}"
                                                    class="c-adv-search-autocomplete position-relative">
                                                </app-autocomplete>

                                                <ng-template #typeaheadItemTemplate let-model="item" let-index="index">
                                                    <div *ngIf="model.type =='suggestion'" class="d-flex pt-2 flex text-sm align-items-center">
                                                        <i class="fa-solid fa-magnifying-glass"></i>
                                                        <span class="ml-2 text-gray-800" [innerHTML]="model.suggestion"></span>
                                                    </div>
                                                    <div class="row pt-2" *ngIf="model.type !='suggestion'">
                                                        <div class="col-1-5 ml-2 d-flex align-items-center">
                                                            <div class="row c-row">
                                                                <label (click)="preEvent($event, model)" class="d-flex">
                                                                    <input type="checkbox" name="typehead[]"
                                                                        [value]="model?.crd"
                                                                        [checked]="company_checkbox.includes(model.name)?true:false"
                                                                        class="mr-1">
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="col-1-5">
                                                            <ng-container *ngIf="model?.photo_url; else ownerAdvPhoto">
                                                                <div class="circle circle-auto circle-align">
                                                                    <img class="circle-img" src="{{model?.photo_url}}">
                                                                </div>
                                                            </ng-container>
                                                            <ng-template #ownerAdvPhoto>
                                                                <div>
                                                                    <span class="circle circle-auto auto-user-initial top-0">
                                                                        {{ logoNamePersonFilter(model) }}
                                                                    </span>
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                        <div class="col-5 r-bor pl-0">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <div class="col-12 pl-0 bold lh-normal">
                                                                        <span class="fs-12"
                                                                            style="white-space: normal;"> {{ model.name
                                                                            }} </span>
                                                                    </div>
                                                                    <div class="col-12 pl-0 lh-normal">
                                                                        <span class="fs-10 inline"
                                                                            style="white-space: normal;"> {{
                                                                            model.city_state }} </span>
                                                            
                                                                        <span *ngIf="model.txt_crd" class="fs-10 inline"
                                                                            style="white-space: normal;"><span class="dot-btn inline"></span>CRD: {{
                                                                            model.txt_crd }}</span>

                                                                        <span *ngIf="model.txt_npn" class="fs-10 inline"
                                                                            style="white-space: normal;"><span class="dot-btn inline"></span>NPN: {{
                                                                            model.txt_npn }}</span>
                                                                    </div>
                                                                    <div class="col-12 pl-0 lh-normal">
                                                                        <div class="mt-1">
                                                                            <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({
                                                                                'advizor': model?.advizor,
                                                                                'broker': model?.broker,
                                                                                'insurance': model?.insurance && user.has_access_insurance,
                                                                                'fo': model?.fo_id && user.has_access_family_office,
                                                                                'bt': model?.bt_id && user.has_access_bank_and_trust,
                                                                                'dc': model?.dc && user.has_access_defined_contribution,
                                                                                'db': model?.db && user.has_access_defined_benefit,
                                                                            })" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-5 r-item">
                                                            <div class="row pl-2" *ngIf="model.type=='firm'">
                                                                <div class="col-12 pl-0" *ngIf="model?.aum">
                                                                    <i class="fas fa-dollar-sign color-grn mr-2"></i>
                                                                    <span class="inline fs-12"
                                                                        style="white-space: normal;">{{ (model?.aum |
                                                                        currency:'USD':'symbol':'1.0-2')+' M'
                                                                        }}</span>
                                                                </div>
                                                                <div class="col-12 pl-0" *ngIf="model?.persons">
                                                                    <i class="far fa-user-circle text-danger mr-2"></i>
                                                                    <span class="inline fs-12"
                                                                        style="white-space: normal;"> {{
                                                                        model.persons | number : 0 }} </span>
                                                                </div>
                                                            </div>
                                                            <div class="row pl-2" *ngIf="model.type=='person'">
                                                                <div class="col-12 pl-0 firm-bld"
                                                                    *ngFor="let team of model.team">
                                                                    <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({
                                                                        'team': true,
                                                                    })" [iconExtraClass]="'team-person-auto'" />
                                                                    <span class="d-inline fs-12"
                                                                        style="white-space: normal;"> {{ team |
                                                                        uppercase }}
                                                                    </span>
                                                                </div>
                                                                <div class="col-12 pl-0 firm-bld"
                                                                    *ngFor="let frm of model.firm_name">
                                                                    <i class="far fa-building text-primary mr-2"
                                                                        *ngIf="frm"></i>
                                                                    <span class="d-inline fs-12"
                                                                        style="white-space: normal;"> {{ frm }}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </div>

                                            <div class="ai-feedback hidden" *ngIf="aiFeedback || errorMessage">
                                                <span [ngClass]="{'error-message': errorMessage}" [innerHTML]="typedFeedback"></span>
                                                <span *ngIf="isTyping" class="dot-blinking"></span>
                                            </div>
                                            <!-- <i class="pg pg-search"></i> -->
                                        </div>

                                        <div class="mt-2 mr-0 grid-layout">
                                            <div class="checkbox mt-0 mb-0" *ngFor="let option of types let i = index">
                                                <div *ngIf="option.label !== 'Defined Contribution' && option.label !== 'Defined Benefit'" class="mt-2 mb-1">
                                                    <input type="checkbox" [id]="option.label"
                                                        [checked]="datasetFilterChecked(option)"
                                                        [disabled]="datasetFilterOptionDisabled(option)"
                                                        (change)="datasetFilterChange($event, option.label)">
                                                    <label class="mr-0" [for]="option.label">
                                                        <span class="content-label-checkbox">
                                                            {{ option.label }} <i
                                                                *ngIf="datasetFilterOptionDisabled(option)"
                                                                class="fa fa-question-circle" nz-tooltip
                                                                [nzTooltipColor]="tooltipColor"
                                                                nzTooltipTitle="{{ datasetFilterTooltipMessage(option) }}"></i>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-lg-12 mt-2 adv-type inline"
                                        *ngIf="!onlyInsuranceAccess && !onlyFamilyAccess">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <a class="fs-12 pull-right" href="javascript:void(0)"
                                                (click)="uploadCrd()"><u>Bulk Search</u></a>
                                            <a class="fs-12 pull-right" href="javascript:void(0)"
                                                (click)="uploadList()"><u>List Matching</u></a>
                                        </div>
                                    </div>

                                    <!-- <div class="col-lg-12 mt-2">
                                        <div class="bulksearch" (click)="uploadCrd()">
                                            <div>
                                                <span class="searchicon"><i class="fa fa-search mr-2" aria-hidden="true"></i></span>
                                                <span>Bulk Search</span>
                                            </div>
                                            <span class="chevronicon"><i class="fa fa-chevron-right" aria-hidden="true"></i></span>
                                        </div>
                                    </div> -->
                                    <div class="col-lg-12">
                                        <label class="mt-4 mb-2 panelCategory"
                                            *ngIf="hasContactFilters && panels['Contacts']?.length > 0">Contacts
                                        </label>
                                        <nz-collapse>
                                            <nz-collapse-panel *ngFor="let panel of panels['Contacts']; trackBy: trackByFn; let i = index"
                                                [nzHeader]="panel[0]['category']"
                                                [nzActive]="(panel[0]['shouldOpenPanel'] && i === panel[0]['indexOfPanelToOpen'])"
                                                (nzActiveChange)="panelClick(i,'contact')"
                                                [ngClass]="[(panel[0]['filterCount']?'active_cat':'' )]"
                                                [nzExtra]="panel[0]['filterCount']?panel[0]['filterCount']:panel[0]['shouldOpenPanel'] ?closePanel:openPanel">
                                                <div class="col-lg-12 p-0 form-group" *ngFor="let cat_value of panel">
                                                    <ng-container *ngIf="cat_value?.search_field?.length > 0 && !hasStaticField(cat_value); else elseField">
                                                        <ng-container *ngTemplateOutlet="commonTemplate; context: {data: cat_value}"></ng-container>
                                                    </ng-container>
                                                    <ng-template #elseField>
                                                        <div *ngIf="cat_value.field_id == 'lead_score'">
                                                            <ng-container *ngIf="score?.score_name; else elseScore">
                                                                <label class="bold fs-12">Score</label>
                                                                <div class="d-block checkbox"
                                                                    *ngFor="let option of minimumScore | keyvalue: LeadScoreOrder">
                                                                    <input type="checkbox" [id]="option.key"
                                                                        name="lead_score[]" [value]="minimumScore[option.key]"
                                                                        [checked]="lead_score.includes(option.key)?true:false"
                                                                        (change)="changeMinimumScore($event, option.key)">
                                                                    <label [for]="option.key">
                                                                        <span>
                                                                            <span class="score-btn bg-score-grey">
                                                                                <img src="assets/img/{{ option.value }}.png"
                                                                                    width="14px" [alt]="option.value">
                                                                                <span class="semi-bold">{{ option.key}}</span>
                                                                            </span>
                                                                        </span>
                                                                    </label>
                                                                </div>
    
                                                                <label class="bold fs-12">Scoring Model</label>
                                                                <div class="mb-2">
                                                                    <pg-select style="width: 100%;" [Mode]="'single'"   [PlaceHolder]="'Select'"
                                                                        [(ngModel)]="scoreID" [NotFoundContent]="'Not found'"
                                                                        name="scoreName" (ngModelChange)="changeScore()">
                                                                        <pg-option *ngFor="let option of score.score_list"
                                                                        [Label]="option.score_name" [Value]="option.score_id">
                                                                        </pg-option>
                                                                    </pg-select>
                                                                </div>
                                                                <div class="fs-12 mb-2">
                                                                    <a [routerLink]="'/score'"><i class="fa fa-cog fs-12"
                                                                            aria-hidden="true"></i> <span
                                                                            class="ml-2 fs-12">Manage Scores</span></a>
                                                                </div>
                                                            </ng-container>
                                                            <ng-template #elseScore>
                                                                <label>You don't have a score yet. Let's create and manage your custom score.
                                                                </label>
                                                                <a [routerLink]="'/score'" class="btn btn-md btn-default"><i
                                                                        class="fa fa-cog text-primary fs-12"
                                                                        aria-hidden="true"></i> <span
                                                                        class="ml-2 text-primary fs-12">Manage
                                                                        Scores</span></a>
                                                            </ng-template>
                                                        </div>

                                                        <div *ngIf="cat_value.field_id == 'person_keyword'">
                                                            <label>Keyword&nbsp;<i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                            &nbsp;
                                                            <pg-select style="width: 100%;" [Mode]="'multiple'"
                                                                [PlaceHolder]="'Select'"
                                                                [(ngModel)]="search.person_keywords"
                                                                [NotFoundContent]="'Not found'" name="person_keywords"
                                                                [disabled]="!search.person_keyword_category"
                                                                (ngModelChange)="modelChanged()">
                                                                <pg-option
                                                                    *ngFor="let option of filtered_web_advizor_person_keyword"
                                                                    [Label]="option.keyword_display"
                                                                    [Value]="option.keyword_display"
                                                                    [Disabled]="option.disabled">
                                                                </pg-option>
                                                            </pg-select>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'initial_appointment_date'">
                                                            <label>Initial Appointment Date&nbsp;<i
                                                                    *ngIf="tooltipList['initial_appointment_date']"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{tooltipList['initial_appointment_date']}}"></i></label>
                                                            <div class="row">
                                                                <div class="col-lg-6">
                                                                    <div pgFormGroupDefault class="calendar-input">
                                                                        <nz-date-picker
                                                                            [(ngModel)]="search.min_appointment_date"
                                                                            name="min_appointment_date"
                                                                            (ngModelChange)="startAptDateChange($event)"
                                                                            class="w-100"
                                                                            [Placeholder]="'Start date'"></nz-date-picker>
                                                                        <br />
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div pgFormGroupDefault class="calendar-input">
                                                                        <nz-date-picker
                                                                            [(ngModel)]="search.max_appointment_date"
                                                                            name="max_appointment_date"
                                                                            [Placeholder]="'End date'"
                                                                            (ngModelChange)="endAptDateChange($event)"
                                                                            class="w-100"></nz-date-picker>
                                                                        <br />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'insurance_carrier'">
                                                            <label>Insurance Carrier&nbsp;<i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                            <div class="row">
                                                                <pg-select style="width: 100%;" class="col-lg-12"
                                                                    [Mode]="'single'" [PlaceHolder]="'Select'"
                                                                    [(ngModel)]="select_carrier_type"
                                                                    [NotFoundContent]="'Not found'"
                                                                    name="select_carrier_type"
                                                                    (ngModelChange)="changeCarrierType($event)">
                                                                    <pg-option *ngFor="let option of carrier_contains"
                                                                        [Label]="option.name" [Value]="option.value"
                                                                        [Disabled]="option.disabled">
                                                                    </pg-option>
                                                                </pg-select>
                                                                <!-- <div class="multiple-control col-lg-6"> -->
                                                                <div class="col-lg-12">
                                                                    <div class="multiple-control pl-1 mt-2">
    
                                                                        <pg-tag-control *ngIf="insCarrierType"
                                                                            [(ngModel)]="text_carrier_contain"
                                                                            name="text_carrier_contain" class=""
                                                                            (keypress)="handleKeyEvent($event)"></pg-tag-control>
                                                                        <div
                                                                            *ngIf="!insCarrierType && select_ins_carriers.length > 0">
                                                                            <span class="custom-chip"
                                                                                *ngFor="let item of select_ins_carriers">
                                                                                <i class="pg pg-close"
                                                                                    (click)="removeInsCarriers(item)"></i>
                                                                                {{ item }}
                                                                            </span>
                                                                        </div>
                                                                        <app-autocomplete *ngIf="!insCarrierType"
                                                                            [(ngModel)]="insurance_carrier"
                                                                            [getValue]="true" [removeValue]="true"
                                                                            [callback]="ins_carriers_autocomplete"
                                                                            name="insurance_carrier"
                                                                            (typeaheadOnMultipleSelect)="selectInsCarriers($event)"
                                                                            [isMultiple]="true"
                                                                            [autoSelectionData]="autoSelectionInsCarriers"
                                                                            (change)="changeInsCarriers($event)">
                                                                        </app-autocomplete>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'show_people_with'">
                                                            <label class="d-inline mb-2">
                                                                <a href="javascript:void(0)" class="pull-right clear-text"
                                                                    (click)="clearPeopleWith()">
                                                                    Clear
                                                                </a>
                                                                Show People With&nbsp;<i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i>&nbsp;
                                                            </label>
                                                            <div class="mt-1">
                                                                <div class="d-block">
                                                                    <nz-switch [(ngModel)]="peopleWithFilterSwitch"
                                                                        name="peopleWithFilterSwitch" nzSize="small"
                                                                        (ngModelChange)="modelChanged()"
                                                                        nzCheckedChildren="OR"
                                                                        nzUnCheckedChildren="AND"></nz-switch>
                                                                </div>
                                                                <div class="d-block checkbox"
                                                                    *ngFor="let option of peopleWith">
                                                                    <input type="checkbox" [id]="option.label"
                                                                        name="people_with[]" [value]="option.value"
                                                                        [checked]="people_with.includes(option.value)?true:false"
                                                                        (change)="changePeopleWith($event, option.value)">
                                                                    <label [for]="option.label">
                                                                        <span>
                                                                            {{ option.label }}
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'latest_disclosure_date'">
                                                            <label>Latest Disclosure Date&nbsp;<i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                            <div class="row">
                                                                <div class="col-lg-6">
                                                                    <div pgFormGroupDefault class="calendar-input">
                                                                        <nz-date-picker
                                                                            [(ngModel)]="search.start_latest_disclosure_date"
                                                                            name="start_latest_disclosure_date"
                                                                            (ngModelChange)="startDateDiscChange($event)"
                                                                            [Placeholder]="'Start date'"></nz-date-picker>
                                                                        <br />
                                                                    </div>
                                                                </div>
    
                                                                <div class="col-lg-6">
                                                                    <div pgFormGroupDefault class="calendar-input">
                                                                        <nz-date-picker
                                                                            [(ngModel)]="search.end_latest_disclosure_date"
                                                                            name="end_latest_disclosure_date"
                                                                            [Placeholder]="'End date'"
                                                                            (ngModelChange)="endDateDiscChange($event)"></nz-date-picker>
                                                                        <br />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'previous_firm'">
                                                            <label>Previous Firm&nbsp;<i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                            <div class="multiple-control custom-m-parent">
                                                                <div *ngIf="select_previous_firm.length > 0">
                                                                    <span class="custom-chip"
                                                                        *ngFor="let item of select_previous_firm">
                                                                        <i class="pg pg-close"
                                                                            (click)="removePreviousFirm(item)"></i>
                                                                        {{ item }}
                                                                    </span>
                                                                </div>
                                                                <app-autocomplete [(ngModel)]="previous_firm"
                                                                    [getValue]="true" [removeValue]="true"
                                                                    [callback]="previous_firm_autocomplete"
                                                                    [autoSelectionData]="autoSelectionPreviousFirm"
                                                                    name="previous_firm" class="custom-m-autocomplete"
                                                                    (typeaheadOnMultipleSelect)="selectPreviousFirm($event)"
                                                                    [isMultiple]="true"
                                                                    (change)="changePreviousFirm($event)">
                                                                </app-autocomplete>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'gender'">
                                                            <label class="mr-2">Gender&nbsp;<i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
    
                                                            <div class="checkbox inline mr-2">
    
                                                                <input type="checkbox" name="gender_male"
                                                                    [(ngModel)]="gender_male"
                                                                    (ngModelChange)="genderChanged()" id="gender-m"
                                                                    value="male">
                                                                <label for="gender-m" class="m-0">Male</label>
                                                            </div>
                                                            <div class="checkbox inline mr-2">
                                                                <input type="checkbox" name="gender_female"
                                                                    [(ngModel)]="gender_female"
                                                                    (ngModelChange)="genderChanged()" id="gender-f"
                                                                    value="female">
                                                                <label for="gender-f" class="m-0">Female</label>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'registration_date'">
                                                            <label>Securities Registration Date&nbsp;<i
                                                                    *ngIf="cat_value.tooltip" class="fa fa-question-circle"
                                                                    nz-tooltip [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                            <div class="row">
                                                                <div class="col-lg-6">
                                                                    <div pgFormGroupDefault class="calendar-input">
                                                                        <nz-date-picker
                                                                            [(ngModel)]="search.start_registration_date"
                                                                            name="start_registration_date"
                                                                            (ngModelChange)="startDateRegistrationChange($event)"
                                                                            [Placeholder]="'Start date'"></nz-date-picker>
                                                                        <br />
                                                                    </div>
                                                                </div>
    
                                                                <div class="col-lg-6">
                                                                    <div pgFormGroupDefault class="calendar-input">
                                                                        <nz-date-picker
                                                                            [(ngModel)]="search.end_registration_date"
                                                                            name="ennd_registration_date"
                                                                            [Placeholder]="'End date'"
                                                                            (ngModelChange)="endDateRegistrationChange($event)"></nz-date-picker>
                                                                        <br />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'insurance_agency'">
                                                            <label>Insurance Agency&nbsp;<i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                            <div class="row custom-m-parent">
                                                                <div class="col-lg-12">
                                                                    <pg-select style="width: 100%;" [Mode]="'single'"
                                                                        [PlaceHolder]="'Select'"
                                                                        [(ngModel)]="select_company_type"
                                                                        [NotFoundContent]="'Not found'"
                                                                        name="select_company_type"
                                                                        (ngModelChange)="changeCompanyType($event)">
                                                                        <pg-option *ngFor="let option of company_contains"
                                                                            [Label]="option.name" [Value]="option.value"
                                                                            [Disabled]="option.disabled">
                                                                        </pg-option>
                                                                    </pg-select>
                                                                </div>
                                                                <!-- <div class="multiple-control col-lg-6"> -->
                                                                <div class="col-lg-12 mt-2">
                                                                    <div class="multiple-control pl-1">
                                                                        <pg-tag-control *ngIf="insAgencyActive"
                                                                            [(ngModel)]="text_company_contain"
                                                                            name="text_company_contain" class=""
                                                                            (keypress)="handleKeyEvent($event)"></pg-tag-control>
                                                                        <div
                                                                            *ngIf="!insAgencyActive && select_ins_companies.length > 0">
                                                                            <span class="custom-chip"
                                                                                *ngFor="let item of select_ins_companies">
                                                                                <i class="pg pg-close"
                                                                                    (click)="removeInsCompany(item)"></i>
                                                                                {{ item }}
                                                                            </span>
                                                                        </div>
                                                                        <app-autocomplete *ngIf="!insAgencyActive"
                                                                            [(ngModel)]="insurance_company"
                                                                            [getValue]="true" [removeValue]="true"
                                                                            [callback]="ins_company_autocomplete"
                                                                            name="insurance_company"
                                                                            class="custom-m-autocomplete"
                                                                            (typeaheadOnMultipleSelect)="selectInsCompany($event)"
                                                                            [isMultiple]="true"
                                                                            [autoSelectionData]="autoSelectionInsCompany"
                                                                            (change)="changeInsCompany($event)">
                                                                        </app-autocomplete>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'captive'">
                                                            <label class="">Captive&nbsp;<i *ngIf="tooltipList['captive']"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{tooltipList['captive']}}"></i></label>
                                                            <div class="row">
                                                                <div class="col-lg-12">
                                                                    <pg-select style="width: 100%;" [Mode]="'single'"
                                                                        [PlaceHolder]="'Select'"
                                                                        [(ngModel)]="search.captive"
                                                                        [NotFoundContent]="'Not found'" name="captive"
                                                                        [AllowClear]="'true'"
                                                                        (ngModelChange)="modelChanged()">
                                                                        <pg-option [Label]="'Yes'" [Value]="1"></pg-option>
                                                                        <pg-option [Label]="'No'" [Value]="0"></pg-option>
                                                                    </pg-select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'person_title'">
                                                            <label>Title <i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                            <div class="row">
                                                                <div class="col-lg-12">
                                                                    <pg-select style="width: 100%;" [Mode]="'single'"
                                                                        [PlaceHolder]="'Select'"
                                                                        [(ngModel)]="select_title_type"
                                                                        [NotFoundContent]="'Not found'"
                                                                        name="select_title_type"
                                                                        (ngModelChange)="changeTitleType($event)">
                                                                        <pg-option
                                                                            *ngFor="let option of person_title_contains"
                                                                            [Label]="option.name" [Value]="option.value"
                                                                            [Disabled]="option.disabled">
                                                                        </pg-option>
                                                                    </pg-select>
                                                                </div>
                                                                <!-- <div class="multiple-control col-lg-6"> -->
                                                                <div class="col-lg-12 mt-2">
                                                                    <div class="multiple-control pl-1">
                                                                        <pg-tag-control *ngIf="titleType"
                                                                            [(ngModel)]="text_title_contain"
                                                                            name="text_title_contain" class=""
                                                                            (keypress)="handleKeyEvent($event)">
                                                                        </pg-tag-control>
    
                                                                        <div *ngIf="!titleType">
                                                                            <span class="custom-chip"
                                                                                *ngFor="let item of select_title_contain">
                                                                                <i class="pg pg-close"
                                                                                    (click)="removeTitleContain(item)"></i>
                                                                                {{ item }}
                                                                            </span>
                                                                        </div>
                                                                        <app-autocomplete *ngIf="!titleType"
                                                                            [(ngModel)]="title_contain" [getValue]="true"
                                                                            [callback]="title_contain_autocomplete"
                                                                            name="title_contain"
                                                                            (typeaheadOnMultipleSelect)="selectTitleContain($event)"
                                                                            [isMultiple]="true"
                                                                            [autoSelectionData]="autoSelectionTitle"
                                                                            (change)="changeTitleContain($event)"
                                                                            (keyup.enter)="keyUpTitleContain($event)">
                                                                        </app-autocomplete>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'person_county'">
                                                            <label>County <i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                            <div class="multiple-control">
                                                                <div *ngIf="select_person_county.length > 0">
                                                                    <span class="custom-chip"
                                                                        *ngFor="let item of select_person_county">
                                                                        <i class="pg pg-close"
                                                                            (click)="removePersonCounty(item)"></i>
                                                                        {{ item }}
                                                                    </span>
                                                                </div>
                                                                <app-autocomplete [(ngModel)]="person_county"
                                                                    [getValue]="true" [removeValue]="true"
                                                                    [callback]="county_autocomplete" name="person_county"
                                                                    (typeaheadOnSelect)="selectPersonCounty($event)">
                                                                </app-autocomplete>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'person_metro_areas'">
                                                            <label>Metro Areas <i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                            <div class="multiple-control custom-m-parent">
                                                                <div *ngIf="select_person_metro_area.length > 0">
                                                                    <span class="custom-chip"
                                                                        *ngFor="let item of select_person_metro_area">
                                                                        <i class="pg pg-close"
                                                                            (click)="removePersonMetroArea(item)"></i>
                                                                        {{ item }}
                                                                    </span>
                                                                </div>
                                                                <app-autocomplete [(ngModel)]="person_metro_area"
                                                                    [getValue]="true"
                                                                    [callback]="person_metro_area_autocomplete"
                                                                    name="person_metro_area_autocomplete"
                                                                    class="custom-m-autocomplete"
                                                                    (typeaheadOnMultipleSelect)="selectPersonMetroArea($event)"
                                                                    [isMultiple]="true" placeholder="Metro Area Name"
                                                                    [autoSelectionData]="autoSelectionPersonMetroArea"
                                                                    (change)="changePersonMetroAreaContain($event)"
                                                                    (keyup.enter)="keyUpPersonMetroAreaContain($event)">
                                                                </app-autocomplete>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'person_city'">
                                                            <label>City <i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                            <div class="input-group transparent input-align">
                                                                <app-autocomplete [(ngModel)]="search.person_city"
                                                                    [getValue]="true" [callback]="city_name_autocomplete"
                                                                    (typeaheadOnSelect)="selectPersonCity($event)"
                                                                    placeholder="City Name" name="person_city_autocomplete"
                                                                    class="c-bulk-upload">
                                                                </app-autocomplete>
    
                                                                <div class="input-group-append">
                                                                    <span class="input-group-text transparent"><a
                                                                            href="javascript:void(0);"
                                                                            (click)="handleOpenBulkModal(searchVariables.bulk_person_city)"
                                                                            class="upd-crd"><i
                                                                                class="fas fa-cloud-upload-alt"
                                                                                aria-hidden="true"></i></a>
                                                                    </span>
                                                                </div>
    
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'person_zip'">
                                                            <label>Zip <i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                            <div class="input-group transparent input-align">
                                                                <pg-tag-control [(ngModel)]="person_zip_code"
                                                                    name="person_zip_code"
                                                                    class="multi_zip_code c-bulk-upload"
                                                                    (keypress)="handleKeyEvent($event)"></pg-tag-control>
    
                                                                <div class="input-group-append">
                                                                    <span class="input-group-text transparent"><a
                                                                            href="javascript:void(0);"
                                                                            (click)="handleOpenBulkModal(searchVariables.bulk_person_zip)"
                                                                            class="upd-crd"><i
                                                                                class="fas fa-cloud-upload-alt"
                                                                                aria-hidden="true"></i></a>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'person_distance_of_zip'">
                                                            <label>Distance of zip <i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                            <input type="text" *ngIf="!person_zip_code.length"
                                                                class="form-control" (ngModelChange)="modelChanged()"
                                                                disabled>
                                                            <pg-select style="width: 100%;" [Mode]="'single'"
                                                                [PlaceHolder]="'Select'"
                                                                [(ngModel)]="search.person_distance_of_zip"
                                                                class="ml-2 mr-1" [NotFoundContent]="'Not found'"
                                                                name="person_dis_zip_code"
                                                                *ngIf="person_zip_code.length && (!user?.basic_access || !user?.basic_access_advizor)"
                                                                (ngModelChange)="modelChanged()">
                                                                <pg-option *ngFor="let option of dis_zip_code_option"
                                                                    [Label]="option.label" [Value]="option.value"
                                                                    [Disabled]="option.disabled">
                                                                </pg-option>
                                                            </pg-select>
                                                            <input type="text"
                                                                *ngIf="(user?.basic_access && user?.basic_access_advizor)"
                                                                class="form-control" (click)="onInputClick($event)">
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'remove_non_advisor_individuals'">
                                                            <label class="">Remove Non-Advisor Individuals&nbsp;<i
                                                                    *ngIf="tooltipList['remove_non_advisor_individuals']"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{tooltipList['remove_non_advisor_individuals']}}"></i>
                                                            </label>
                                                            <div class="row">
                                                                <div class="col-lg-12">
                                                                    <pg-select style="width: 100%;" [Mode]="'multiple'"
                                                                        [PlaceHolder]="'Select'"
                                                                        [(ngModel)]="search.remove_known_non_advisor_individual"
                                                                        [NotFoundContent]="'Not found'"
                                                                        name="remove_known_non_advisor_individual"
                                                                        [AllowClear]="'true'"
                                                                        (ngModelChange)="modelChanged()"
                                                                        [disabled]="hasNonAdvisorAccess">
                                                                        <pg-option [Label]="'Yes'" [Value]="1"></pg-option>
                                                                        <pg-option [Label]="'No'" [Value]="0"></pg-option>
                                                                    </pg-select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'securities_registered'">
                                                            <label class="d-flex">Securities Registered&nbsp;<i
                                                                    *ngIf="tooltipList['securities_registered']"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{tooltipList['securities_registered']}}"></i></label>
                                                            <div class="row">
                                                                <div class="col-lg-12">
                                                                    <pg-select style="width: 100%;" [Mode]="'single'"
                                                                        [PlaceHolder]="'Select'"
                                                                        [(ngModel)]="search.securities_registered"
                                                                        [NotFoundContent]="'Not found'"
                                                                        name="securities_registered" [AllowClear]="'true'"
                                                                        (ngModelChange)="modelChanged()">
                                                                        <pg-option [Label]="'Yes'" [Value]="1"></pg-option>
                                                                        <pg-option [Label]="'No'" [Value]="0"></pg-option>
                                                                    </pg-select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </div>
                                            </nz-collapse-panel>
                                        </nz-collapse>
                                        <ng-template #openPanel>
                                            <span><i class="fa fa-chevron-down"></i></span>
                                        </ng-template>
                                        <ng-template #closePanel>
                                            <span><i class="fa fa-chevron-up"></i></span>
                                        </ng-template>
                                    </div>
                                    <div class="col-lg-12">
                                        <label class="mt-4 mb-2 panelCategory"
                                            *ngIf="!onlyInsuranceAccess && hasCompanyFilters && panels['Companies']?.length > 0">Companies
                                        </label>
                                        <nz-collapse>
                                            <nz-collapse-panel *ngFor="let panel of panels['Companies']; trackBy: trackByFn; let i = index"
                                                [nzHeader]="panel[0]['category']"
                                                [nzActive]="(panel[0]['shouldOpenPanel'] && i === panel[0]['indexOfPanelToOpen'])"
                                                (nzActiveChange)="panelClick(i,'company')"
                                                [ngClass]="[(panel[0]['filterCount']?'active_cat':'' )]"
                                                [nzExtra]="panel[0]['filterCount']?panel[0]['filterCount']:panel[0]['shouldOpenPanel'] ?closePanel:openPanel">
                                                <div class="col-lg-12 p-0 form-group" *ngFor="let cat_value of panel">
                                                    <ng-container *ngIf="cat_value?.search_field?.length > 0 && !hasStaticField(cat_value); else elseComField">
                                                        <div *ngIf="inputFilterAccess(cat_value.field_id)">
                                                            <ng-container *ngTemplateOutlet="commonTemplate; context: {data: cat_value}"></ng-container>
                                                        </div>
                                                    </ng-container>
                                                    <ng-template #elseComField>
                                                        <div *ngIf="cat_value.field_id == 'ticker'">
                                                            <label>Ticker <i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i>&nbsp;<nz-switch
                                                                    [(ngModel)]="tickerFilterSwitch"
                                                                    name="tickerFilterSwitch" nzSize="small"
                                                                    nzCheckedChildren="AND" nzUnCheckedChildren="OR"
                                                                    (ngModelChange)="modelChanged()"></nz-switch></label>
                                                            <div class="multiple-control d-flex">
                                                                <div class="input-group">
                                                                    <div *ngIf="select_ticker.length > 0">
                                                                        <span class="custom-chip"
                                                                            *ngFor="let item of select_ticker">
                                                                            <i class="pg pg-close"
                                                                                (click)="removeTicker(item)"></i>
                                                                            {{ item }}
                                                                        </span>
                                                                    </div>
                                                                    <div class="input-group transparent">
                                                                        <app-autocomplete [(ngModel)]="ticker"
                                                                            [getValue]="true" [removeValue]="true"
                                                                            [callback]="ticker_autocomplete" name="ticker"
                                                                            (typeaheadOnSelect)="selectTicker($event)"
                                                                            (typeaheadOnBlur)="keyUpTicker($event)"
                                                                            [isEnter]="true" class="c-bulk-upload">
                                                                        </app-autocomplete>
                                                                    </div>
                                                                </div>
                                                                <div class="input-group-append border-l">
                                                                    <span class="input-group-text transparent"
                                                                        style="border: none !important;"><a
                                                                            href="javascript:void(0);"
                                                                            (click)="handleOpenBulkModal(searchVariables.bulk_ticker)"
                                                                            class="upd-crd"><i
                                                                                class="fas fa-cloud-upload-alt"
                                                                                aria-hidden="true"></i></a>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'value'">
                                                            <label>Value ($) 
                                                                <ng-container *ngIf="isTickerDisable('ms'); else elseTicker">
                                                                    <i 
                                                                        class="fa fa-question-circle" 
                                                                        nz-tooltip 
                                                                        [nzTooltipColor]="tooltipColor" 
                                                                        [nzTooltipTitle]="'It is disabled because the related filters — Ticker, Cusip, and Morningstar Category — are not selected.'">
                                                                    </i>
                                                                </ng-container>

                                                                <ng-template #elseTicker>
                                                                    <i 
                                                                        *ngIf="cat_value.tooltip" 
                                                                        class="fa fa-question-circle" 
                                                                        nz-tooltip 
                                                                        [nzTooltipColor]="tooltipColor" 
                                                                        [nzTooltipTitle]="cat_value.tooltip">
                                                                    </i>
                                                                </ng-template>
                                                            </label>
                                                            <div class="row custom-min-max">
                                                                <div class="col-lg-12">
                                                                    <div class="input-group transparent">
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text transparent"><i
                                                                                    class="fas fa-dollar-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" name="min_holdings_value"
                                                                            [(ngModel)]="search.min_holdings_value"
                                                                            class="form-control" placeholder="Min"
                                                                            [textMask]="{mask: numberMask}"
                                                                            [disabled]="isTickerDisable('ms')"
                                                                            (ngModelChange)="containChanged()">
                                                                    </div>
                                                                </div>
    
                                                                <div class="col-lg-12 mt-2">
                                                                    <div class="input-group transparent">
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text transparent"><i
                                                                                    class="fas fa-dollar-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" name="max_holdings_value"
                                                                            [(ngModel)]="search.max_holdings_value"
                                                                            class="form-control" placeholder="Max"
                                                                            [textMask]="{mask: numberMask}"
                                                                            [disabled]="isTickerDisable('ms')"
                                                                            (ngModelChange)="containChanged()">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'number_of_shares'">
                                                            <label>Number Of Shares 
                                                                <ng-container *ngIf="isTickerDisable(); else elseTicker">
                                                                    <i 
                                                                        class="fa fa-question-circle" 
                                                                        nz-tooltip 
                                                                        [nzTooltipColor]="tooltipColor" 
                                                                        [nzTooltipTitle]="'It is disabled because the related filters — Ticker, and Cusip — are not selected.'">
                                                                    </i>
                                                                </ng-container>

                                                                <ng-template #elseTicker>
                                                                    <i 
                                                                        *ngIf="cat_value.tooltip" 
                                                                        class="fa fa-question-circle" 
                                                                        nz-tooltip 
                                                                        [nzTooltipColor]="tooltipColor" 
                                                                        [nzTooltipTitle]="cat_value.tooltip">
                                                                    </i>
                                                                </ng-template>
                                                            </label>
                                                            <div class="row custom-min-max">
                                                                <div class="col-lg-12">
                                                                    <div class="input-group transparent">
                                                                        <input type="text" name="min_holdings_no_of_shares"
                                                                            [(ngModel)]="search.min_holdings_no_of_shares"
                                                                            class="form-control" placeholder="Min"
                                                                            [disabled]="isTickerDisable()"
                                                                            (ngModelChange)="containChanged()">
                                                                    </div>
                                                                </div>
    
                                                                <div class="col-lg-12 mt-2">
                                                                    <div class="input-group transparent">
                                                                        <input type="text" name="max_holdings_no_of_shares"
                                                                            [(ngModel)]="search.max_holdings_no_of_shares"
                                                                            class="form-control" placeholder="Max"
                                                                            [disabled]="isTickerDisable()"
                                                                            (ngModelChange)="containChanged()">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'firm_keyword'">
                                                            <label>Keyword&nbsp;<i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                            &nbsp;
                                                            <pg-select style="width: 100%;" [Mode]="'multiple'"
                                                                [PlaceHolder]="'Select'" [(ngModel)]="search.firm_keywords"
                                                                [NotFoundContent]="'Not found'" name="firm_keywords"
                                                                [disabled]="!search.firm_keyword_category"
                                                                (ngModelChange)="modelChanged()">
                                                                <pg-option
                                                                    *ngFor="let option of filtered_web_advizor_firm_keyword"
                                                                    [Label]="option.keyword_display"
                                                                    [Value]="option.keyword_display"
                                                                    [Disabled]="option.disabled">
                                                                </pg-option>
                                                            </pg-select>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'firm_contain'">
                                                            <div class="row mt-3 mb-4">
                                                                <div class="col-lg-2" *ngIf="checkAccess('firm_contain')">
                                                                    <label>Contains&nbsp;<i *ngIf="cat_value.tooltip"
                                                                            class="fa fa-question-circle" nz-tooltip
                                                                            [nzTooltipColor]="tooltipColor"
                                                                            nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                                </div>
                                                                <div class="col-lg-12 text-right web-serch-checkbox">
                                                                    <pg-select style="width: 100%;" [Mode]="'multiple'"
                                                                        [PlaceHolder]="'Select'"
                                                                        [(ngModel)]="selected_firm_contains"
                                                                        [NotFoundContent]="'Not found'"
                                                                        name="selected_firm_contains"
                                                                        (ngModelChange)="firmContainChange()">
                                                                        <pg-option *ngFor="let option of firm_contains_list"
                                                                            [Label]="option.label" [Value]="option.value"
                                                                            [Disabled]="option.disabled">
                                                                        </pg-option>
                                                                    </pg-select>
                                                                </div>
                                                            </div>
                                                            <input type="text" name="firm_contains"
                                                                [(ngModel)]="search.firm_contains" class="form-control"
                                                                placeholder="Contains" (ngModelChange)="containChanged()">
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'affiliations'">
                                                            <label>Affiliations <i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                            <div class="multiple-control">
                                                                <div *ngIf="select_affiliation.length > 0">
                                                                    <span class="custom-chip"
                                                                        *ngFor="let item of select_affiliation">
                                                                        <i class="pg pg-close"
                                                                            (click)="removeAffiliation(item)"></i>
                                                                        {{ item }}
                                                                    </span>
                                                                </div>
                                                                <app-autocomplete [(ngModel)]="affiliation"
                                                                    [getValue]="true" [removeValue]="true"
                                                                    [callback]="affiliation_autocomplete" name="affiliation"
                                                                    (typeaheadOnSelect)="selectAffiliation($event)"
                                                                    (change)="changeAffiliation($event)">
                                                                </app-autocomplete>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'client_type'">
                                                            <label>Client Type <i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                            <pg-select style="width: 100%;" [Mode]="'multiple'"
                                                                [PlaceHolder]="'Select'" [(ngModel)]="search.client_type"
                                                                [NotFoundContent]="'Not found'" name="client_type"
                                                                (ngModelChange)="modelChanged()">
                                                                <pg-option *ngFor="let option of client_types"
                                                                    [Label]="checkClientLabel(option.label)"
                                                                    [Value]="option.label" [Disabled]="option.disabled">
                                                                </pg-option>
                                                            </pg-select>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'number_of_clients'" class="mt-2">
                                                            <label>Num of Clients <i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                            <div class="row">
                                                                <div class="col-lg-6">
                                                                    <div class="input-group transparent">
                                                                        <input type="text" name="min_number_of_clients"
                                                                            [(ngModel)]="search.min_number_of_clients"
                                                                            class="form-control" placeholder="Min"
                                                                            (ngModelChange)="containChanged()"
                                                                            [readonly]="search?.client_type?.length !== 1 ? 'true' : null">
                                                                    </div>
                                                                </div>
    
                                                                <div class="col-lg-6">
                                                                    <div class="input-group transparent">
                                                                        <input type="text" name="max_number_of_clients"
                                                                            [(ngModel)]="search.max_number_of_clients"
                                                                            class="form-control" placeholder="Max"
                                                                            (ngModelChange)="containChanged()"
                                                                            [readonly]="search?.client_type?.length !== 1 ? 'true' : null">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'client_type_percentage'">
                                                            <label>Client Type (%) <i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                            <div class="row">
                                                                <div class="col-lg-6">
                                                                    <div class="input-group transparent">
                                                                        <input type="text" name="min_client_type"
                                                                            [(ngModel)]="search.min_client_type"
                                                                            class="form-control" placeholder="Min"
                                                                            (ngModelChange)="containChanged()"
                                                                            [readonly]="search?.client_type?.length !== 1 ? 'true' : null">
                                                                    </div>
                                                                </div>
    
                                                                <div class="col-lg-6">
                                                                    <div class="input-group transparent">
                                                                        <input type="text" name="max_client_type"
                                                                            [(ngModel)]="search.max_client_type"
                                                                            class="form-control" placeholder="Max"
                                                                            (ngModelChange)="containChanged()"
                                                                            [readonly]="search?.client_type?.length !== 1 ? 'true' : null">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'client_type_dollar'">
                                                            <label>Client Type ($) <i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                            <div class="row custom-min-max">
                                                                <div class="col-lg-12">
                                                                    <div class="input-group transparent">
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text transparent"><i
                                                                                    class="fas fa-dollar-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" name="min_client_type_dollar"
                                                                            [(ngModel)]="search.min_client_type_dollar"
                                                                            class="form-control" placeholder="Min"
                                                                            [textMask]="{mask: numberMask}"
                                                                            (ngModelChange)="containChanged()"
                                                                            [readonly]="search?.client_type?.length !== 1 ? 'true' : null">
                                                                    </div>
                                                                </div>
    
                                                                <div class="col-lg-12 mt-2">
                                                                    <div class="input-group transparent">
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text transparent"><i
                                                                                    class="fas fa-dollar-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" name="max_client_type_dollar"
                                                                            [(ngModel)]="search.max_client_type_dollar"
                                                                            class="form-control" placeholder="Max"
                                                                            [textMask]="{mask: numberMask}"
                                                                            (ngModelChange)="containChanged()"
                                                                            [readonly]="search?.client_type?.length !== 1 ? 'true' : null">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'average_client_type_dollar'">
                                                            <label>Average Client Type ($) <i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                            <div class="row custom-min-max">
                                                                <div class="col-lg-12">
                                                                    <div class="input-group transparent">
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text transparent"><i
                                                                                    class="fas fa-dollar-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" name="min_avg_client_type"
                                                                            [(ngModel)]="search.min_avg_client_type"
                                                                            class="form-control" placeholder="Min"
                                                                            [textMask]="{mask: numberMask}"
                                                                            (ngModelChange)="containChanged()"
                                                                            [readonly]="search?.client_type?.length !== 1 ? 'true' : null">
                                                                    </div>
                                                                </div>
    
                                                                <div class="col-lg-12 mt-2">
                                                                    <div class="input-group transparent">
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text transparent"><i
                                                                                    class="fas fa-dollar-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" name="max_avg_client_type"
                                                                            [(ngModel)]="search.max_avg_client_type"
                                                                            class="form-control" placeholder="Max"
                                                                            [textMask]="{mask: numberMask}"
                                                                            (ngModelChange)="containChanged()"
                                                                            [readonly]="search?.client_type?.length !== 1 ? 'true' : null">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'custodian_clearing_firm'"
                                                            class="custom-m-parent">
                                                            <label>Custodian/Clearing Firm <i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i>
                                                                <nz-switch [(ngModel)]="custodian_option"
                                                                    name="custodian_option" nzSize="small"
                                                                    nzCheckedChildren="Includes"
                                                                    nzUnCheckedChildren="Excludes"
                                                                    (ngModelChange)="modelChanged()"></nz-switch></label>
                                                            <div class="multiple-control">
                                                                <div *ngIf="select_custodian.length > 0">
                                                                    <span class="custom-chip"
                                                                        *ngFor="let item of select_custodian">
                                                                        <i class="pg pg-close"
                                                                            (click)="removeCustodian(item)"></i>
                                                                        {{ item }}
                                                                    </span>
                                                                </div>
                                                                <app-autocomplete [(ngModel)]="custodian" [getValue]="true"
                                                                    [removeValue]="true" [callback]="custodian_autocomplete"
                                                                    name="custodian" class="custom-m-autocomplete"
                                                                    (typeaheadOnMultipleSelect)="selectCustodian($event)"
                                                                    [isMultiple]="true" (change)="changeCustodian($event)"
                                                                    [autoSelectionData]="autoSelectionCustodian"
                                                                    (keyup.enter)="keyUpCustodianContain($event)"
                                                                    [typeaheadOptionsLimit]="125"
                                                                    >
                                                                </app-autocomplete>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'assets_held_by_custodian'">
                                                                <label class="">Assets held&nbsp;<i
                                                                       *ngIf="cat_value.tooltip" class="fa fa-question-circle"
                                                                        nz-tooltip [nzTooltipColor]="tooltipColor"
                                                                        nzTooltipTitle="{{cat_value.tooltip}}"></i>
                                                                </label>
                                                                <div class="row">
                                                                        <div class="col-lg-6">
                                                                                <input type="text" name="min_assets_held_by_custodian"
                                                                                        [(ngModel)]="search.min_assets_held_by_custodian"
                                                                                        (ngModelChange)="containChanged()"
                                                                                        class="form-control" placeholder="Min"
                                                                                        [textMask]="{mask: numberMask}"
                                                                                        [disabled]="select_custodian.length !== 1">
                                                                        </div>
                                                                        <div class="col-lg-6">
                                                                                <input type="text" name="max_assets_held_by_custodian"
                                                                                        [(ngModel)]="search.max_assets_held_by_custodian"
                                                                                        (ngModelChange)="containChanged()"
                                                                                        class="form-control" placeholder="Max"
                                                                                        [textMask]="{mask: numberMask}"
                                                                                        [disabled]="select_custodian.length !== 1">
                                                                        </div>
                                                                </div>
                                                        </div>

                                                        <div *ngIf="cat_value.field_id == 'assets_held_by_custodian_pct'">
                                                            <label class="">Assets held Percent&nbsp;<i
                                                                   *ngIf="cat_value.tooltip" class="fa fa-question-circle"
                                                                    nz-tooltip [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i>
                                                            </label>
                                                            <div class="row">
                                                                    <div class="col-lg-6">
                                                                            <input type="text" name="min_assets_held_by_custodian_pct"
                                                                                    [(ngModel)]="search.min_assets_held_by_custodian_pct"
                                                                                    (ngModelChange)="containChanged()"
                                                                                    class="form-control" placeholder="Min"
                                                                                    [textMask]="{mask: numberMask}"
                                                                                    [disabled]="select_custodian.length !== 1">
                                                                    </div>
                                                                    <div class="col-lg-6">
                                                                            <input type="text" name="max_assets_held_by_custodian_pct"
                                                                                    [(ngModel)]="search.max_assets_held_by_custodian_pct"
                                                                                    (ngModelChange)="containChanged()"
                                                                                    class="form-control" placeholder="Max"
                                                                                    [textMask]="{mask: numberMask}"
                                                                                    [disabled]="select_custodian.length !== 1">
                                                                    </div>
                                                            </div>
                                                    </div>

                                                        <div *ngIf="cat_value.field_id == 'wealth_creator'"
                                                            class="custom-m-parent">
                                                            <label>Wealth Creator <i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                            <div class="multiple-control">
                                                                <div *ngIf="select_wealth_creator.length > 0">
                                                                    <span class="custom-chip"
                                                                        *ngFor="let item of select_wealth_creator">
                                                                        <i class="pg pg-close"
                                                                            (click)="removeWealthCreator(item)"></i>
                                                                        {{ item }}
                                                                    </span>
                                                                </div>
                                                                <app-autocomplete [(ngModel)]="wealth_creator"
                                                                    [getValue]="true"
                                                                    [callback]="wealth_creator_autocomplete"
                                                                    name="wealth_creator_autocomplete"
                                                                    class="custom-m-autocomplete"
                                                                    (typeaheadOnMultipleSelect)="selectWealthCreator($event)"
                                                                    [isMultiple]="true" placeholder="Wealth Creator"
                                                                    [autoSelectionData]="autoSelectionWealthCreator"
                                                                    (change)="changeWealthCreatorContain($event)"
                                                                    (keyup.enter)="keyUpWealthCreatorContain($event)">
                                                                </app-autocomplete>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'firm_established'">
                                                            <label>Firm Established <i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                            <div class="row">
                                                                <div class="col-lg-6">
                                                                    <div pgFormGroupDefault class="calendar-input">
                                                                        <nz-date-picker
                                                                            [(ngModel)]="search.start_date_establish_date"
                                                                            name="start_date_establish_date"
                                                                            (ngModelChange)="startDateChange($event)"
                                                                            [Placeholder]="'Start date'"></nz-date-picker>
                                                                        <br />
                                                                    </div>
                                                                </div>
    
                                                                <div class="col-lg-6">
                                                                    <div pgFormGroupDefault class="calendar-input">
                                                                        <nz-date-picker
                                                                            [(ngModel)]="search.end_date_establish_date"
                                                                            name="end_date_establish_date"
                                                                            [Placeholder]="'End date'"
                                                                            (ngModelChange)="endDateChange($event)"></nz-date-picker>
                                                                        <br />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'sec_registration'">
                                                            <label>SEC Registration Date <i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                            <div class="row">
                                                                <div class="col-lg-6">
                                                                    <div pgFormGroupDefault class="calendar-input">
                                                                        <nz-date-picker
                                                                            [(ngModel)]="search.start_date_sec_registration"
                                                                            name="start_date_sec_registration"
                                                                            (ngModelChange)="startDateSecRegChange($event)"
                                                                            class="w-100"
                                                                            [Placeholder]="'Start date'"></nz-date-picker>
                                                                        <br />
                                                                    </div>
                                                                </div>
    
                                                                <div class="col-lg-6">
                                                                    <div pgFormGroupDefault class="calendar-input">
                                                                        <nz-date-picker
                                                                            [(ngModel)]="search.end_date_sec_registration"
                                                                            name="end_date_sec_registration"
                                                                            [Placeholder]="'End date'"
                                                                            (ngModelChange)="endDateSecRegChange($event)"
                                                                            class="w-100"></nz-date-picker>
                                                                        <br />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'firm_city'">
                                                            <label>Firm City <i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                            <div class="input-group transparent">
                                                                <app-autocomplete [(ngModel)]="search.city"
                                                                    (typeaheadOnSelect)="selectFirmCity($event)"
                                                                    [getValue]="true" [callback]="city_name_autocomplete"
                                                                    placeholder="City Name" name="city_autocomplete"
                                                                    class="c-bulk-upload">
                                                                </app-autocomplete>
    
                                                                <div class="input-group-append">
                                                                    <span class="input-group-text transparent"><a
                                                                            href="javascript:void(0);"
                                                                            (click)="handleOpenBulkModal(searchVariables.bulk_firm_city)"
                                                                            class="upd-crd"><i
                                                                                class="fas fa-cloud-upload-alt"
                                                                                aria-hidden="true"></i></a>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'firm_metro_areas'">
    
                                                            <label>Metro Areas <i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                            <div class="multiple-control custom-m-parent">
                                                                <div *ngIf="select_metro_area.length > 0">
                                                                    <span class="custom-chip"
                                                                        *ngFor="let item of select_metro_area">
                                                                        <i class="pg pg-close"
                                                                            (click)="removeMetroArea(item)"></i>
                                                                        {{ item }}
                                                                    </span>
                                                                </div>
                                                                <app-autocomplete [(ngModel)]="metro_area" [getValue]="true"
                                                                    [callback]="metro_area_autocomplete"
                                                                    name="metro_area_autocomplete"
                                                                    class="custom-m-autocomplete"
                                                                    (typeaheadOnMultipleSelect)="selectMetroArea($event)"
                                                                    [isMultiple]="true" placeholder="Metro Area Name"
                                                                    [autoSelectionData]="autoSelectionMetroArea"
                                                                    (change)="changeMetroAreaContain($event)"
                                                                    (keyup.enter)="keyUpMetroAreaContain($event)">
                                                                </app-autocomplete>
                                                            </div>
    
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'firm_zip'">
                                                            <label>Firm Zip <i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                            <div class="input-group transparent">
                                                                <pg-tag-control [(ngModel)]="zip_code" name="zip_code"
                                                                    class="multi_zip_code c-bulk-upload"
                                                                    (keypress)=" handleKeyEvent($event)"></pg-tag-control>
    
                                                                <div class="input-group-append">
                                                                    <span class="input-group-text transparent"><a
                                                                            href="javascript:void(0);"
                                                                            (click)="handleOpenBulkModal(searchVariables.bulk_firm_zip)"
                                                                            class="upd-crd"><i
                                                                                class="fas fa-cloud-upload-alt"
                                                                                aria-hidden="true"></i></a>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'distance_of_zip'">
                                                            <label>Distance of zip <i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                            <input type="text" *ngIf="!zip_code.length" class="form-control"
                                                                disabled>
                                                            <pg-select style="width: 100%;" [Mode]="'single'"
                                                                [PlaceHolder]="'Select'"
                                                                [(ngModel)]="search.distance_of_zip"
                                                                [NotFoundContent]="'Not found'" name="dis_zip_code"
                                                                *ngIf="zip_code.length && (!user?.basic_access || !user?.basic_access_advizor)"
                                                                class="ml-2" (ngModelChange)="modelChanged()" Disabled>
                                                                <pg-option *ngFor="let option of dis_zip_code_option"
                                                                    [Label]="option.label" [Value]="option.value"
                                                                    [Disabled]="option.disabled">
                                                                </pg-option>
                                                            </pg-select>
                                                            <input type="text"
                                                                *ngIf="(user?.basic_access && user?.basic_access_advizor)"
                                                                class="form-control" (click)="onInputClick($event)">
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'firm_county'">
                                                            <label>Firm County <i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                            <div class="multiple-control">
                                                                <div *ngIf="select_county.length > 0">
                                                                    <span class="custom-chip"
                                                                        *ngFor="let item of select_county">
                                                                        <i class="pg pg-close"
                                                                            (click)="removeCounty(item)"></i>
                                                                        {{ item }}
                                                                    </span>
                                                                </div>
                                                                <app-autocomplete [(ngModel)]="county" [getValue]="true"
                                                                    [removeValue]="true" [callback]="county_autocomplete"
                                                                    name="county"
                                                                    (typeaheadOnSelect)="selectCounty($event)">
                                                                </app-autocomplete>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'remove_non_advisor_firms'">
                                                            <label class="">Remove Non-Advisor Firms&nbsp;<i
                                                                    *ngIf="tooltipList['remove_non_advisor_firms']"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{tooltipList['remove_non_advisor_firms']}}"></i>
                                                            </label>
                                                            <div class="row">
                                                                <div class="col-lg-12">
                                                                    <pg-select style="width: 100%;" [Mode]="'multiple'"
                                                                        [PlaceHolder]="'Select'"
                                                                        [(ngModel)]="search.known_non_advisor"
                                                                        [NotFoundContent]="'Not found'"
                                                                        name="known_non_advisor" [AllowClear]="'true'"
                                                                        (ngModelChange)="modelChanged()"
                                                                        [disabled]="hasNonAdvisorAccess">
                                                                        <pg-option [Label]="'Yes'" [Value]="1"></pg-option>
                                                                        <pg-option [Label]="'No'" [Value]="0"></pg-option>
                                                                    </pg-select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'firm_name'">
                                                            <label>Firm Name <i *ngIf="tooltipList['firm_name']"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{tooltipList['firm_name']}}"></i></label>
                                                            <pg-select style="width: 100%;" [getValue]="true"
                                                                [Mode]="'single'" [PlaceHolder]="'Select'"
                                                                [(ngModel)]="firm_name_search"
                                                                [NotFoundContent]="'Not found'" name="firm_name_search"
                                                                (ngModelChange)="firmModelChanged()" nz-tooltip
                                                                [nzTooltipColor]="tooltipColor"
                                                                nzTooltipTitle="{{tooltipList['search_firm_contain']}}">
                                                                <pg-option *ngFor="let option of myAdvizorOption"
                                                                    [Label]="option" [Value]="option"></pg-option>
                                                            </pg-select>
    
                                                            <div class="multiple-control cus-multiple-control mt-2">
                                                                <div *ngIf="select_firm_name.length > 0">
                                                                    <span class="custom-chip"
                                                                        *ngFor="let item of select_firm_name">
                                                                        <i class="pg pg-close"
                                                                            (click)="removeFirmName(item)"></i>
                                                                        {{ item }}
                                                                    </span>
                                                                </div>
    
                                                                <app-autocomplete [(ngModel)]="firm_name" [getValue]="true"
                                                                    [removeValue]="true"
                                                                    [autoSelectionData]="autoSelectionFirm"
                                                                    [callback]="firm_name_autocomplete"
                                                                    name="firm_autocomplete"
                                                                    (change)="changeFirmName($event)" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{tooltipList['search_firm_name']}}"
                                                                    [placeholder]="'Firms Name'"
                                                                    [typeaheadItemTemplate]="typeaheadFrimTemplate"
                                                                    typeaheadOptionField="name"
                                                                    (typeaheadOnSelect)="selectFirmName($event)"
                                                                    (keyup.enter)="keyUpFirmName($event)">
                                                                </app-autocomplete>
                                                                <ng-template #typeaheadFrimTemplate let-model="item"
                                                                    let-index="index">
                                                                    <div class="row pt-2">
                                                                        <div class="col-1-5 ml-2 d-flex align-items-center">
                                                                            <div class="row c-row">
                                                                                <label
                                                                                    (click)="preFirmEvent($event, model)">
                                                                                    <input type="checkbox"
                                                                                        name="typeheadfirmName[]"
                                                                                        [value]="model?.crd"
                                                                                        [checked]="firm_checkbox.includes(model.name)?true:false"
                                                                                        class="mr-1">
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-1-5">
                                                                            <div class="circle-adv mt-2"
                                                                                style="display: grid">
                                                                                <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({
                                                                                    'advizor': model?.advizor,
                                                                                    'broker': model?.broker,
                                                                                    'insurance': model?.insurance && user.has_access_insurance,
                                                                                    'fo': model?.fo_id && user.has_access_family_office,
                                                                                    'bt': model?.bt_id && user.has_access_bank_and_trust,
                                                                                    'dc': model?.dc && user.has_access_defined_contribution,
                                                                                    'db': model?.db && user.has_access_defined_benefit,
                                                                                })" />
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-5 r-bor pl-0">
                                                                            <div class="row">
                                                                                <div class="col-12">
                                                                                    <div class="col-12 pl-0 bold">
                                                                                        <span class="fs-12"
                                                                                            style="white-space: normal;"> {{
                                                                                            model.name
                                                                                            }} </span>
                                                                                    </div>
                                                                                    <div class="col-12 pl-0">
                                                                                        <span class="fs-10"
                                                                                            style="white-space: normal;"> {{
                                                                                            model.city_state }} </span>
                                                                                    </div>
                                                                                    <div class="col-12 pl-0">
                                                                                        <span *ngIf="model.txt_crd"
                                                                                            class="fs-10 inline"
                                                                                            style="white-space: normal;">CRD:
                                                                                            {{ model.txt_crd
                                                                                            }}{{(model.txt_npn ?
                                                                                            ',':'')}}&nbsp;</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-5 r-item">
                                                                            <div class="row pl-2">
                                                                                <div class="col-12 pl-0" *ngIf="model?.aum">
                                                                                    <i
                                                                                        class="fas fa-dollar-sign color-grn mr-2"></i>
                                                                                    <span class="inline"
                                                                                        style="white-space: normal;"
                                                                                        *ngIf="model?.aum">{{ (model?.aum |
                                                                                        currency:'USD':'symbol':'1.0-2')+'
                                                                                        M'
                                                                                        }}</span>
                                                                                </div>
                                                                                <div class="col-12 pl-0">
                                                                                    <i
                                                                                        class="far fa-user-circle text-danger mr-2"></i>
                                                                                    <span class="inline"
                                                                                        style="white-space: normal;"> {{
                                                                                        model.persons | number : 0 }}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ng-template>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'allocate_external_managers'">
                                                            <div class="checkbox" nz-tooltip [nzTooltipColor]="tooltipColor"
                                                                nzTooltipTitle="{{cat_value.allocate_external_managers}}">
                                                                <input type="checkbox" name="allocateExternalManagers" [(ngModel)]="allocateExternalManagers" (ngModelChange)="modelChanged()" id="checkbox_allocate_external_managers">
                                                                <label for="checkbox_allocate_external_managers" class="m-0">Allocates to External Managers
                                                                    <i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <!-- Bank & Trust -->
                                                        <div *ngIf="cat_value.field_id == 'bt_trust_services'">
                                                            <div class="checkbox" nz-tooltip [nzTooltipColor]="tooltipColor"
                                                                nzTooltipTitle="{{cat_value.bt_trust_services}}">
                                                                <input type="checkbox" name="trustServices"
                                                                    [(ngModel)]="trustServices"
                                                                    (ngModelChange)="modelChanged()"
                                                                    id="checkbox_bt_trust_services">
                                                                <label for="checkbox_bt_trust_services" class="m-0">Trust
                                                                    Services
                                                                    <i *ngIf="cat_value.tooltip"
                                                                        class="fa fa-question-circle" nz-tooltip
                                                                        [nzTooltipColor]="tooltipColor"
                                                                        nzTooltipTitle="{{cat_value.tooltip}}"></i>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'bt_trust_teams'">
                                                            <div class="checkbox" nz-tooltip [nzTooltipColor]="tooltipColor"
                                                                nzTooltipTitle="{{cat_value.bt_trust_teams}}">
                                                                <input type="checkbox" name="trustTeams" [(ngModel)]="trustTeams" (ngModelChange)="modelChanged()" id="checkbox_bt_trust_teams">
                                                                <label for="checkbox_bt_trust_teams" class="m-0">Trust Team
                                                                    <i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'team_state'">
                                                            <label>Team State&nbsp;<i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                            <pg-select style="width: 100%;" [Mode]="'multiple'"
                                                                [PlaceHolder]="'Select'" [(ngModel)]="search.team_state"
                                                                [NotFoundContent]="'Not found'" name="team_state"
                                                                (ngModelChange)="modelChanged()"> 
                                                                <pg-option *ngFor="let option of states"
                                                                    [Label]="option.value" [Value]="option.id"
                                                                    [Disabled]="option.disabled">
                                                                </pg-option>
                                                            </pg-select>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'team_city'">
                                                            <label>Team City&nbsp;<i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                            <div class="input-group transparent input-align">
                                                                <app-autocomplete [(ngModel)]="search.team_city"
                                                                    [getValue]="true" [callback]="city_name_autocomplete"
                                                                    (typeaheadOnSelect)="selectTeamCity($event)"
                                                                    placeholder="City Name" name="team_city_autocomplete"
                                                                    class="c-bulk-upload">
                                                                </app-autocomplete>
    
                                                                <div class="input-group-append">
                                                                    <span class="input-group-text transparent"><a
                                                                            href="javascript:void(0);"
                                                                            (click)="handleOpenBulkModal(searchVariables.bulk_team_city)"
                                                                            class="upd-crd"><i
                                                                                class="fas fa-cloud-upload-alt"
                                                                                aria-hidden="true"></i></a>
                                                                    </span>
                                                                </div>
    
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'team_zip'">
                                                            <label>Team Zip&nbsp;<i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                            <div class="input-group transparent input-align">
                                                                <pg-tag-control [(ngModel)]="team_zip_code"
                                                                    name="team_zip_code"
                                                                    class="multi_zip_code c-bulk-upload"
                                                                    (keypress)="handleKeyEvent($event)"></pg-tag-control>
    
                                                                <div class="input-group-append">
                                                                    <span class="input-group-text transparent"><a
                                                                            href="javascript:void(0);"
                                                                            (click)="handleOpenBulkModal(searchVariables.bulk_team_zip)"
                                                                            class="upd-crd"><i
                                                                                class="fas fa-cloud-upload-alt"
                                                                                aria-hidden="true"></i></a>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="cat_value.field_id == 'team_metro_area'">
                                                            <label>Team Metro Area&nbsp;<i *ngIf="cat_value.tooltip"
                                                                    class="fa fa-question-circle" nz-tooltip
                                                                    [nzTooltipColor]="tooltipColor"
                                                                    nzTooltipTitle="{{cat_value.tooltip}}"></i></label>
                                                            <div class="multiple-control custom-m-parent">
                                                                <div *ngIf="select_team_metro_area.length > 0">
                                                                    <span class="custom-chip"
                                                                        *ngFor="let item of select_team_metro_area">
                                                                        <i class="pg pg-close"
                                                                            (click)="removeTeamMetroArea(item)"></i>
                                                                        {{ item }}
                                                                    </span>
                                                                </div>
                                                                <app-autocomplete [(ngModel)]="team_metro_area"
                                                                    [getValue]="true"
                                                                    [callback]="team_metro_area_autocomplete"
                                                                    name="team_metro_area_autocomplete"
                                                                    class="custom-m-autocomplete"
                                                                    (typeaheadOnMultipleSelect)="selectTeamMetroArea($event)"
                                                                    [isMultiple]="true" placeholder="Metro Area Name"
                                                                    [autoSelectionData]="autoSelectionTeamMetroArea"
                                                                    (change)="changeTeamMetroAreaContain($event)"
                                                                    (keyup.enter)="keyUpTeamMetroAreaContain($event)">
                                                                </app-autocomplete>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </div>
                                            </nz-collapse-panel>
                                        </nz-collapse>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-9 pl-0">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card card-default adv-box-shadow">
                        <div class="card-body pl-0 pr-0">
                            <nz-tabset class="search-tab" [nzTabBarExtraContent]="extraTemplate">
                                <nz-tab [nzTitle]="titleTemplate" (nzSelect)="changeTab('person')" [nzSize]="'large'">
                                    <ng-template #titleTemplate>
                                        <b class="fs-12 dm-sans-bold">Contacts</b> <ng-container
                                            *ngIf="personLoading; else elseNotDone">
                                            <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
                                        </ng-container>
                                        <ng-template #elseNotDone>
                                            <b class="fs-12 dm-sans-bold"> &nbsp;({{search_result?.person?.total |
                                                number : 0}})</b>

                                        </ng-template>
                                    </ng-template>

                                    <div class="row">
                                        <div class="col-lg-11 ml-1 note-field" *ngIf="filter_count > 0">
                                            <div class="ml-4">
                                                <div class="search-tag d-inline">
                                                    <ng-container *ngTemplateOutlet="tagContentTemplate"></ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row m-0">
                                        <div class="col-lg-12 mb-3">
                                            <div class="table-responsive" style="min-height:150px;">
                                                <ng-container *ngIf="personLoading; else personLd">
                                                    <nz-spin nzTip="Loading..." class="tbl-loader">
                                                        <ng-template #personDatatblLoad></ng-template>
                                                    </nz-spin>
                                                </ng-container>
                                                <ng-template #personLd>
                                                    <ng-template #personDatatblLoad></ng-template>
                                                </ng-template>
                                                <ngx-datatable
                                                    class='table table-hover advisor-search-tbl material table-v2'
                                                    [rows]="search_result?.person?.data"
                                                    [columnMode]="columnModeSetting" [headerHeight]="43"
                                                    [footerHeight]="50" [rowHeight]="'auto'"
                                                    [scrollbarH]="scrollBarHorizontal" [externalPaging]="true"
                                                    [count]="search_result?.person?.total"
                                                    [offset]="paginate.pageNumber" [limit]="paginate.limit"
                                                    (page)='sortDataPerson($event)' [externalSorting]="true"
                                                    (sort)="onSortPerson($event)" #personDatatblLoad>

                                                    <ngx-datatable-column [minWidth]="20" [sortable]="false"
                                                        [draggable]="false" [resizeable]="false">
                                                        <ng-template ngx-datatable-header-template let-value="value">
                                                            <input type="checkbox" [checked]="defCheck"
                                                                #select_all_checkbox
                                                                (change)="searchTicker($event.target.checked)" />
                                                        </ng-template>
                                                        <ng-template ngx-datatable-cell-template let-row="row"
                                                            let-value="value">
                                                            <input [checked]="row?.checked" type="checkbox"
                                                                (change)="tickerChange(row?.crd, row?.email, $event.target.checked, row?.id)" />
                                                        </ng-template>
                                                    </ngx-datatable-column>

                                                    <ngx-datatable-column [resizeable]="false" [sortable]="true"
                                                        [sorts]="[{ prop: 'last_name', dir: 'desc' }]" prop="last_name"
                                                        name="Name" [minWidth]="260">
                                                        <ng-template let-row="row" let-value="value"
                                                            ngx-datatable-cell-template>
                                                            <div class="d-flex">
                                                                <div>
                                                                    <ng-container *ngIf="row?.photo_url; else ownerPhoto">
                                                                        <div class="circle circle-align">
                                                                            <img class="circle-img" src="{{row?.photo_url}}">
                                                                        </div>
                                                                    </ng-container>
                                                                    <ng-template #ownerPhoto>
                                                                        <div>
                                                                            <span class="circle dash-user-initial top-0">
                                                                                {{ logoNameFilter(row) }}
                                                                            </span>
                                                                        </div>
                                                                    </ng-template>
                                                                </div>
                                                                <div>
                                                                    <a *ngIf="onlyInsuranceAccess" class="inheritColor"
                                                                        href="javascript:void(0)"
                                                                        (click)="openProfile({type:'in-person',crd:row?.npn, score: row?.score})">
                                                                        <span>{{ row?.first_name | uppercase }} {{
                                                                            row?.last_name | uppercase }}</span>
                                                                    </a>

                                                                    <a *ngIf="row?.crd && !onlyInsuranceAccess"
                                                                        class="inheritColor" href="javascript:void(0)"
                                                                        (click)="openProfile({type:'person',crd:row?.crd, score: row?.score})">
                                                                        <span>{{ row?.first_name | uppercase }} {{
                                                                            row?.last_name | uppercase }}</span>
                                                                    </a>

                                                                    <a *ngIf="row?.npn && !row?.crd && !onlyInsuranceAccess"
                                                                        class="inheritColor" href="javascript:void(0)"
                                                                        (click)="openProfile({type:'in-person',crd:row?.npn, score: row?.score})">
                                                                        <span>{{ row?.first_name | uppercase }} {{
                                                                            row?.last_name | uppercase }}</span>
                                                                    </a>

                                                                    <a *ngIf="!row?.npn && !row?.crd && row?.fo_id"
                                                                        (click)="openProfile({type:'family-person', crd:row?.fo_id, score: row?.score})"
                                                                        target="_blank" class="inheritColor">
                                                                        <span>{{ row?.first_name | uppercase }} {{
                                                                            row?.last_name | uppercase }}</span>
                                                                    </a>

                                                                    <a *ngIf="!row?.npn && !row?.crd && row?.bt_id"
                                                                        (click)="openProfile({type:'bank-person', crd:row?.bt_id, score: row?.score})"
                                                                        target="_blank" class="inheritColor">
                                                                        <span>{{ row?.first_name | uppercase }} {{
                                                                            row?.last_name | uppercase }}</span>
                                                                    </a>

                                                                    <a *ngIf="!row?.npn && !row?.crd && !row?.bt_id && row?.db"
                                                                        (click)="openPersonProfile(row)"
                                                                        target="_blank" class="inheritColor">
                                                                        <span>{{ row?.first_name | uppercase }} {{
                                                                            row?.last_name | uppercase }}</span>
                                                                    </a>

                                                                    <a *ngIf="!row?.npn && !row?.crd && !row?.bt_id && row?.dc"
                                                                        (click)="openPersonProfile(row)"
                                                                        target="_blank" class="inheritColor">
                                                                        <span>{{ row?.first_name | uppercase }} {{
                                                                            row?.last_name | uppercase }}</span>
                                                                    </a>

                                                                    <p class="fs-10 title-txt mb-0" *ngIf="row?.title">{{
                                                                        row?.title | uppercase }}</p>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                    </ngx-datatable-column>

                                                    <ngx-datatable-column [resizeable]="false" [minWidth]="300"
                                                        [width]="450" [sortable]="true" prop="firms" name="Firms">
                                                        <ng-template let-row="row" let-value="value"
                                                            ngx-datatable-cell-template>
                                                            <!-- <span [ngClass]="{'icon-check' : attributeCheck(row?.firms)}"></span> -->
                                                            <div class="d-flex ai-center-sch"
                                                                *ngFor="let firmRow of row?.firms['team']">
                                                                <div class="icon-mw">
                                                                    <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({'team': true})" />
                                                                </div>

                                                                <ng-container>
                                                                    <a href="javascript:void(0)"
                                                                        (click)="openDbaContact(firmRow, row?.advizor_team)"
                                                                        class="ml-2 inheritColor">{{ firmRow?.name |
                                                                        uppercase }}</a>
                                                                </ng-container>
                                   
                                                            </div>

                                                            <div class="d-flex ai-center-sch"
                                                                *ngFor="let firmRow of row?.firms['ins']">
                                                                <div class="icon-mw">
                                                                    <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({'insurance': true})" />
                                                                </div>

                                                                <ng-container>
                                                                    <div class="ml-2">{{ firmRow?.name | uppercase }}
                                                                    </div>
                                                                </ng-container>
                                                            </div>

                                                            <div class="d-flex ai-center-sch"
                                                                *ngFor="let firmRow of row?.firms['fo']">
                                                                <div class="icon-mw">
                                                                    <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({'fo': true})" />
                                                                </div>

                                                                <ng-container>
                                                                    <a (click)="openProfile({type:'family-firm', crd:firmRow?.id})"
                                                                        class="ml-2 inheritColor"
                                                                        href="javascript:void(0)">
                                                                        <span>{{ firmRow?.name | uppercase }}</span>
                                                                    </a>
                                                                </ng-container>
                                                            </div>

                                                            <div class="d-flex ai-center-sch"
                                                                *ngFor="let firmRow of row?.firms['dc']">
                                                                <div class="icon-mw">
                                                                    <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({'dc': true})" />
                                                                </div>

                                                                <ng-container>
                                                                    <a (click)="openFirmProfile({id: firmRow.id, dc: 1})"
                                                                        class="ml-2 inheritColor"
                                                                        href="javascript:void(0)">
                                                                        <span>{{ firmRow?.name | uppercase }}</span>
                                                                    </a>
                                                                </ng-container>
                                                            </div>

                                                            <div class="d-flex ai-center-sch"
                                                                *ngFor="let firmRow of row?.firms['db']">
                                                                <div class="icon-mw">
                                                                    <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({'db': true})" />
                                                                </div>

                                                                <ng-container>
                                                                    <a (click)="openFirmProfile({id: firmRow.id, db: 1})"
                                                                        class="ml-2 inheritColor"
                                                                        href="javascript:void(0)">
                                                                        <span>{{ firmRow?.name | uppercase }}</span>
                                                                    </a>
                                                                </ng-container>
                                                            </div>

                                                            <div class="d-flex ai-center-sch"
                                                                *ngFor="let firmRow of row?.firms['dual']">
                                                                <div class="icon-mw">
                                                                    <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({
                                                                        'advizor': true,
                                                                        'broker': true,
                                                                    })" />
                                                                </div>

                                                                <ng-container>
                                                                    <a href="javascript:void(0)"
                                                                        class="ml-2 inheritColor"
                                                                        (click)="openProfile({firm:'firm',crd:firmRow?.id})">
                                                                        <span>{{ firmRow?.name | uppercase }}</span>
                                                                    </a>
                                                                </ng-container>
                                                            </div>

                                                            <div class="d-flex ai-center-sch"
                                                                *ngFor="let firmRow of row?.firms['fo_ria']">
                                                                <div class="icon-mw">
                                                                    <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({
                                                                        'advizor': true,
                                                                        'fo': true,
                                                                    })" />
                                                                </div>

                                                                <ng-container>
                                                                    <a href="javascript:void(0)"
                                                                        class="ml-2 inheritColor"
                                                                        (click)="openProfile({firm:'firm',crd:firmRow?.id})">
                                                                        <span>{{ firmRow?.name | uppercase }}</span>
                                                                    </a>
                                                                </ng-container>
                                                            </div>

                                                            <div class="d-flex ai-center-sch"
                                                                *ngFor="let firmRow of row?.firms['ria']">
                                                                <div class="icon-mw">
                                                                    <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({'advizor': true})" />
                                                                </div>

                                                                <ng-container>
                                                                    <a href="javascript:void(0)"
                                                                        class="ml-2 inheritColor"
                                                                        (click)="openProfile({firm:'firm',crd:firmRow?.id})">
                                                                        <span>{{ firmRow?.name | uppercase }}</span>
                                                                    </a>
                                                                </ng-container>
                                                            </div>

                                                            <div class="d-flex ai-center-sch"
                                                                *ngFor="let firmRow of row?.firms['bt']">
                                                                <div class="icon-mw">
                                                                    <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({'bt': true})" />
                                                                </div>

                                                                <ng-container>
                                                                    <a (click)="openProfile({type:'bank-firm', crd:firmRow?.id})"
                                                                        class="ml-2 inheritColor"
                                                                        href="javascript:void(0)">
                                                                        <span>{{ firmRow?.name | uppercase }}</span>
                                                                    </a>
                                                                </ng-container>
                                                            </div>

                                                            <div class="d-flex ai-center-sch"
                                                                *ngFor="let firmRow of row?.firms['bt_ria']">
                                                                <div class="icon-mw">
                                                                    <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({
                                                                        'advizor': true,
                                                                        'bt': true,
                                                                    })" />
                                                                </div>

                                                                <ng-container>
                                                                    <a href="javascript:void(0)"
                                                                        class="ml-2 inheritColor"
                                                                        (click)="openProfile({firm:'firm',crd:firmRow?.id})">
                                                                        <span>{{ firmRow?.name | uppercase }}</span>
                                                                    </a>
                                                                </ng-container>
                                                            </div>

                                                            <div class="d-flex ai-center-sch"
                                                                *ngFor="let firmRow of row?.firms['bt_bd']">
                                                                <div class="icon-mw">
                                                                    <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({
                                                                        'broker': true,
                                                                        'bt': true,
                                                                    })" />
                                                                </div>

                                                                <ng-container>
                                                                    <a href="javascript:void(0)"
                                                                        class="ml-2 inheritColor"
                                                                        (click)="openProfile({firm:'firm',crd:firmRow?.id})">
                                                                        <span>{{ firmRow?.name | uppercase }}</span>
                                                                    </a>
                                                                </ng-container>
                                                            </div>

                                                            <div class="d-flex ai-center-sch"
                                                                *ngFor="let firmRow of row?.firms['bt_dual']">
                                                                <div class="icon-mw">
                                                                    <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({
                                                                        'advizor': true,
                                                                        'broker': true,
                                                                        'bt': true,
                                                                    })" />
                                                                </div>

                                                                <ng-container>
                                                                    <a href="javascript:void(0)"
                                                                        class="ml-2 inheritColor"
                                                                        (click)="openProfile({firm:'firm',crd:firmRow?.id})">
                                                                        <span>{{ firmRow?.name | uppercase }}</span>
                                                                    </a>
                                                                </ng-container>
                                                            </div>

                                                            <div class="d-flex ai-center-sch"
                                                                *ngFor="let firmRow of row?.firms['bd']">
                                                                <div class="icon-mw">
                                                                    <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({
                                                                        'broker': true
                                                                    })" />
                                                                </div>

                                                                <ng-container>
                                                                    <a href="javascript:void(0)"
                                                                        class="ml-2 inheritColor"
                                                                        (click)="openProfile({firm:'firm',crd:firmRow?.id})">
                                                                        <span>{{ firmRow?.name | uppercase }}</span>
                                                                    </a>
                                                                </ng-container>
                                                            </div>
                                                        </ng-template>
                                                    </ngx-datatable-column>

                                                    <ngx-datatable-column [resizeable]="false" [minWidth]="100"
                                                        [sortable]="true" prop="city" name="City">
                                                        <ng-template let-row="row" let-value="value"
                                                            ngx-datatable-cell-template>
                                                            <span>{{ row?.city | uppercase }}</span>
                                                        </ng-template>
                                                    </ngx-datatable-column>

                                                    <ngx-datatable-column [resizeable]="false" [minWidth]="60"
                                                        [sortable]="true" prop="state" name="State">
                                                        <ng-template let-row="row" let-value="value"
                                                            ngx-datatable-cell-template>
                                                            <span>{{ row?.state | uppercase}}</span>
                                                        </ng-template>
                                                    </ngx-datatable-column>

                                                    <ngx-datatable-column
                                                        [resizeable]="false" [minWidth]="100" [sortable]="false"
                                                        prop="score" name="Score">
                                                        <ng-template let-row="row" let-value="value"
                                                            ngx-datatable-cell-template>
                                                            <span *ngIf="!row.score">
                                                                <a [routerLink]="'/score'"><span class="semi-bold">--</span></a>
                                                            </span>
                                                            
                                                            <span *ngIf="row.score">
                                                                <span class="score-btn bg-score-grey">
                                                                    <img src="assets/img/{{ minimumScore[row?.score] }}.png"
                                                                        width="14px" [alt]="minimumScore[row?.score]">
                                                                    <span class="semi-bold">{{ row?.score}}</span>
                                                                </span>
                                                            </span>
                                                        </ng-template>
                                                    </ngx-datatable-column>

                                                    <ngx-datatable-column [resizeable]="false"
                                                        cellClass="advisor_action" [minWidth]="85" [sortable]="false"
                                                        prop="advizor_person_attribute.value" name="Contact">
                                                        <ng-template let-row="row" let-value="value"
                                                            ngx-datatable-cell-template>
                                                            <a href="mailto:{{row?.email}}" target="_blank"
                                                                *ngIf="row?.email" class="ml-1"
                                                                title="{{row?.email}}"><i class="far fa-envelope"
                                                                    aria-hidden="true"></i></a>
                                                            <a href="mailto:{{row?.personal_email}}" target="_blank"
                                                                *ngIf="row?.personal_email" class="ml-1"
                                                                title="{{row?.personal_email}}"><i
                                                                    class="far fa-envelope personal_email"
                                                                    aria-hidden="true"></i></a>
                                                            <a href="tel:{{row?.phone}}" title="{{row?.phone}}"
                                                                *ngIf="row?.phone" class="ml-1"><i
                                                                    class="fas fa-phone-alt" aria-hidden="true"></i></a>
                                                            <a href="{{row?.bio_url}}" title="{{row?.bio_url}}"
                                                                target="_blank" *ngIf="row?.bio_url" class="ml-1"><i
                                                                    class="fas fa-user" aria-hidden="true"></i></a>
                                                            <a href="{{row?.linkedin}}" title="{{row?.linkedin}}"
                                                                target="_blank" *ngIf="row?.linkedin" class="ml-1"><i
                                                                    class="fab fa-linkedin" aria-hidden="true"></i></a>
                                                        </ng-template>
                                                    </ngx-datatable-column>
                                                    <ngx-datatable-footer>
                                                        <ng-template ngx-datatable-footer-template
                                                            let-rowCount="rowCount" let-pageSize="pageSize"
                                                            let-selectedCount="selectedCount" let-curPage="curPage"
                                                            let-offset="offset" let-isVisible="isVisible">
                                                            <div class="pagination-v2">
                                                                <div class="page-count p-0">
                                                                    <span class="page-total">
                                                                        {{ rowCount?.toLocaleString() }} total entries
                                                                    </span>
                                                                </div>
                                                                <datatable-pager class="custom-datatable-pager"
                                                                    [pagerLeftArrowIcon]="'datatable-icon-left'"
                                                                    [pagerRightArrowIcon]="'datatable-icon-right'"
                                                                    [pagerPreviousIcon]="'datatable-icon-prev'"
                                                                    [pagerNextIcon]="'datatable-icon-skip'"
                                                                    [page]="curPage" [size]="pageSize"
                                                                    [count]="rowCount"
                                                                    [hidden]="!((rowCount / pageSize) > 1)"
                                                                    (change)="sortDataPerson($event)">
                                                                </datatable-pager>
                                                            </div>
                                                        </ng-template>
                                                    </ngx-datatable-footer>
                                                </ngx-datatable>
                                            </div>
                                        </div>
                                    </div>
                                </nz-tab>
                                <nz-tab [nzTitle]="titleFirmTemplate" (nzSelect)="changeTab('firm')" [nzSize]="'large'"
                                    *ngIf="!onlyInsuranceAccess">
                                    <ng-template #titleFirmTemplate>
                                        <b class="fs-12 dm-sans-bold">Companies</b> <ng-container
                                            *ngIf="firmLoading; else elseFirmNotDone">
                                            <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
                                        </ng-container>
                                        <ng-template #elseFirmNotDone>
                                            <b class="fs-12 dm-sans-bold">&nbsp;({{search_result?.firm?.total | number :
                                                0}})</b>
                                        </ng-template>
                                    </ng-template>

                                    <div class="row m-0">
                                        <div class="col-lg-11 p-0 ml-1 note-field" *ngIf="filter_count > 0">
                                            <div class="ml-4">
                                                <div class="search-tag d-inline">
                                                    <ng-container *ngTemplateOutlet="tagContentTemplate"></ng-container>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 mb-3">
                                            <div class="table-responsive" style="min-height:150px;">
                                                <ng-container *ngIf="firmLoading; else firmLd">
                                                    <nz-spin nzTip="Loading..." class="tbl-loader">
                                                        <ng-template #firmDatatblLoad></ng-template>
                                                    </nz-spin>
                                                </ng-container>
                                                <ng-template #firmLd>
                                                    <ng-template #firmDatatblLoad></ng-template>
                                                </ng-template>
                                                <ngx-datatable
                                                    class='table table-hover advisor-search-tbl material table-v2'
                                                    [rows]="search_result?.firm?.data" [columnMode]="columnModeSetting"
                                                    [headerHeight]="43" [footerHeight]="50" [rowHeight]="'auto'"
                                                    [scrollbarH]="scrollBarHorizontal" [externalPaging]="true"
                                                    [count]="search_result?.firm?.total"
                                                    [offset]="firmPaginate.pageNumber" [limit]="firmPaginate.limit"
                                                    (page)='sortDataFirm($event)' [externalSorting]="true"
                                                    (sort)="onSortFirm($event)" #firmDatatblLoad>
                                                    <ngx-datatable-column [resizeable]="false" [sortable]="true"
                                                        prop="firm_name" name="Name" [minWidth]="420">
                                                        <ng-template let-row="row" let-value="value"
                                                            ngx-datatable-cell-template>
                                                            <div class="d-flex ai-center-sch">
                                                                <div class="icon-ifb mr-1">
                                                                    <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({
                                                                        'advizor': row?.advizor,
                                                                        'broker': row?.broker,
                                                                        'fo': row?.fo,
                                                                        'bt': row?.bt,
                                                                        'db': row?.db,
                                                                        'dc': row?.dc,
                                                                    })" />
                                                                </div>
                                                                <a *ngIf="row?.crd" href="javascript:void(0)"
                                                                    (click)="openProfile({firm:'firm',crd:row?.crd})"
                                                                    class="inheritColor"><span>
                                                                        {{ row?.firm_name | uppercase}}</span></a>
                                                                <a *ngIf="!row?.crd && row?.fo_id"
                                                                    (click)="openProfile({type:'family-firm', crd:row?.fo_id})"
                                                                    href="javascript:void(0)"
                                                                    class="inheritColor"><span>
                                                                        {{ row?.firm_name | uppercase}}</span></a>
                                                                <a *ngIf="!row?.crd && row?.bt_id"
                                                                    (click)="openProfile({type:'bank-firm', crd:row?.bt_id})"
                                                                    href="javascript:void(0)"
                                                                    class="inheritColor"><span>
                                                                        {{ row?.firm_name | uppercase}}</span></a>
                                                                
                                                                <!-- Default case, should be update openFirmProfile instead of add a new
                                                                 component here -->
                                                                <a *ngIf="!row?.crd && !row?.fo_id && !row?.bt_id && row?.dc || row?.db"
                                                                    (click)="openFirmProfile(row)"
                                                                        href="javascript:void(0)"
                                                                        class="inheritColor"><span>
                                                                            {{ row?.firm_name | uppercase}}</span></a>
                                                                
                                                            </div>
                                                        </ng-template>
                                                    </ngx-datatable-column>

                                                    <ngx-datatable-column prop="total_aum" name="AUM" [minWidth]="100"
                                                        [resizeable]="false" [sortable]="true">
                                                        <ng-template let-row="row" let-value="value"
                                                            ngx-datatable-cell-template>
                                                            <span *ngIf="row?.total_aum">{{ ((row?.total_aum / 1000000)
                                                                |
                                                                currency:'USD':'symbol':'1.0-2')+' M' }}</span>
                                                            <span *ngIf="row?.fo_firm_aum && !row?.total_aum">{{
                                                                ((row?.fo_firm_aum / 1000000) |
                                                                currency:'USD':'symbol':'1.0-2')+' M' }}</span>
                                                            <span *ngIf="row?.total_bank_assets && !row?.total_aum && !row?.fo_firm_aum">{{
                                                                ((row?.total_bank_assets / 1000000) |
                                                                currency:'USD':'symbol':'1.0-2')+' M' }}</span>
                                                            <span *ngIf="!row?.total_aum && !row?.fo_firm_aum && !row?.total_bank_assets">--</span>
                                                        </ng-template>
                                                    </ngx-datatable-column>

                                                    <ngx-datatable-column [resizeable]="false" [sortable]="true"
                                                        prop="city" name="City" [minWidth]="150">
                                                        <ng-template let-row="row" let-value="value"
                                                            ngx-datatable-cell-template>
                                                            <span>{{ row?.city | uppercase}}</span>
                                                        </ng-template>
                                                    </ngx-datatable-column>

                                                    <ngx-datatable-column [resizeable]="false" [sortable]="true"
                                                        prop="state" name="State" [minWidth]="50">
                                                        <ng-template let-row="row" let-value="value"
                                                            ngx-datatable-cell-template>
                                                            <span>{{ row?.state | uppercase}}</span>
                                                        </ng-template>
                                                    </ngx-datatable-column>

                                                    <ngx-datatable-column *ngIf="user?.lead_scoring && false"
                                                        [resizeable]="false" [minWidth]="100" [sortable]="false"
                                                        prop="score" name="Score">
                                                        <ng-template let-row="row" let-value="value"
                                                            ngx-datatable-cell-template>
                                                            <span *ngIf="!row.score">
                                                                <a [routerLink]="'/score'"><span class="semi-bold">--</span></a>
                                                            </span>
                                                            
                                                            <span *ngIf="row.score">
                                                                <span class="score-btn bg-score-grey">
                                                                    <img src="assets/img/{{ minimumScore[row?.score] }}.png"
                                                                        width="14px" [alt]="minimumScore[row?.score]">
                                                                    <span class="semi-bold">{{ row?.score}}</span>
                                                                </span>
                                                            </span>
                                                        </ng-template>
                                                    </ngx-datatable-column>

                                                    <!-- cellClass="d-flex align-items-center" -->
                                                    <!--
                                                <ngx-datatable-column [resizeable]="false" [sortable]="true" name="CRD" [minWidth]="80">
                                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                    <span>{{ row?.crd }}</span>
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                -->
                                                    <ngx-datatable-footer>
                                                        <ng-template ngx-datatable-footer-template
                                                            let-rowCount="rowCount" let-pageSize="pageSize"
                                                            let-selectedCount="selectedCount" let-curPage="curPage"
                                                            let-offset="offset" let-isVisible="isVisible" class="fs-11">
                                                            <div class="pagination-v2">
                                                                <div class="page-count p-0">
                                                                    <span class="page-total">
                                                                        {{ rowCount?.toLocaleString() }} total entries
                                                                    </span>
                                                                </div>
                                                                <datatable-pager class="custom-datatable-pager"
                                                                    [pagerLeftArrowIcon]="'datatable-icon-left'"
                                                                    [pagerRightArrowIcon]="'datatable-icon-right'"
                                                                    [pagerPreviousIcon]="'datatable-icon-prev'"
                                                                    [pagerNextIcon]="'datatable-icon-skip'"
                                                                    [page]="curPage" [size]="pageSize"
                                                                    [count]="rowCount"
                                                                    [hidden]="!((rowCount / pageSize) > 1)"
                                                                    (change)="sortDataFirm($event)">
                                                                </datatable-pager>
                                                            </div>
                                                        </ng-template>
                                                    </ngx-datatable-footer>
                                                </ngx-datatable>
                                            </div>
                                        </div>
                                    </div>
                                </nz-tab>
                            </nz-tabset>
                            <ng-template #extraTemplate>
                                <div [ngClass]="{'setin-pg': exportCss}">
                                    <div>
                                        <button *ngIf="!onlyInsuranceAccess && !personLoading" class="map-button" (click)="showLocationModal()">
                                            <div class="button-items-container  ">
                                                <svg  class="map-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24"  cached="true">
                                                    <g transform="matrix(1.157867 0 0 1.185459-1.894404-2.225508)">
                                                        <path d="M9,4L3,8v12l6-4l6,4l6-4v-12L15,8L9,4Z" fill="none" stroke="#000" stroke-width="0.768" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </g>
                                                    <path d="M8.526399,2.516328q0,12.07711,0,12.119906v2.105602" fill="none" stroke="#000" stroke-width="0.5"/>
                                                    <path d="M15.473601,7.258164q0,14.225508,0,14.225508" fill="none" stroke="#000" stroke-width="0.5"/>
                                                </svg>
                                                <span class="button-map-common-class"> Map View </span>
                                            </div>
                                        </button> 
                                    </div>
                                   <div class="search-buttons dm-sans-regular "> 
                                        <!-- <a href="javascript:void(0);" class="semi-bold fs-13" (click)="savedClick()">Saved Searches</a> -->
                                        <app-saved-search-selector
                                            [selectedSaved]="selectedSaved"
                                            [savedSearcheOptions]="saveOfGroupOption"
                                            (searchChange)="updateSaveFilter($event)"
                                            nz-tooltip
                                            [nzTooltipColor]="tooltipColor"
                                            nzTooltipTitle="{{tooltipList['saved_search']}}"
                                        />

                                        <button type="button" *ngIf="is_make_search" title="Bulk Follow"
                                            name="bulk_follow" class="tooltip-wrapper  btn mt-1 btn-search icon-btn"
                                            (click)="bulkFollow()"> <i class="fa fa-plus" aria-hidden="true"></i>
                                        </button>

                                        <button type="button" title="Send Email" *ngIf="user?.email_automation"
                                            name="send_email"
                                            class="tooltip-wrapper  btn btn-cons btn-search icon-btn mt-1"
                                            (click)="sendEmail()"><i class="fas fa-envelope"></i></button>

                                        <!-- <a href="javascript:void(0);" class=" semi-bold fs-13" (click)="emailQuality()">Email Quality (A+{{user_setting.email_quality_A ? ', A':''}}{{user_setting.email_quality_B ? ', B':''}})</a> -->

                                        <button type="button" title="Excel Export" icon="fa-file-pdf-o"
                                            class="btn btn-search icon-btn excel-btn mt-1 fs-11"
                                            [disabled]="exportStatus" iconPos="right" label="CSV"
                                            (click)="exportModel()" *ngIf="user?.ap_user_limit_csv">
                                            <span *ngIf="exportStatus">Exporting...</span>
                                            <span *ngIf="!exportStatus"></span><i class="far fa-file-excel"
                                                aria-hidden="true"></i> <span class="ml-1">Excel Export</span>
                                        </button>


                                        <button type="button" title="Save Search" name="save_search_btn"
                                            class=" btn btn-cons btn-search icon-btn search-btn mt-1 ml-3 mr-3 fs-11"
                                            (click)="showDialog()"> <i class="far fa-save" aria-hidden="true"></i><span
                                                class="ml-1">Save Search</span>
                                        </button>

                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ng-template #tagContentTemplate>
            <ng-container *ngFor="let tag of search | keyvalue;">
                <ng-container *ngIf="tag.value">
                    <ng-container
                        *ngIf="tag.key == 'type' && tag.value?.length > 0">
                        <div class="d-inline-block">
                            <div class="d-block mb-2">
                                <div class="d-inline tagsize">
                                    {{ keyFilter(replace(tag),"key") }} :
                                </div>
                                <ng-container *ngFor="let val of tag.value;">
                                    <pg-tag [Closable]="true"
                                        (Close)="handleHeaderClose({key:'type', value:val})">{{
                                        keyFilter(replace({key:'type',
                                        value:val})) }}
                                    </pg-tag>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container
                        *ngIf="tag.key == 'quick_search' && tag.value?.length > 0">
                        <div class="d-inline-block">
                            <div class="d-block mb-2">
                                <div class="d-inline tagsize">
                                    {{ keyFilter(replace(tag),"key") }} :
                                </div>
                                <ng-container *ngFor="let val of tag.value;">
                                    <pg-tag *ngIf="preCheckSearch(val)"
                                        [Closable]="true"
                                        (Close)="handleHeaderClose({key:'quick_search', value:val})">{{
                                        keyFilter(replace({key:'quick_search',
                                        value:val})) }}
                                    </pg-tag>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container
                        *ngIf="tag.key !='crd_autocomplete' && tag.key !='file_name' && tag.key != 'save_search_id' && tag.key !='search_option' && tag.key != 'search_type' && tag.key !='quick_search_crd_name' && tag.key !='quick_full_search' && tag.key !='firm_keyword_category' && tag.key !='person_keyword_category' && tag.key !='select_title_type' && tag.key != 'select_carrier_type' && tag.key != 'select_company_type' && tag.key !='shared_type' && tag.key !='my_advizor_option' && tag.key !='firm_my_advizor_option' && tag.key != 'custodian_option' && tag.key !='licenses_filter_switch' && tag.key != 'designation_filter_switch' && tag.key != 'ticker_filter_switch' && tag.key != 'people_with_filter_switch' && tag.key != 'exclude_firms' && tag.key != 'exclude_persons' && tag.key != 'ins_line_filter_switch' && tag.key != 'ins_register_filter_switch' && tag.key !='firm_name_search' && tag.key !='exclude_firm' && tag.key !='bulk_filter_option' && tag.key != 'email_quality' && tag.key != 'email_quality_A' && tag.key != 'email_quality_B' && tag.key != 'type' && tag.key != 'quick_search' && tag.key !='lead_score_id'">
                        <div class="d-inline-block">
                            <div class="d-block mb-2">
                                <div class="d-inline  tagsize">
                                    {{ keyFilter(replace(tag),"key") }} :
                                </div>
                                <pg-tag [Closable]="true"
                                    (Close)="handleClose(tag)">{{
                                    keyFilter(replace(tag),"value") }}</pg-tag>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-template>
        
        <ng-template #commonTemplate let-data="data">
            <div *ngFor="let fieldOption of data?.search_field; trackBy: trackByFnOptions;">
                <div *ngIf="fieldOption.type == 'select'">
                    <label>{{data?.label}}&nbsp;<i *ngIf="data.tooltip"
                            class="fa fa-question-circle" nz-tooltip
                            [nzTooltipColor]="tooltipColor"
                            nzTooltipTitle="{{data.tooltip}}"></i>
                        <span *ngIf="fieldOption.is_switch">
                            &nbsp;
                            <nz-switch
                            [ngModel]="getSwitchModel(fieldOption)"
                            name="fieldOption['switch_model_name']"
                            [nzSize]="fieldOption['switch_size']"
                            [nzCheckedChildren]="fieldOption['switch_checked']"
                            [nzUnCheckedChildren]="fieldOption['switch_unchecked']"
                            (ngModelChange)="setSwitchModelName($event, fieldOption)"></nz-switch>
                        </span>
                    </label>
                    &nbsp;
                    <pg-select style="width: 100%;"
                        [Mode]="fieldOption['mode']"
                        [PlaceHolder]="'Select'"
                        [(ngModel)]="search[fieldOption['model_name']]" 
                        [NotFoundContent]="fieldOption['not_found_content']"
                        name="fieldOption['model_name']"
                        [Disabled]="inputFilterOptionDisabled(fieldOption)"
                        (ngModelChange)="setModelName($event, fieldOption)">
                        <pg-option *ngFor="let option of getList(fieldOption)"
                            [Label]="getOption(option, 'option_label', fieldOption)"
                            [Value]="getOption(option, 'option_value', fieldOption)"
                            [Disabled]="option?.disabled">
                        </pg-option>
                    </pg-select>
                </div>
                
                <div *ngIf="fieldOption.type == 'text'">
                    <label>{{data?.label}}&nbsp;<i *ngIf="data.tooltip"
                            class="fa fa-question-circle" nz-tooltip
                            [nzTooltipColor]="tooltipColor"
                            nzTooltipTitle="{{data.tooltip}}"></i>
                        <span *ngIf="fieldOption.is_switch">
                            &nbsp;
                            <nz-switch
                            [ngModel]="getSwitchModel(fieldOption)"
                            name="fieldOption['switch_model_name']"
                            [nzSize]="fieldOption['switch_size']"
                            [nzCheckedChildren]="fieldOption['switch_checked']"
                            [nzUnCheckedChildren]="fieldOption['switch_unchecked']"
                            (ngModelChange)="setSwitchModelName($event, fieldOption)"></nz-switch>
                        </span>
                    </label>
                    <div>
                        <input type="text" name="fieldOption['model_name']"
                            [(ngModel)]="search[fieldOption['model_name']]"
                            class="form-control" placeholder="Contains"
                            (ngModelChange)="setModelName($event, fieldOption)">
                    </div>
                </div>

                <div *ngIf="fieldOption.type == 'minmax'">
                    <label>{{data?.label}}&nbsp;<i
                            *ngIf="data.tooltip" class="fa fa-question-circle"
                            nz-tooltip
                            nzTooltipTitle="{{data.tooltip}}"></i></label>
                        
                    <div class="row">
                        <div class="col-lg-{{fieldOption['col_size']}}">
                            <ng-container *ngIf="fieldOption['textmask']; else noMask">
                                <input type="text"
                                    name="fieldOption['min_model_name']"
                                    [(ngModel)]="search[fieldOption['min_model_name']]"
                                    (ngModelChange)="setModelName($event, fieldOption, 'min_model_change')"
                                    class="form-control"
                                    [placeholder]="fieldOption['min_placeholder']"
                                    [textMask]="{mask: numberMask}">
                            </ng-container>

                            <ng-template #noMask>
                            <input type="text"
                                    name="fieldOption['min_model_name']"
                                    [(ngModel)]="search[fieldOption['min_model_name']]"
                                    (ngModelChange)="setModelName($event, fieldOption, 'min_model_change')"
                                    class="form-control"
                                    [placeholder]="fieldOption['min_placeholder']">
                            </ng-template>
                        </div>
                        <div class="col-lg-{{fieldOption['col_size']}}" [ngClass]="{'mt-2': fieldOption['col_size'] == 12}">
                            <ng-container *ngIf="fieldOption['textmask']; else noMaxMask">
                                <input type="text"
                                    name="fieldOption['max_model_name']"
                                    [(ngModel)]="search[fieldOption['max_model_name']]"
                                    (ngModelChange)="setModelName($event, fieldOption, 'max_model_change')"
                                    class="form-control"
                                    [placeholder]="fieldOption['max_placeholder']"
                                    [textMask]="{mask: numberMask}">
                            </ng-container>

                            <ng-template #noMaxMask>
                            <input type="text"
                                    name="fieldOption['max_model_name']"
                                    [(ngModel)]="search[fieldOption['max_model_name']]"
                                    (ngModelChange)="setModelName($event, fieldOption, 'max_model_change')"
                                    class="form-control"
                                    [placeholder]="fieldOption['max_placeholder']">
                            </ng-template>
                        </div>
                    </div>
                </div>

                <div *ngIf="fieldOption.type == 'checkbox'">
                    <div class="checkbox">
                        <input type="checkbox" name="{{fieldOption['model_name']}}"
                            [(ngModel)]="search[fieldOption['model_name']]"
                            (ngModelChange)="setModelName($event, fieldOption)" id="{{fieldOption['model_name']}}">
                        <label for="{{fieldOption['model_name']}}" class="m-0">{{data?.label}}
                            <i *ngIf="data.tooltip" class="fa fa-question-circle" nz-tooltip [nzTooltipColor]="tooltipColor" nzTooltipTitle="{{data.tooltip}}"></i>
                        </label>
                    </div>
                </div>

                <div *ngIf="fieldOption.type == 'minmaxInputGroup'">
                    <label>{{data?.label}}&nbsp;<i
                            *ngIf="data.tooltip" class="fa fa-question-circle"
                            nz-tooltip
                            nzTooltipTitle="{{data.tooltip}}"></i></label>
                        
                    <div class="row custom-min-max">
                        <div class="col-lg-{{fieldOption['col_size']}}">
                            <div class="input-group transparent">
                                <div class="input-group-prepend" *ngIf="fieldOption['prefix']">
                                    <span class="input-group-text transparent">
                                        <i class="fas fa-dollar-sign"></i>
                                    </span>
                                </div>
                                <ng-container *ngIf="fieldOption['textmask']; else noMask">
                                    <input type="text"
                                        name="fieldOption['min_model_name']"
                                        [(ngModel)]="search[fieldOption['min_model_name']]"
                                        (ngModelChange)="setModelName($event, fieldOption, 'min_model_change')"
                                        class="form-control"
                                        [placeholder]="fieldOption['min_placeholder']"
                                        [textMask]="{mask: numberMask}">
                                </ng-container>

                                <ng-template #noMask>
                                    <input type="text"
                                        name="fieldOption['min_model_name']"
                                        [(ngModel)]="search[fieldOption['min_model_name']]"
                                        (ngModelChange)="setModelName($event, fieldOption, 'min_model_change')"
                                        class="form-control"
                                        [placeholder]="fieldOption['min_placeholder']">
                                </ng-template>
                            </div>
                        </div>
                        <div class="col-lg-{{fieldOption['col_size']}}" [ngClass]="{'mt-2': fieldOption['col_size'] == 12}">
                            <div class="input-group transparent">
                                <div class="input-group-prepend" *ngIf="fieldOption['prefix']">
                                    <span class="input-group-text transparent">
                                        <i class="fas fa-dollar-sign"></i>
                                    </span>
                                </div>
                                <ng-container *ngIf="fieldOption['textmask']; else noMaxMask">
                                    <input type="text"
                                        name="fieldOption['max_model_name']"
                                        [(ngModel)]="search[fieldOption['max_model_name']]"
                                        (ngModelChange)="setModelName($event, fieldOption, 'max_model_change')"
                                        class="form-control"
                                        [placeholder]="fieldOption['max_placeholder']"
                                        [textMask]="{mask: numberMask}">
                                </ng-container>

                                <ng-template #noMaxMask>
                                <input type="text"
                                        name="fieldOption['max_model_name']"
                                        [(ngModel)]="search[fieldOption['max_model_name']]"
                                        (ngModelChange)="setModelName($event, fieldOption, 'max_model_change')"
                                        class="form-control"
                                        [placeholder]="fieldOption['max_placeholder']">
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</pg-container>

<location-modal [isActive]="locationModalActive" 
                (isActiveChange)="locationModalActiveChange()" 
                [advizorProService]="advizorProService"
                [searchNewComponent]="this"
                />

<nz-modal nzCentered [(nzVisible)]="display" nzWidth="600px" [nzContent]="displaySavedContent"
    [nzFooter]="displaySavedContentFooter" nzTitle="Save Search" (nzOnCancel)="displaySavedContentCancel()"
    (nzOnOk)="saveSearchData(save_search)">
    <ng-template #displaySavedContent>
        <div class="row">
            <div class="col-md-12 mb-3">
                <div class="custom-inputs">
                    <label class="custom-input-label">Name this search</label>
                    <input class="custom-input-text form-control" type="text" name="hello"
                        [(ngModel)]="save_search.search_name" />
                </div>
            </div>
            <div class="col-md-3 form-group">
                <input type="radio" name="shared_type" [(ngModel)]="save_search.shared_type" value="0">
                <label for="private_label" style="margin-left:0.5rem;">Private</label>
            </div>
            <div class="col-md-3 form-group">
                <input type="radio" name="shared_type" [(ngModel)]="save_search.shared_type" value="1">
                <label for="team_label" style="margin-left:0.5rem;">Team</label>
            </div>
        </div>
        <div class="row" *ngIf="search.save_search_id && !isAdvizorProSaved">
            <div class="col-md-6 form-group">
                <input type="radio" name="overwrite" [(ngModel)]="save_search.save_exist_type" value="1">
                <label for="private_label" style="margin-left:0.5rem;">Update current search</label>
            </div>
            <div class="col-md-5 form-group">
                <input type="radio" name="save_as_new" [(ngModel)]="save_search.save_exist_type"
                    (click)="save_search.search_name=''" value="0">
                <label for="team_label" style="margin-left:0.5rem;">Save as new search</label>
            </div>
        </div>
        <div class="row" *ngIf="user.has_access_hubspot || user.has_access_salesforce">
            <div class="col-md-6 form-group">
                <div class="checkbox">
                    <input type="checkbox" name="exclude_firms" [(ngModel)]="save_search.exclude_firms"
                        id="checkbox_ef">
                    <label for="checkbox_ef" class="m-0">Exclude Firms in Results</label>
                </div>
            </div>
            <div class="col-md-6 form-group">
                <div class="checkbox">
                    <input type="checkbox" name="exclude_persons" [(ngModel)]="save_search.exclude_persons"
                        id="checkbox_ep">
                    <label for="checkbox_ep" class="m-0">Exclude Persons in Results</label>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #displaySavedContentFooter>
        <div class="row mt-3">
            <div class="col-md-12">
                <button class="btn btn-complete pull-right" type="button" (click)="saveSearchData(save_search)"
                    label="Save"> Save </button>
            </div>
        </div>
    </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="isUpdList" nzTitle="List Matching" (nzOnCancel)="updListCancel()"
    (nzOnOk)="updListOk()" [nzFooter]="updListFooter" [nzContent]="updListContent" [nzWidth]="800">
    <ng-template #updListContent>
        <div class="row">
            <div class="col-lg-12">
                <span class="pull-left semi-bold">This platform feature provides you with the ability to upload a list
                    of people or companies with unique identifiers (such as emails, address, phone number, CRD, or company
                    names) and match them against related records in the AdvizorPro database. As a result, list matching
                    will equip you with the most accurate and updated data available. It only takes only a matter of
                    seconds for most lists.
                </span>

             <br />
             <br />
                     
            <strong>For Contacts files:</strong>
            <ul>
                <li>At a minimum, include either the full name or both first and last names, and the company or firm name.</li>
                <li>Alternatively, you can upload contacts with just an email address.</li>
                <li>Additional fields, like city/state and phone number enhance match accuracy.</li>
            </ul>
    
            <strong>For Companies files:</strong>
            <ul>
                <li>Only the company or firm name is required.</li>
                <li>Including fields like city/state, phone number, or website will strengthen match results.</li>
            </ul>
                 

                <p *ngIf="invalidUploadField.indexOf('upload_file') != -1" class="text-danger">Please upload a file.</p>
                <div class="col-lg-12 mt-2">
                    <br>
                    <nz-upload nzType="drag" [(nzFileList)]="fileList" [nzMultiple]="false" nzAccept=".xlsx,.csv,.xls"
                        Name="upload_file" [nzBeforeUpload]="uploadFileChange">
                        <span class="ant-upload-text bold">Drag and drop or choose a file to upload your list.</span>
                        <div class="ant-upload-text bold">All .csv, .xlsx, and .xls file types are supported.</div>
                    </nz-upload>
                </div>
            </div>
            <div class="col-lg-12 mt-3">
                <label class="mr-2 mb-0">Search Name</label>
                <div class="col-lg-12 pl-0 d-flex align-items-center">
                    <input type="text" name="list_save_search" [(ngModel)]="searchFileName" class="form-control"
                        placeholder="Search Name">
                </div>
            </div>
            
        </div>
    </ng-template>
    <ng-template #updListFooter>
        <div class="row mt-3">
            <div class="col-md-8 web-serch-checkbox d-flex align-items-center">
                <div class="checkbox ml-2 pull-right">
                    <input type="checkbox" name="disclaimer_check" [(ngModel)]="disclaimer_check" id="checkbox_dis">

                    <label for="checkbox_dis" class="m-0">I agree to read and accept this <span nz-tooltip
                            [nzTooltipColor]="tooltipColor"
                            nzTooltipTitle="{{tooltipList['list_matching_disclaimer']}}" (click)="disclosureContentModal()">Disclaimer</span></label>
                </div>
            </div>
            <div class="col-md-4 d-flex align-items-center justify-content-end">
                <button class="btn primary-btn-grn pull-right" type="button" (click)="uploadFile();"
                    [disabled]="!disclaimer_check"> Next </button>
            </div>
        </div>
    </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="isList" [nzTitle]="listMatchingTitle" (nzOnCancel)="listCancel()"
    (nzOnOk)="listOk()" [nzFooter]="listFooter" [nzContent]="listContent" [nzWidth]="950">
    <ng-template #listMatchingTitle>
        List Matching
    </ng-template>
    <ng-template #listContent>
        <div class="row ml-1">
            <div class="col-md-12 pl-0">
                <div *ngIf="!uploadedList?.fields_detected && uploadedList?.status != 'error'">
                    <h6><span class="bold">Uploading data and detecting columns from the file...</span></h6>
                </div>

                <div *ngIf="uploadedList?.status == 'error'">
                    <h6><span class="bold">An error has occurred. The AdvizorPro team has been notified and will follow
                            up.</span></h6>
                </div>

                <div *ngIf="uploadedList?.fields_detected">
                    <h6><span class="bold">File type:</span> {{uploadedList?.file_type}}</h6>
                    <h6><span class="bold">Columns detected:</span> {{uploadedList?.fields_detected}}</h6>
                    <h6><span class="bold">Total records:</span> {{uploadedList?.total_records}}</h6>

                    <h6 *ngIf="uploadedList?.file_type === 'contacts'"><span class="bold">Persons Matched: </span> <span
                            [countUp]="uploadedList?.mapped_records">0</span>
                        <i *ngIf="!uploadedList?.progress?.step_4" class="mr-2 fas fa-circle-notch fa-spin"
                            aria-hidden="true"></i>
                    </h6>

                    <h6 *ngIf="uploadedList?.file_type === 'companies'"><span class="bold">Companies Matched: </span> <span
                        [countUp]="uploadedList?.mapped_records">0</span>
                    <i *ngIf="!uploadedList?.progress?.step_4" class="mr-2 fas fa-circle-notch fa-spin"
                        aria-hidden="true"></i>
                </h6>

                </div>

            </div>
            <div class="col-md-12 pl-0">
                <div class="row mt-2 ml-1">
                    <div class="col-md-12 pl-0">
                        <br>
                        <div>
                            <h6 class="bold inline">
                                <i *ngIf="uploadedList?.progress?.step_1 == 'Complete'"
                                    class="mr-2 far fa-check-circle color-grn" aria-hidden="true"></i>
                                <i *ngIf="uploadedList?.progress?.step_1 != 'Complete'"
                                    class="mr-2 fas fa-circle-notch fa-spin" aria-hidden="true"></i>
                                Matching Firms
                            </h6>

                            <h6 class="bold pull-right inline">
                                {{uploadedList?.progress?.step_1}}
                            </h6>
                            <hr>
                        </div>

                        <div *ngIf="uploadedList?.file_type === 'contacts'">
                            <h6 class="bold inline">
                                <i *ngIf="uploadedList?.progress?.step_2 == 'Complete'"
                                    class="mr-2 far fa-check-circle color-grn" aria-hidden="true"></i>
                                <i *ngIf="uploadedList?.progress?.step_2 != 'Complete'"
                                    class="mr-2 fas fa-circle-notch fa-spin" aria-hidden="true"></i>
                                Matching Persons
                            </h6>

                            <h6 class="bold pull-right inline">
                                {{uploadedList?.progress?.step_2}}
                            </h6>
                            <hr>
                        </div>

                        <div>
                            <h6 class="bold inline">
                                <i *ngIf="uploadedList?.progress?.step_3 == 'Complete'"
                                    class="mr-2 far fa-check-circle color-grn" aria-hidden="true"></i>
                                <i *ngIf="uploadedList?.progress?.step_3 != 'Complete'"
                                    class="mr-2 fas fa-circle-notch fa-spin" aria-hidden="true"></i>
                                Validating Matches
                            </h6>

                            <h6 class="bold pull-right inline">
                                {{uploadedList?.progress?.step_3}}
                            </h6>
                            <hr>
                        </div>

                        <div *ngIf="uploadedList?.create_saved_search">
                            <h6 class="bold inline">
                                <i *ngIf="uploadedList?.progress?.step_4 == 'Complete'"
                                    class="mr-2 far fa-check-circle color-grn" aria-hidden="true"></i>
                                <i *ngIf="uploadedList?.progress?.step_4 != 'Complete'"
                                    class="mr-2 fas fa-circle-notch fa-spin" aria-hidden="true"></i>
                                Creating Saved Search
                            </h6>

                            <h6 class="bold pull-right inline">
                                {{uploadedList?.progress?.step_4}}
                            </h6>
                            <hr>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #listFooter>
        <div class="row mt-3">
            <div class="col-md-6 text-left">
                <a *ngIf="uploadedList?.download_file_url"
                   [href]="uploadedList.download_file_url" 
                   download 
                   class="btn btn-link btn-cons icon-btn search-btn">
                    Download Mapped File
                </a>
            </div>
            <div class="col-md-6 text-right">
                <button class="btn btn-complete" type="button"
                        (click)="updateSaveFilter(uploadedList?.saved_search_id); listCancel();"
                        [disabled]="uploadedList.status != 'completed'"> Run Search </button>
            </div>
        </div>
    </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="isAntWaitingBox" nzWidth="600px" [nzContent]="antWaitingBoxContent"
    [nzFooter]="antWaitingBoxFooter" [nzTitle]="antWaitingBoxTitle" (nzOnCancel)="antWaitingBoxCancel()"
    (nzOnOk)="antWaitingBoxOk()">
    <ng-template #antWaitingBoxTitle>
        {{downloadMessage.status_message}}
    </ng-template>
    <ng-template #antWaitingBoxContent>
        <div class="mr-3">
            <div *ngIf="downloadMessage.status === 'success'">
                <nz-progress [nzPercent]="percent_complete" nzStatus="active"></nz-progress>
            </div>
            <div *ngIf="downloadMessage.status === 'error'" class="d-flex flex-column justify-content-center align-items-center">
                <i class="fa fa-exclamation-triangle d-flex justify-content-center fa-2x text-danger"
                    aria-hidden="true"></i>
                <p *ngIf="downloadMessage.message" class="fs-14 mt-4 text-center">{{ downloadMessage.message }}</p>
            </div>
            <div *ngIf="downloadMessage.status === 'large'" class="d-flex flex-column w-100 justify-content-center align-items-center">
                <nz-spin nzSimple></nz-spin>
                <p *ngIf="downloadMessage.message" class="fs-14 mt-4 text-center">{{ downloadMessage.message }}</p>
                <nz-progress [nzPercent]="percent_complete" nzStatus="active" class="w-100"></nz-progress>
            </div>
        </div>
    </ng-template>
    <ng-template #antWaitingBoxFooter></ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="mapContactModal" nzWidth="700px" [nzContent]="mapContactContent" [nzFooter]="null"
    nzTitle="Contacts" (nzOnCancel)="mapContactCancel()" (nzOnOk)="mapContactOk()">
    <ng-template #mapContactContent>
        <div class="row mt-3">
            <div class="col-lg-12 table-responsive">
                <ngx-datatable class='table table-hover contact-table' [rows]="contacts?.data"
                    [columnMode]="columnModeSetting" [headerHeight]="43" [footerHeight]="50" [rowHeight]="'auto'"
                    [scrollbarH]="scrollBarHorizontal" [externalPaging]="true" [count]="contacts?.total"
                    [offset]="paginateContact.pageNumber" [limit]="paginateContact.limit" [externalSorting]="true"
                    (page)='sortData($event)' [messages]="messagesTeam">

                    <ngx-datatable-column [resizeable]="false" cellClass="d-flex align-items-center" [sortable]="false"
                        prop="name" name="Name" [minWidth]="300">
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            <a [routerLink]="['/advisor/person', row?.person_crd]" target="_blank"><span>{{
                                    row?.first_name | uppercase }}{{ row?.last_name | uppercase}}</span>
                            </a>
                            <p class="fs-10 mb-0">{{row?.title | uppercase }}</p>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column [resizeable]="false" headerClass="text-center"
                        cellClass="text-center d-flex align-items-center" [sortable]="false" name="BD" prop="broker"
                        [minWidth]="50">
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            <span *ngIf="row?.broker"><i class="fa fa-check-circle ria-bd-icon"></i></span>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column [resizeable]="false" headerClass="text-center"
                        cellClass="text-center d-flex align-items-center" [sortable]="false" prop="investment_advisor"
                        name="RIA" [minWidth]="50">
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            <span *ngIf="row?.advizor"><i class="fa fa-check-circle ria-bd-icon"></i></span>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column [resizeable]="false" cellClass="advisor_action" [minWidth]="70"
                        [sortable]="false" prop="advizor_person_attribute.value" name="">
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            <a href="mailto:{{row?.email}}" target="_blank" *ngIf="row?.email" class="ml-1"
                                title="{{row?.email}}"><i class="far fa-envelope" aria-hidden="true"></i></a>
                            <a href="mailto:{{row?.personal_email}}" target="_blank" *ngIf="row?.personal_email"
                                class="ml-1" title="{{row?.personal_email}}"><i class="far fa-envelope personal_email"
                                    aria-hidden="true"></i></a>
                            <a href="tel:{{row?.phone}}" title="{{row?.phone}}" *ngIf="row?.phone" class="ml-1"><i
                                    class="fas fa-phone-alt" aria-hidden="true"></i></a>
                            <a href="{{row?.bio_url}}" title="{{row?.bio_url}}" target="_blank" *ngIf="row?.bio_url"
                                class="ml-1"><i class="fas fa-user" aria-hidden="true"></i></a>
                            <a href="{{row?.linkedin}}" title="{{row?.linkedin}}" target="_blank" *ngIf="row?.linkedin"
                                class="ml-1"><i class="fab fa-linkedin" aria-hidden="true"></i></a>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            </div>
        </div>
    </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="header_basic_access" nzWidth="450px" [nzContent]="headerBasiAccessContent"
    [nzFooter]="null" nzTitle="Upgrade to Premium" (nzOnCancel)="headerBasiAccessCancel()">
    <ng-template #headerBasiAccessContent>
        <div class="text-center mt-4">
            <p>A premium subscription is required.</p>
            <p class="btnlink"><a href="https://planprofessional.com/request-demo/" target="_blank"
                    class="btn btn-md btn-primary btn-success">Learn More</a></p>
        </div>
    </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="limit_over_model" nzWidth="450px" [nzContent]="limitOverModelContent"
    [nzFooter]="null" nzTitle="Download Limit Reached" (nzOnCancel)="limitOverModelCancel()">
    <ng-template #limitOverModelContent>
        <div class="text-center mt-4">
            <p class="text-primary">Your report creation limit has been reached for this month. It will reset on the
                first
                day of next month.</p>
        </div>
    </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="isExportModal" nzWidth="600px" [nzContent]="exportModalContent"
    [nzFooter]="exportModalFooter" nzTitle="Excel Export" (nzOnCancel)="exportModalCancel()" (nzOnOk)="exportModalOk()"
    nzClassName="exp-dn">
    <!-- <ng-template #modalTitle>
        <h5 class="bold m-0">Excel Export</h5>
        <div class="col-md-12 down-repo" *ngIf="excel_link">
            <a href="{{excel_link}}" target="_blank" class="btn btn-success" download>Download Report</a>
        </div>
    </ng-template>-->
    <ng-template #exportModalContent>
        <div class="col-md-12">
            <p class="p-b-10" *ngIf="is_export_tab;else other_content">
                <span *ngIf="user?.ap_export_credit || user?.ap_account_export_credit">
                    <span *ngIf="exportCreditCount == 0"><b>You have exhausted your export credits for the
                            month.</b></span>
                    <span
                        *ngIf="(exportCreditCount > 0) && (exportCreditCount >= search_result?.person?.total) && (search_result?.person?.total < 10000)"><b>{{
                            ((selected_person.length > 0) ? selected_person.length : search_result?.person?.total) |
                            number : 0}} of
                            {{exportCreditCount | number : 0}}</b> available export credits will be used.</span>
                    <span
                        *ngIf="(exportCreditCount > 0) && (exportCreditCount >= search_result?.person?.total) && (search_result?.person?.total >= 10000)"><b>10,000
                            of {{exportCreditCount | number : 0}}</b> available export credits will be used.</span>
                    <span *ngIf="(exportCreditCount > 0) && (search_result?.person?.total > exportCreditCount)"><b>You
                            have
                            {{exportCreditCount | number : 0}} remaining export credits.</b> This export will use all of
                        your
                        remaining export credits and only include the first {{exportCreditCount | number : 0}} of
                        {{search_result?.person?.total | number : 0}} results of this search.</span>
                    <br>
                    <br>

                    <i *ngIf="user?.ap_export_credit && user?.ap_account_export_credit">Your account is currently
                        allotted {{
                        user?.ap_export_credit | number: 0 }} exports credits per calendar month and {{
                        user?.ap_account_export_credit | number: 0 }} export credits per annum. Please reach out to <a
                            href="mailto:support@advizorpro.com">support@advizorpro.com</a> with any
                        questions.</i>
                    <i *ngIf="user?.ap_export_credit && !(user?.ap_account_export_credit)">Your account is currently
                        allotted {{
                        user?.ap_export_credit | number: 0 }} exports credits per calendar month. Please reach out to <a
                            href="mailto:support@advizorpro.com">support@advizorpro.com</a> with any
                        questions.</i>
                    <i *ngIf="user?.ap_account_export_credit && !(user?.ap_export_credit)">Your account is currently
                        allotted {{
                        user?.ap_account_export_credit | number: 0 }} exports credits per annum. Please reach out to <a
                            href="mailto:support@advizorpro.com">support@advizorpro.com</a> with any
                        questions.</i>
                </span>
            </p>
            <ng-template #other_content>
                <p class="p-b-10">{{exportMessage}}</p>
            </ng-template>
        </div>
        <div class="col-md-12">
            <p class="p-b-10"
                *ngIf="(user?.ap_export_credit || user?.ap_account_export_credit) && (search_result?.person?.total > 10000) && (exportCreditCount != 0)">
                <i>*Please note that the first 10,000 persons will be included in the export.</i>
            </p>
            <p class="p-b-10"
                *ngIf="(!user?.ap_export_credit && !user?.ap_account_export_credit) && (search_result?.person?.total > 5000)">
                <i>*Please note that the first 5,000 persons will be included in the export.</i>
            </p>
        </div>
        <nz-tabset class="horizontal-tab" (nzSelectChange)="tabSelectChange($event)">
            <nz-tab nzTitle="Settings">
                <div class="row mt-3 auto-overflow">
                    <div class="col-md-12">
                        <label>Export Name (Optional)</label>
                        <input type="text" class="form-control" name="exportOptionalName"
                            [(ngModel)]="exportOptionalName">
                    </div>
                </div>

                <div class="row mt-3 auto-overflow">
                    <div class="col-md-12">
                        <div class="checkbox m-0 inline">
                            <input type="checkbox" name="omit_check" value="1" id="omit_person" [(ngModel)]="omit.check"
                                checked="checked">
                            <label for="omit_person" class="d-inline fs-14 mr-1">Omit previously exported persons
                                by</label>
                        </div>
                        <pg-select [Mode]="'single'" style="min-width: 15%;" [PlaceHolder]="'Select'"
                            [(ngModel)]="omit.type" [NotFoundContent]="'Not found'" name="omit_type">
                            <pg-option *ngFor="let option of omitType" [Label]="option.name" [Value]="option.value">
                            </pg-option>
                        </pg-select> in the last
                        <pg-select [Mode]="'single'" [PlaceHolder]="'Select'" [(ngModel)]="omit.day"
                            [NotFoundContent]="'Not found'" name="omit_day">
                            <pg-option *ngFor="let option of omitList" [Label]="option.name" [Value]="option.value">
                            </pg-option>
                        </pg-select>
                    </div>
                </div>

                <div class="row mt-2 auto-overflow" *ngIf="user.ap_crm_exports">
                    <div class="col-md-4">
                        <div class="checkbox m-0">
                            <p class="m-0">&nbsp;</p>
                            <input type="checkbox" name="crm_friendly" value="{{crm_friendly}}" id="crm_frnd"
                                checked="checked" [(ngModel)]="crm_friendly">
                            <label for="crm_frnd" class="m-0">CRM friendly export</label>
                        </div>
                    </div>
                </div>
            </nz-tab>
            <nz-tab nzTitle="Person">
                <div class="row mt-3 auto-overflow">
                    <div class="col-md-4" *ngFor="let item of export_person_cols; let i = index;">
                        <div *ngIf ="user[item.permision_key] === 1  || item?.permision_key === undefined" 
                            class="checkbox m-0" (change)="checkboxPersonExportCheckBox($event)">
                            <p class="m-0">&nbsp;</p>
                            <input type="checkbox" name="export_person_cols" value="{{item.value}}"
                                id="person_{{item.id}}" checked="checked" [(ngModel)]="item.checked">
                            <label for="person_{{item.id}}" class="m-0">{{item.value}}</label>
                        </div>
                    </div>
                </div>
            </nz-tab>
            <nz-tab nzTitle="Firm" *ngIf="!onlyInsuranceAccess">
                <div class="row mt-3 auto-overflow">
                    <div class="col-md-4" *ngFor="let item of export_firm_cols; let i = index;">
                        <div class="checkbox m-0" (change)="checkboxFirmExportCheckBox($event)">
                            <p class="m-0">&nbsp;</p>
                            <input type="checkbox" name="export_firm_cols" value="{{item.value}}" id="firm_{{item.id}}"
                                checked="checked" [(ngModel)]="item.checked">
                            <label for="firm_{{item.id}}" class="m-0">{{item.value}}</label>
                        </div>
                    </div>
                </div>
            </nz-tab>
        </nz-tabset>
    </ng-template>
    <ng-template #exportModalFooter>
        <div class="m-2 exp-nw">
            <nz-button-group>
                <button nz-button class="ant-btn ant-btn-grn" (click)="exportAllCSV('all')">Export</button>
                <button nz-button class="ant-btn ant-btn-grn" nz-dropdown [nzDropdownMenu]="menu2"
                    nzPlacement="bottomRight" [disabled]="(user?.ap_export_credit) && (exportCreditCount == 0)">
                    <span nz-icon nzType="down"></span>
                </button>
            </nz-button-group>
            <nz-dropdown-menu #menu2="nzDropdownMenu">
                <ul nz-menu>
                    <li nz-menu-item (click)="exportAllCSV('firm')" *ngIf="!onlyInsuranceAccess">Export only Firms</li>
                    <li nz-menu-item (click)="exportAllCSV('person')">Export only Persons</li>
                </ul>
            </nz-dropdown-menu>
        </div>
    </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="email_automation_modal" nzWidth="600px" [nzContent]="emailContent" [nzFooter]="null"
    nzTitle="Send Emails" (nzOnCancel)="emailContentCancel()">
    <ng-template #emailContent>
        <email_automation_setting (TestmailSuccess)="showSuccessModal($event)" [recipient_data]="recipient_data">
        </email_automation_setting>
    </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="email_test_modal" nzWidth="600px" [nzContent]="emailTestContent" [nzFooter]="null"
    [nzTitle]="null" (nzOnCancel)="emailTestContentCancel()">
    <ng-template #emailTestContent>
        Test email has been sent
    </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="bulk_follow" nzWidth="600px" [nzContent]="bulkFollowContent"
    [nzFooter]="bulkFollowFooter" nzTitle="Bulk Follow" (nzOnCancel)="bulkFollowCancel()" (nzOnOk)="saveBulkStatus()">
    <ng-template #bulkFollowContent>
        <div class="row">
            <div class="col-md-12">
                <h5>Select Status</h5>
                <pg-select style="width: 100%;" [Mode]="'single'" [PlaceHolder]="'Select'" [(ngModel)]="follow_status"
                    [NotFoundContent]="'Not found'" name="follow_status">
                    <pg-option *ngFor="let option of statusList" [Label]="option" [Value]="option">
                    </pg-option>
                </pg-select>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-3 mb-0 d-flex align-items-center form-group">
                <input type="radio" id="private_label" name="follow_type" [(ngModel)]="user_follow_type"
                    value="private">
                <label for="private_label" class="mb-0" style="margin-left:0.5rem;">Private</label>
            </div>
            <div class="col-lg-3 mb-0 d-flex align-items-center form-group">
                <input type="radio" name="follow_type" id="firm_label" [(ngModel)]="user_follow_type" value="firm">
                <label for="firm_label" class="mb-0" style="margin-left:0.5rem;">Firm</label>
            </div>
        </div>
    </ng-template>
    <ng-template #bulkFollowFooter>
        <div class="row m-2">
            <div class="offset-10 col-md-2 mt-2">
                <button class="btn btn-complete pull-right" type="button" (click)="saveBulkStatus();"
                    [disabled]="!bulk_data"> Follow
                </button>
            </div>
        </div>
    </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="email_quality" nzWidth="650px" [nzContent]="emailQualityContent"
    [nzFooter]="emailQualityFooter" nzTitle="Email Quality Score" (nzOnCancel)="emailQualityCancel()"
    (nzOnOk)="updateUserSearch()">
    <ng-template #emailQualityContent>
        <div class="row">
            <div class="col-md-12 mt-2 text-left">
                <p>AdvizorPro’s email quality scores are based on their predicted accuracy and indicate the reliability
                    of the emails in our database. AdvizorPro employs several methods for email verification which allow
                    us to determine email reliability. An email with a rating of A+ has the highest degree of being
                    valid and the lowest likely bounce rate. Our platform will default to only including the best
                    quality emails (A+), unless otherwise selected by a user.</p>

                <p>Emails of varying qualities will be appropriate for different use cases. When pulling data for an
                    email marketing campaign, setting a high quality score is recommended and ensures bounce rates are
                    low. This helps protect your sender score and prevents emails from being delayed or caught by a SPAM
                    filter. When trying to find a specific contact's email you may be comfortable using an email that we
                    are not as confident in but has a reasonable chance of being the individual emails.</p>
            </div>
        </div>
        <div class="row ml-2 mr-2 mt-2">
            <div class="col-md-2">
                <label>A+&nbsp;&nbsp;</label>
            </div>
            <div class="col-md-2"><nz-switch [ngModel]="true" name="email_quality_a_plus" nzSize="small"
                    class="pull-right" [nzDisabled]="true"></nz-switch></div>
        </div>
        <div class="row ml-2 mr-2">
            <div class="col-md-2">
                <label>A&nbsp;&nbsp;</label>
            </div>
            <div class="col-md-2">
                <nz-switch name="email_quality_A" [(ngModel)]="user_setting.email_quality_A" nzSize="small"
                    class="pull-right"></nz-switch>
            </div>
        </div>

        <div class="row ml-2 mr-2">
            <div class="col-md-2">
                <label>B&nbsp;&nbsp;</label>
            </div>
            <div class="col-md-2">
                <nz-switch [(ngModel)]="user_setting.email_quality_B" name="email_quality_B" nzSize="small"
                    class="pull-right"></nz-switch>
            </div>
        </div>
    </ng-template>
    <ng-template #emailQualityFooter>
        <div class="row m-2">
            <div class="offset-10 col-md-2 mt-2">
                <button class="btn btn-complete pull-right" type="button" (click)="updateUserSearch();"> Save
                </button>
            </div>
        </div>
    </ng-template>
</nz-modal>

<app-bulk-modal [name]="searchVariables.bulk_firm_city" [title]="'Bulk Firm City List'" [label]="'Firm City List'" [placeholder]="'Bulk Firm City'" [note]="'Please add City with comma or space'" [search]="search" (makeSearch)="makeSearch()"></app-bulk-modal>
<app-bulk-modal [name]="searchVariables.bulk_firm_zip" [title]="'Bulk Firm Zip List'" [label]="'Firm Zip List'" [placeholder]="'Bulk Firm Zip'" [note]="'Please add Zip with comma or space.'" [search]="search" (makeSearch)="makeSearch()"></app-bulk-modal>
<app-bulk-modal [name]="searchVariables.bulk_person_city" [title]="'Bulk Person City List'" [label]="'Person City List'" [placeholder]="'Bulk Person City'" [note]="'Please add City with comma or space.'" [search]="search" (makeSearch)="makeSearch()"></app-bulk-modal>
<app-bulk-modal [name]="searchVariables.bulk_person_zip" [title]="'Bulk Person Zip List'" [label]="'Person Zip List'" [placeholder]="'Bulk Person Zip'" [note]="'Please add Zip with comma or space.'" [search]="search" (makeSearch)="makeSearch()"></app-bulk-modal>
<app-bulk-modal [name]="searchVariables.bulk_team_city" [title]="'Bulk Team City List'" [label]="'Team City List'" [placeholder]="'Bulk Team City'" [note]="'Please add City with comma.'" [search]="search" (makeSearch)="makeSearch()"></app-bulk-modal>
<app-bulk-modal [name]="searchVariables.bulk_team_zip" [title]="'Bulk Team Zip List'" [label]="'Team Zip List'" [placeholder]="'Bulk Team Zip'" [note]="'Please add Zip with comma or space.'" [search]="search" (makeSearch)="makeSearch()"></app-bulk-modal>
<app-bulk-modal [name]="searchVariables.bulk_ticker" [title]="'Bulk Ticker List'" [label]="'Ticker List'" [placeholder]="'Bulk Ticker'" [note]="'Please add Ticker with comma or space.'" [search]="search" (makeSearch)="makeSearch()"></app-bulk-modal>
<app-bulk-modal [name]="searchVariables.upload_person_crd" [title]="'Bulk Search Options'" [placeholder]="'Insert here'" [note]="'Please seperate data points with comma or space.'" [search]="search" (makeSearch)="makeSearch()">
        <div class="col-md-12 pl-0">
                <ul>
                        <li>Rep CRD</li>
                        <!--<li>Person Name</li>-->
                        <li>Email Address</li>
                </ul>
        </div>
</app-bulk-modal>
<app-bulk-modal [name]="searchVariables.upload_crd" [title]="'Bulk Search'" [placeholder]="'Insert here'" [note]="'Please seperate data points with comma or space.'" [search]="search" (makeSearch)="makeSearch()">
        <div class="col-md-12 ml-1">
                <label><nz-switch [(ngModel)]="bulk_filter_option" name="bulk_filter_option" nzSize="small"
                        nzCheckedChildren="Includes" nzUnCheckedChildren="Excludes"></nz-switch></label>
        </div>
        <div class="col-md-5 ml-1 mb-4 adv-radio">
                <nz-radio-group [(ngModel)]="bulkSearchValue">
                    <label *ngIf="user.id == 1" nz-radio nzValue="id">Id</label>
                    <label *ngIf="user.has_access_insurance" nz-radio nzValue="npn">NPN</label>
                    <label nz-radio nzValue="firm_crd">Firm CRD</label>
                    <label nz-radio nzValue="rep_crd">Rep CRD</label>
                    <label nz-radio nzValue="firm_name">Firm Name</label>
                    <label nz-radio nzValue="email_address">Email Address</label>
                    <label nz-radio nzValue="email_domain">Email Domain</label>
                </nz-radio-group>
        </div>
        <div class="col-md-5 ml-1 mb-4 adv-radio">
                <nz-radio-group [(ngModel)]="bulkSearchValue">
                    <label nz-radio nzValue="firm_city">Firm City</label>
                    <label nz-radio nzValue="firm_zip">Firm Zip</label>
                    <label nz-radio nzValue="person_city">Person City</label>
                    <label nz-radio nzValue="person_zip">Person Zip</label>
                    <label nz-radio nzValue="person_name">Person Name</label>
                    <label nz-radio nzValue="ticker">Ticker</label>
                </nz-radio-group>
        </div>
</app-bulk-modal>

<nz-modal nzCentered [(nzVisible)]="setting_modal" nzWidth="600px" [nzContent]="settingContent"
    [nzFooter]="settingContentFooter" nzTitle="Settings" (nzOnCancel)="settingContentCancel()"
    (nzOnOk)="updateUserSearch()">
    <ng-template #settingContent>
        <div class="row ml-2 mr-2 mt-2" *ngIf="user.has_access_advisor">
            <div class="col-md-10">
                <label>Remove Non-Advisor Firms&nbsp;&nbsp;</label>
            </div>
            <div class="col-md-2"><nz-switch [(ngModel)]="user_setting.known_non_advisor" name="known_non_advisor"
                    nzSize="small" class="pull-right"></nz-switch></div>

            <div class="col-md-10">
                <label>Remove Non-Advisor Persons&nbsp;&nbsp;</label>
            </div>
            <div class="col-md-2">
                <nz-switch [(ngModel)]="user_setting.remove_known_non_advisor_individual"
                    name="remove_known_non_advisor_individual" nzSize="small" class="pull-right"></nz-switch>
            </div>
        </div>
        <div class="row ml-2 mr-2" *ngIf="user.has_access_insurance">
            <div class="col-md-10">
                <label>Remove Non-Producing Persons&nbsp;&nbsp;</label>
            </div>
            <div class="col-md-2">
                <nz-switch [(ngModel)]="user_setting.remove_known_non_producing_individual"
                    name="remove_known_non_producing_individual" nzSize="small" class="pull-right"></nz-switch>
            </div>

        </div>
        <div class="col-md-10">
            <div class="multiple-control">
                <div *ngIf="exclude_firm_name.length > 0">
                    <span class="custom-chip" *ngFor="let item of exclude_firm_name">
                        <i class="pg pg-close" (click)="removeExcludeFirmName(item)"></i>
                        {{ item }}
                    </span>
                </div>
                <app-autocomplete [(ngModel)]="exclude_firm" [getValue]="true" [removeValue]="true"
                    [autoSelectionData]="autoSelectionExcludeFirm" [callback]="exclude_firm_name_autocomplete"
                    name="exclude_firm_autocomplete" (typeaheadOnSelect)="selectExcludeFirm($event)"
                    (change)="changeExcludeFirmName($event)" [placeholder]="'Firms to Exclude'"
                    [typeaheadItemTemplate]="typeaheadSettingTemplate" typeaheadOptionField="name">
                </app-autocomplete>
                <ng-template #typeaheadSettingTemplate let-model="item" let-index="index">
                    <label (click)="preExcludeEvent($event, model)">
                        <input type="checkbox" name="typeheadfirm[]" [value]="model?.crd"
                            [checked]="firm_exclude_checkbox.includes(model.name)?true:false" class="mr-1">
                    </label>
                    <div class="d-inline">
                        <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({
                            'advizor': model?.advizor,
                            'broker': model?.broker
                        })" />
                    </div>
                    <ng-container>
                        <span class="font-montserrat"> {{ model.name }}{{ model.display_text }} </span>
                    </ng-container>
                </ng-template>
            </div>
        </div>
    </ng-template>
    <ng-template #settingContentFooter>
        <div class="row m-2">
            <div class="offset-10 col-md-2 mt-2">
                <button class="btn btn-complete pull-right" type="button" (click)="updateUserSearch()" label="Save">
                    Save
                </button>
            </div>
        </div>
    </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="date_saved_search" nzWidth="600px" [nzContent]="savedContent"
    [nzFooter]="savedContentFooter" nzTitle="Saved Search List" (nzOnCancel)="savedContentCancel()"
    (nzOnOk)="updateUserSearch()">
    <ng-template #savedContent>
        <div class="row mt-1">
            <div class="col-md-12 text-center" *ngIf="saved_search_diff.length == 0">
                <span>loading...</span>
            </div>
        </div>
        <div class="row" *ngIf="saved_search_diff.length > 0">
            <div class="col-md-12 mt-1" *ngFor="let item of saved_search_diff">
                <a href="javascript:void(0)"
                    (click)="runSavedSearcQuery(item.saved_search_id, item.created_at)">{{item.created_at |
                    date:'MM/dd/yyyy hh:mm a'}}</a>
            </div>
        </div>
    </ng-template>
    <ng-template #savedContentFooter>
    </ng-template>
</nz-modal>

<team-modal [id]="dbaContactId" [dbaData]="dbaData"></team-modal>

<nz-modal nzCentered [(nzVisible)]="disclosure_content" nzWidth="750px" [nzContent]="disclosureContent"
    [nzFooter]="disclosureFooter" nzTitle="Disclaimer" (nzOnCancel)="disclosureContentCancel()"
    (nzOnOk)="disclosureContentCancel()">
    <ng-template #disclosureContent>
        <div class="row mt-3">
            <div class="col-md-12">
                <i>Quite often, information providers put limitations on what you can do with the information they
                    supply. By using the List Matching Feature, you represent and warrant that you have the right to use
                    the information you provide to us (and the right to grant us the right to use the information you
                    provide to us) to search for matching contact and personal information. You agree to indemnify,
                    defend and hold us harmless for any third-party costs we may incur as a result of your breach of
                    this representation and warranty.</i>
            </div>
        </div>
    </ng-template>
    <ng-template #disclosureFooter>
        <div class="row mt-3">
            <div class="col-md-12 text-right">
                <button class="btn btn-primary" type="button" (click)="disclosureContentCancel();"> OK
                </button>
            </div>
        </div>
    </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="saveSearched" nzWidth="850px" [nzContent]="saveSearchedContent" [nzFooter]="null"
    nzTitle="Saved Search List" (nzOnCancel)="saveSearchedCancel()">
    <ng-template #saveSearchedContent>
        <div class="row mt-3">
            <div class="col-md-12 table-responsive">
                <ngx-datatable class='table table-hover save-model-dt' [rows]="search_data?.data"
                    [columnMode]="columnModeSetting" [headerHeight]="43" [footerHeight]="50" [rowHeight]="'auto'"
                    [scrollbarH]="scrollBarHorizontal" [externalPaging]="true" [count]="search_data?.total"
                    [offset]="paginateSaved.pageNumber" [limit]="paginateSaved.limit" [externalSorting]="true"
                    (page)='pageSearchData($event)'>

                    <ngx-datatable-column [resizeable]="false" cellClass="d-flex align-items-center" [sortable]="false"
                        prop="search_name" name="Search Name" [minWidth]="300">
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            {{ row?.search_name | uppercase }}
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column [resizeable]="false" headerClass="text-center"
                        cellClass="d-flex align-items-center text-center" [sortable]="false" name="Type"
                        prop="shared_type" [minWidth]="150">
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            {{ shareType[row?.shared_type] }}
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column [resizeable]="false" headerClass="text-center"
                        cellClass="d-flex align-items-center text-center" [sortable]="false" name="Creation Date"
                        prop="shared_type" [minWidth]="150">
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            <span>{{ row?.created_at | date:'MM/dd/yyyy' }}</span>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column [resizeable]="false" headerClass="text-center"
                        cellClass="d-flex align-items-center text-center" [sortable]="false" name="" prop=""
                        [minWidth]="200">
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template class="text-wrap">
                            <a class="mr-4" href="javascript:void(0);" (click)="searchSaveFilter(row.id)">
                                <i class="fa fa-search bg-grn search-cus-icon"></i>
                            </a>

                            <a href="javascript:void(0);" (click)="deleteSearchRecord(row.id)">
                                <i class="far fa-trash-alt bg-danger search-cus-icon"></i>
                            </a>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            </div>
        </div>
    </ng-template>
</nz-modal>

<p-sidebar [(visible)]="isProfilevisible" [style]="{'overflow-y': 'auto', 'overflow-x': 'hidden', 'width': '1250px'}"
    [modal]="true" [baseZIndex]="999" position="right" [showCloseIcon]="false" styleClass="advisor-firm-drawer"
    [blockScroll]="false" (onHide)="drawerClose()">
    <ng-template pTemplate="header">
        <div class="back-link">
            <a href="javascript:void(0)" class="pull-left c-text-color d-flex align-items-center" (click)="close()"><i
                    class="fa fa-chevron-left mr-2" aria-hidden="true"></i> Back </a>
        </div>
        <div class="new-tab-link">
            <a class="pull-right" [routerLink]="['/'+profileData?.type+'/'+profileData?.sub_type+'/'+profileData?.crd]"
                target="_blank"><i class="fa fa-external-link-alt mr-1" aria-hidden="true"></i><u>Open profile in a new
                    window</u></a>
        </div>
    </ng-template>
    <ng-template pTemplate="content">
        <!-- <hr class="m-0"> -->
        <div class="drawer-content">
            <div *ngIf="isProfilevisible && profileData?.sub_type == 'firm'">
                <advisor-firm-modal [profileData]="profileData"></advisor-firm-modal>
            </div>
            <div *ngIf="isProfilevisible && profileData?.sub_type != 'firm'">
                <advisor-person-modal [profileData]="profileData"></advisor-person-modal>
            </div>
        </div>
    </ng-template>
</p-sidebar>
