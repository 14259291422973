export interface CaseValues {
  case: string;
  values: string[];
}

export interface CategoryField {
  [key: string]: CaseValues[];
}

export const categoryField: CategoryField = Object.freeze({
    Contacts : [
          { 
            case: 'Location',
            values: [
              'person_metro_area',
              'person_city',
              'person_zip_code',
              'person_distance_of_zip',
              'person_county',
              'person_state'
            ]
          }, { 
            case: 'ADV & Website Search',
            values: [
              'person_keywords',
              'person_website_contains'
            ]
          }, { 
            case: 'Lead Score', values: ['lead_score']
          },{
            case: 'Personal & Interests',
            values: [
              'person_hobbie_keywords',
              'person_alma_mater_keywords',
              'person_family_keywords',
              'person_sports_team_keywords',
              'person_greek_life_keywords',
              'person_military_status_keywords'
            ]
          }, {
            case: 'Services',
            values: [
              'person_services_keywords'
            ]
          },{
            case: 'Accolades & Awards',
            values: [
              'person_accolade_award_keywords'
            ]
          }, { case: 'Investment Interests',
            values: [
              'person_investment_keywords',
              'person_faith_based_investing_keywords'
            ]
          }, { 
            case: 'Carriers & Appointments',
            values: [
              'number_of_ins_states_min',
              'number_of_ins_states_max',
              'min_number_of_carriers',
              'max_number_of_carriers',
              'min_appointment_date',
              'max_appointment_date',
              'insurance_registered_state',
              'text_carrier_contain'
            ]
          }, {
            case: 'Email, Phone, & LinkedIn',
            values: ['people_with']
          },{
            case: 'Disclosures',
            values: [
              'has_disclosures',
              'has_no_disclosures',
              'has_disclosure_type',
              'min_num_of_disclosure',
              'max_num_of_disclosure',
              'start_latest_disclosure_date',
              'end_latest_disclosure_date'
            ]
          },{
            case: 'Experience, Age, & Gender',
            values: [
              'previous_firm',
              'years_w_current_firm_min',
              'years_w_current_firm_max',
              'ins_years_of_experience_min',
              'ins_years_of_experience_max',
              'gender',
              'start_registration_date',
              'end_registration_date',
              'min_est_age',
              'max_est_age'
            ]
          },{
            case: 'Insurance Agency & Lines',
            values: [
              'remove_known_non_producing_individual',
              'captive',
              'text_company_contain',
              'insurance_company',
              'insurance_carrier',
              'insurance_line',
              'min_number_of_lines',
              'max_number_of_lines',
              'securities_registered'
            ]
          },{
            case: 'Job, Title, & Role',
            values: [
              'investment_committee',
              'text_title_contain',
              'title_contain',
              'licenses_registration',
              'designation',
              'people_role',
              'remove_known_non_advisor_individual',
              'my_advisor'
            ]
          },{
            case: 'Ownership',
            values: ['firm_ownership']
          },{ 
            case: 'Family Office',
            values: [
              'family_person_asset_class',
              'person_family_bio'
            ]
          },{
            case: 'Teams',
            values: [
              'team_member'
            ]
          }
        ],
    Companies : [
          {
            case: 'Location',
            values: [
              'state',
              'city',
              'zip_code',
              'county',
              'metro_area',
              'distance_of_zip'
            ]
          },
          {
            case: '13F Holdings',
            values: [
              'ticker',
              'cusip',
              'morningstar_category',
              'min_holdings_value',
              'max_holdings_value',
              'min_holdings_no_of_shares',
              'max_holdings_no_of_shares',
              'min_etf_percentage',
              'max_etf_percentage',
              'min_mf_percentage',
              'max_mf_percentage'
            ]
          },
          {
            case: 'ADV & Web Search',
            values: [
              'firm_keywords',
              'firm_contains',
              'firm_adv_brochure_extract',
              'firm_adv_extract'
            ]
          },
          {
            case: 'AUM & Accounts',
            values: [
              'min_aum',
              'max_aum',
              'min_discretionary_aum',
              'max_discretionary_aum',
              'min_aum_growth',
              'max_aum_growth',
              'min_aum_growth_per',
              'max_aum_growth_per',
              'min_avg_size',
              'max_avg_size'
            ]
          },
          {
            case: 'Clients',
            values: [
              'client_type',
              'min_number_of_clients',
              'max_number_of_clients',
              'min_client_type',
              'max_client_type',
              'min_client_type_dollar',
              'max_client_type_dollar',
              'min_avg_client_type',
              'max_avg_client_type',
              'min_total_clients',
              'max_total_clients',
              'min_fin_clients',
              'max_fin_clients',
              'channel'
            ]
          },
          {
            case: 'Custodian',
            values: [
              'custodian',
              'max_number_of_custodians',
              'min_number_of_custodians',
              'min_custodian_assets',
              'max_custodian_assets',
              'min_assets_held_by_custodian',
              'max_assets_held_by_custodian'
            ]
          },
          {
            case: 'Employees',
            values: [
              'min_total_employees',
              'max_total_employees',
              'min_advisory_reps',
              'max_advisory_reps',
              'min_advisor_count',
              'max_advisor_count',
              'min_bd_reps',
              'max_bd_reps',
              'min_ins_licensed_reps',
              'max_ins_licensed_reps'
            ]
          },
          {
            case: 'Family Office',
            values: [
              'family_firm_asset_class',
              'firm_family_office_type',
              'family_firm_description',
              'wealth_creator',
              'min_family_aum',
              'max_family_aum',
              'min_fam_year_founded',
              'max_fam_year_founded',
              'family_direct_investments',
              'family_industry_interests',
              'family_geographic_focus',
              'family_wealth_origin'
            ]
          },
          {
            case: 'Bank & Trust',
            values: [
              'bt_trust_services',
              'bt_trust_powers',
              'bt_trust_teams',
              'bt_classification_code',
              'min_bt_non_managed_accounts',
              'max_bt_non_managed_accounts',
              'min_bt_number_of_fiduciary_accounts_and_related_asset_accounts',
              'max_bt_number_of_fiduciary_accounts_and_related_asset_accounts',
              'min_bt_total_fiduciary_and_related_assets',
              'max_bt_total_fiduciary_and_related_assets',
              'min_bt_total_bank_assets',
              'max_bt_total_bank_assets',
              'min_bt_trust_assets',
              'max_bt_trust_assets',
              'min_bt_total_trust_accounts',
              'max_bt_total_trust_accounts',
              'min_bt_total_bank_accounts',
              'max_bt_bank_trust_accounts'
            ]
          },
          {
            case: 'General Information',
            values: [
              'start_date_establish_date',
              'end_date_establish_date',
              'min_establish_date',
              'max_establish_date',
              'start_date_sec_registration',
              'end_date_sec_registration',
              'firm_my_advisor',
              'known_non_advisor',
              'min_year_founded',
              'max_year_founded',
              'firm_autocomplete',
              'firm_name',
              'allocate_external_managers'
            ]
          },
          {
            case: 'Separately Managed Accounts',
            values: [
              'asset_type',
              'min_sma_asset_type',
              'max_sma_asset_type',
              'sma_asset_type_cash_min',
              'sma_asset_type_cash_max',
            ]
          },
          {
            case: 'Services',
            values: [
              'advisor_service'
            ]
          },
          {
            case: 'Misc. ADV',
            values: [
              'advisor_service',
              'affiliation',
              'compensation',
              'business_type_po'
            ]
          },
          {
            case: 'Teams',
            values: [
              'min_team_size',
              'max_team_size',
              'team_state',
              'team_city',
              'team_metro_area',
              'min_team_number',
              'max_team_number',
            ]
          }
        ]  
})
