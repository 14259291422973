import { Component, Input, OnInit } from '@angular/core';
import { Disclosure } from './person-disclosures-interface';

@Component({
  selector: 'person-disclosures',
  templateUrl: './person-disclosures.component.html',
  styleUrls: ['./person-disclosures.component.scss'],
})
export class PersonDisclosuresComponent implements OnInit {
  readonly PER_PAGE = 15;
  @Input() disclosures: Disclosure[] = [];
  expandSet = new Set<number>();
  currentPage = 1;

  ngOnInit() {
    if(this.disclosures.length < 5)
      this.disclosures.forEach(item => this.expandSet.add(item.id))
  }

  onPageChange(pageIndex: number): void {
    this.currentPage = pageIndex;
  }

  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  buildPercentage(settlementAmount: string | undefined , amountRequested: string | undefined): string{
    if(!settlementAmount || !amountRequested  )
      return ""
    const toNumber = (amount: string ) => Number(amount.replace(/[$,]/g, ''));
    return ` (${((toNumber(settlementAmount) / toNumber(amountRequested))*100).toFixed(2)}%)` 
  }
}


